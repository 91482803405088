import { Component } from '@angular/core';

@Component({
    templateUrl: './dataud-main-layout.component.html',
    styleUrls: ['./dataud-main-layout.component.scss']
})
export class MainLayoutComponent {}



