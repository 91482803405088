import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { ApiUrlService } from './api-url.service';
import { Metadata, StancodeRefItem, SubstanceParameter, ValueDisplayItem } from 'src/app/models/metadata.models';
import { DateTimeOffset } from 'src/app/utils/datetimeoffset';

import _ from 'lodash';

interface MetadataRawResponse extends Omit<Metadata, 'minPeriodDate' | 'maxPeriodDate' | 'species' | 'mediaTypes'> {
    minPeriodDate: string;
    maxPeriodDate: string;
    species: StancodeRefItem[];
    mediaTypes: string[];
}

@Injectable()
export class MetaDataService {
    private _metadata: Metadata;

    constructor(private _http: HttpClient, private _apiUrlService: ApiUrlService) {
    }

    public loadMetadata(language: string): Observable<Metadata> {
        const url = this._apiUrlService.metadata(language);

        return this._http.get<MetadataRawResponse>(url)
            .pipe(
                catchError((error: Error) => {
                    if (this.isServiceNotAvailable(error)) {
                        const message = language === 'da' ? 'Systemet er utilgængeligt.' : 'System is unavailable.';
                        alert(message);
                    } else {
                        alert(error.message);
                    }

                    return throwError(error);
                }),
                map(raw => this.toMetadata(raw)), tap(metadata => {
                    this._metadata = metadata;
                })
            );
    }

    private isServiceNotAvailable(error: any): boolean {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 404 || error.status === 503) {
                // catch error while deploying Azure Function
                return true;
            }
        }

        return false;
    }

    private toMetadata(raw: MetadataRawResponse): Metadata {
        const result = {
            ...raw,
            minPeriodDate: DateTimeOffset.toLocalDate(raw.minPeriodDate),
            maxPeriodDate: DateTimeOffset.toLocalDate(raw.maxPeriodDate),
            substanceParameters: this.toSubstanceParametersItems(raw.substanceParameters),
            species: this.toValueDisplayItems(raw.species, true),
            mediaTypes: raw.mediaTypes.map(x => ({ value: x, displayName: x })),
        } as Metadata;

        return result;
    }

    private toValueDisplayItems(source: StancodeRefItem[], includeSecondName = false): ValueDisplayItem[] {
        const results: ValueDisplayItem[] = [];

        source.forEach(s => {
            const value = `${s.scListId}_${s.scCode}`;
            let displayName = s.name;

            if (includeSecondName) {
                if (!_.isEmpty(s.secondName)) {
                    displayName += ` (${s.secondName})`;
                }
            }

            results.push({ displayName, value });
        });

        return results;
    }

    // Self mutation of the source to compute value|displayName, then return the source itself
    private toSubstanceParametersItems(source: SubstanceParameter[]) {
        source.forEach(s => {
            s.value = `${s.scListId}_${s.scCode}`;
            s.displayName = `${s.scCode} - ${s.name}`;
            s.oldParameters?.forEach(op => {
                op.displayName = `${op.scCode} - ${op.name}`;
            });
        });

        return source;
    }

    public get metadata(): Metadata {
        return this._metadata;
    }
}
