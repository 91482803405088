import { Component } from '@angular/core';

declare const $: any;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    constructor() {
        window.addEventListener('resize', this.updateViewportHeight.bind(null));
        this.updateViewportHeight();
        $('[data-toggle="tooltip"]').tooltip();
    }

    private updateViewportHeight(): void {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}
