import { Component, ChangeDetectionStrategy, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef, NgZone } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, Options, NotificationType } from 'angular2-notifications';

import { Subscription, forkJoin, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { ToasterService } from 'src/app/services/communication/toaster.service';
import { Toast, ToastType, HttpErrorData, GeneralError } from 'src/app/models/toast.models';
import { Guid } from 'src/app/utils/guid';
import { DatePipe } from '@angular/common';
import { Clipboard } from 'src/app/utils/clipboard';

declare const $: any;

interface HttpErrorContext {
    statusCode: number;
    statusText: string;
    url: string;
    dateTime: Date;
    message: string;
    detailOpen: boolean;
    detailContainerElementId: string;
    closeFn: () => void;
    toggleDetailFn: (data: HttpErrorContext) => void;
    copyFn: (data: HttpErrorContext) => void;
}

@Component({
    selector: 'dataud-toaster',
    templateUrl: './dataud-toaster.component.html',
    styleUrls: ['./dataud-toaster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe]
})
export class ToasterComponent implements OnDestroy {
    private _toastSubscription: Subscription;

    @ViewChild('httpErrorTemplate', { static: true }) private _httpErrorTemplateRef: TemplateRef<any>;
    @ViewChild('inputErrorTemplate', { static: true }) private _inputErrorTemplate: TemplateRef<any>;

    public options: Options = {
        position: ['top', 'left'],
        timeOut: undefined,
        clickToClose: false,
        clickIconToClose: false
    };

    constructor(
        private _toasterService: ToasterService,
        private _notificationService: NotificationsService,
        private _translateService: TranslateService,
        private _datePipe: DatePipe,
        private _cd: ChangeDetectorRef,
        private _ngZone: NgZone
    ) {
        this._toastSubscription = this._toasterService.toast$.subscribe({ next: this.notify.bind(this) });
    }

    ngOnDestroy(): void {
        this._toastSubscription.unsubscribe();
    }

    private notify(toast: Toast): void {
        switch (toast.type) {
            case ToastType.HttpError:
                this.notifyHttpError(toast);
                break;
            case ToastType.GeneralError:
                this.notifyGeneralError(toast);
                break;
        }
    }

    private notifyHttpError(toast: Toast): void {
        const data = toast.data as HttpErrorData;
        const context: Partial<HttpErrorContext> = {
            statusCode: data.status,
            statusText: data.statusText,
            url: data.url,
            dateTime: data.dateTime,
            message: data.message,
            detailContainerElementId: Guid.newGuid().toString(),
            detailOpen: false
        };

        const notification = this._notificationService.html(this._httpErrorTemplateRef,
            NotificationType.Error, null, null,
            {
                data: {
                    ...context,
                    closeFn: () => { this._notificationService.remove(notification.id); },
                    toggleDetailFn: (errorData: HttpErrorContext) => {
                        this._ngZone.runOutsideAngular(() => {
                            $(`#${errorData.detailContainerElementId}`).collapse('toggle');
                        });
                        errorData.detailOpen = !errorData.detailOpen;
                        this._cd.markForCheck();
                    },
                    copyFn: (errorData: HttpErrorContext) => this.copyHttpErrorMessage(errorData)
                }
            });
    }

    private copyHttpErrorMessage(error: HttpErrorContext): void {
        this.getErrorCopyText(error).pipe(first()).subscribe({
            next: text => Clipboard.copy(text)
        });
    }

    private getErrorCopyText(error: HttpErrorContext): Observable<string> {
        return forkJoin([
            this._translateService.get('errors.http.title'),
            this._translateService.get('errors.http.http_error_code'),
            this._translateService.get('errors.http.timestamp'),
            this._translateService.get('errors.http.url'),
            this._translateService.get('errors.http.message'),
        ]).pipe(map(([titleLabel, httpErrorCodeLabel, timestampLabel, urlLabel, messageLabel]) => {
            return `${titleLabel}

${httpErrorCodeLabel}: ${error.statusCode} ${error.statusText}
${timestampLabel}: ${this._datePipe.transform(error.dateTime, 'dd-MM-yyyy HH:mm')}
${urlLabel}: ${error.url}
${messageLabel}: ${error.message}`;
        }));
    }

    private notifyGeneralError(toast: Toast): void {
        const error = toast.data as GeneralError;

        const options = {
            timeOut: 5000,
            showProgressBar: false,
            pauseOnHover: true,
            clickToClose: true,
            animate: 'fromRight'
        };

        this._notificationService.html(this._inputErrorTemplate,
            NotificationType.Error, options, null, { data: error });
    }
}
