import {
    Component, ChangeDetectionStrategy, NgZone, Input, ElementRef, ViewChild, OnInit,
    Renderer2, ChangeDetectorRef
} from '@angular/core';

declare const $: any;

@Component({
    selector: 'dataud-collapse-container',
    templateUrl: './dataud-collapse-container.component.html',
    styleUrls: ['./dataud-collapse-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseContainerComponent implements OnInit {
    @Input() header: string;

    // Radio
    @Input() role: 'primary' | 'secondary' = 'secondary';
    @Input() defaultOpen = true;

    @ViewChild('contentContainer', { static: true }) private _contentContainerElRef: ElementRef;
    @ViewChild('selectInput', { static: true }) private _selectInputElRef: ElementRef;

    public isCollapsed = false;

    constructor(private _ngZone: NgZone, private _renderer: Renderer2, private _cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.defaultOpen) {
            this._ngZone.runOutsideAngular(() => {
                this._renderer.addClass(this._contentContainerElRef.nativeElement, 'show');
            });
        }
    }

    public toggle(): void {
        this._ngZone.runOutsideAngular(() => {
            $(this._contentContainerElRef.nativeElement).collapse('toggle');
        });
        this.isCollapsed = !this.isCollapsed;
    }

    public open(): void {
        this._ngZone.runOutsideAngular(() => {
            $(this._contentContainerElRef.nativeElement).collapse('show');
        });
        this.isCollapsed = false;
        this._cd.markForCheck();
    }

    public collapse(): void {
        this._ngZone.runOutsideAngular(() => {
            $(this._contentContainerElRef.nativeElement).collapse('hide');
        });
        this.isCollapsed = true;
        this._cd.markForCheck();
    }
}
