import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

import * as _ from 'lodash';

import { ApiUrlService } from './api-url.service';

@Injectable()
export class GeographicalService {
    private _cacheData: Map<string, any>;
    private _cacheObservable: Map<string, Observable<any>>;

    constructor(private _http: HttpClient, private _apiUrlService: ApiUrlService) {
        this._cacheData = new Map<string, any>();
        this._cacheObservable = new Map<string, Observable<any>>();
    }

    public getRegion(id: string): Observable<any> {
        return this.getDataFromCache(id,
            regionId => `Region_${regionId}`,
            regionId => {
                const url = this._apiUrlService.getRegion(regionId);
                return this._http.get(url);
            });
    }

    public getMunicipality(id: string): Observable<any> {
        return this.getDataFromCache(id,
            municipalityId => `Municipality_${municipalityId}`,
            municipalityId => {
                const url = this._apiUrlService.getMunicipality(municipalityId);
                return this._http.get(url);
            });
    }

    private getDataFromCache(id: string, cacheKeyGenerator: (id: string) => string,
                             observableFactory: (id: string) => Observable<any>): Observable<any> {
        const cacheKey = cacheKeyGenerator(id);

        if (this._cacheData.has(cacheKey)) {
            return of(this._cacheData.get(cacheKey));
        }

        if (this._cacheObservable.has(cacheKey)) {
            return this._cacheObservable.get(cacheKey);
        }

        const obs = observableFactory(id).pipe(
            tap(res => this._cacheData.set(cacheKey, res)),
            catchError(error => {
                this._cacheObservable.delete(cacheKey);
                return throwError(error);
            }),
            shareReplay());
        this._cacheObservable.set(cacheKey, obs);
        return obs;
    }
}
