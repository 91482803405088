import {
    Component, ChangeDetectionStrategy, Input, Output,
    EventEmitter, HostListener, forwardRef, ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'dataud-check-box',
    templateUrl: './dataud-check-box.component.html',
    styleUrls: ['./dataud-check-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckBoxComponent),
            multi: true
        }
    ]
})
export class CheckBoxComponent {

    constructor(private _cd: ChangeDetectorRef) {
        this.id = uuidv4();
    }
    @Input() checked: boolean;
    @Input() disabled: boolean;
    @Input() label: string;
    @Input() type: 'radio' | 'checkbox' = 'radio';
    @Input() textRole: string;
    @Input() useHighlightStyle = false;
    @Input() handleCheckedManually = false;

    @Output() clicked = new EventEmitter<any>();

    public id: string;

    @HostListener('click', ['$event'])
    private onClick(e: Event): void {
        e.preventDefault();

        if (this.disabled) { return; }

        if (this.handleCheckedManually) {
            this.clicked.emit();
            return;
        }

        this.checked = !this.checked;
        this._cd.markForCheck();
        this._onChange(this.checked);
    }

    //#region ControlValueAccessor

    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: boolean): void {
        this.checked = obj;
        this._cd.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._cd.markForCheck();
    }

    //#endregion
}
