import {
    Component, Input, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectionStrategy,
    forwardRef, OnDestroy, Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject, combineLatest, Subscription } from 'rxjs';

import * as _ from 'lodash';
import S2Selection from 'src/app/models/select2-selection';

declare const $: any;

@Component({
    selector: 'dataud-select',
    templateUrl: './dataud-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ],
    styles: [':host { display: block; }']
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() items: any[];
    @Input() bindLabel: string;
    @Input() bindValue: string;
    @Input() multiple = false;
    @Input() placeholder: string;
    @Input() allowClear = false;
    @Input() closeOnSelect = false;
    @Input() isSelectingOnClose = false;
    @Input() formatItemStateFn: () => any;
    @Input() searchMatcherFn: () => any;

    @Output() dropdownOpen = new EventEmitter<any>();
    @Output() dropdownClose = new EventEmitter<any>();

    @ViewChild('select', { static: true }) private _selectElementElRef: ElementRef;
    private get _select(): HTMLSelectElement {
        return this._selectElementElRef.nativeElement as HTMLSelectElement;
    }

    private _initSubject: Subject<boolean>;
    private _valueSubject: Subject<any>;
    private _outValueSubject: Subject<any>;
    private _patchValueSubscription: Subscription;
    private _outValueSubscription: Subscription;
    private _isDropdownOpenning: boolean;

    private _selectionChangeHandler = this.onSelectionChange.bind(this);
    private _dropdownOpenHandler = this.onDropdownOpen.bind(this);
    private _dropdownCloseHandler = this.onDropdownClose.bind(this);

    constructor(private _ngZone: NgZone) {
        this._initSubject = new Subject<boolean>();
        this._valueSubject = new Subject<any>();
        this._outValueSubject = new Subject<any>();

        this._patchValueSubscription = combineLatest([
            this._valueSubject,
            this._initSubject
        ]).subscribe({
            next: ([value]) => this.patchValue(value)
        });

        this._outValueSubscription = combineLatest([
            this._outValueSubject,
            this._initSubject
        ]).subscribe({
            next: ([value]) => {
                _ngZone.run(() => {
                    this._onChange(value);
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._patchValueSubscription.unsubscribe();
        this._outValueSubscription.unsubscribe();
        this.detachDropdownOpenHandler();
        this.detachDropdownCloseHandler();
    }

    //#region ControlValueAccessor
    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: any): void {
        this._valueSubject.next(obj);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._ngZone.runOutsideAngular(() => {
            $(this._select).prop('disabled', isDisabled);
        });
    }
    //#endregion

    ngOnInit(): void {
        this._ngZone.runOutsideAngular(() => {
            this._select.multiple = this.multiple;
            const s2Items = this.toS2Selections();
            this.initSelect2(s2Items);
        })
        this._initSubject.next(true);
    }

    private initSelect2(data): void {
            const settings: any = {
                data,
                theme: 'bootstrap',
                width: 'auto',
                allowClear: this.allowClear,
                placeholder: this.placeholder,
                closeOnSelect: this.closeOnSelect,
                matcher: this.searchMatcherFn ? this.searchMatcherFn : (params, data) => {
                    // params: which contains the search term
                    // data: which is an item. The matcher happening for each item in the source and checking
                    // the term against each item (data)
                    const trimmedTerm = params.term?.trim();

                    if (_.isEmpty(trimmedTerm)) {
                        return data;
                    }

                    // Do not display the item if there is no 'text' property
                    if (_.isEmpty(data.text)) {
                        return null;
                    }

                    // Check matching by item.text
                    if (data.text.toUpperCase().includes(trimmedTerm.toUpperCase())) {
                        return data;
                    }

                    // Return `null` if the term should not be displayed
                    return null;
                }
            };

            if (this.formatItemStateFn) {
                settings.templateResult = this.formatItemStateFn;
            }

            $(this._select).select2(settings);

            this.attachSelectionChangeHandler();
            this.attachDropdownOpenHandler();
            this.attachDropdownCloseHandler();
    }

    private toS2Selections(items: any[] = this.items, labelPath: string = this.bindLabel, valuePath: string = this.bindValue) {
        if (_.isNil(items)) {
            return [];
        }

        return items.map(item => new S2Selection(_.get(item, valuePath, item), _.get(item, labelPath, item), item));
    }

    private patchValue(value: any): void {
        this._ngZone.runOutsideAngular(() => {
            this.detachSelectionChangeHandler();
            $(this._select).val(value).trigger('change');
            this.attachSelectionChangeHandler();
        });
    }

    private raiseValueChange(): void {
        const data = $(this._select).select2('data') as { id: string }[];
        const values = data.map(x => x.id);
        const outValue = this.multiple ? values : values[0];
        this._outValueSubject.next(outValue);
    }

    private onSelectionChange(e): void {
        if (!this.isSelectingOnClose) {
            this.raiseValueChange();
        }
    }

    private attachSelectionChangeHandler(): void {
        $(this._select).on('change.select2', this._selectionChangeHandler);
    }

    private detachSelectionChangeHandler(): void {
        $(this._select).off('change.select2', this._selectionChangeHandler);
    }

    private onDropdownClose(e): void {
        this._isDropdownOpenning = false;
        if (this.isSelectingOnClose) {
            this.raiseValueChange();
        }
        this.dropdownClose.emit(e);
    }

    private attachDropdownCloseHandler(): void {
        $(this._select).on('select2:close', this._dropdownCloseHandler);
    }

    private detachDropdownCloseHandler(): void {
        $(this._select).off('select2:close', this._dropdownCloseHandler);
    }

    private onDropdownOpen(e): void {
        this._isDropdownOpenning = true;
        this.dropdownOpen.emit(e);
    }

    private attachDropdownOpenHandler(): void {
        $(this._select).on('select2:open', this._dropdownOpenHandler);
    }

    private detachDropdownOpenHandler(): void {
        $(this._select).off('select2:open', this._dropdownOpenHandler);
    }

    public updateData(): void {
        this._ngZone.runOutsideAngular(() => {
            const s2Items = this.toS2Selections();
            $(this._select).empty();
            $(this._select).select2("destroy");
            this.detachDropdownOpenHandler();
            this.detachDropdownCloseHandler();
            this.detachSelectionChangeHandler();
            this.initSelect2(s2Items);
        });
    }
}
