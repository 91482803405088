import moment from 'moment-timezone';
import * as _ from 'lodash';

export class DateTimeOffset {
    private static _iso8601Regex = new RegExp(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(.\d*)?[+-](\d{2})\:(\d{2})/);

    public static toLocalDate(isoString: string): Date {
        if (_.isEmpty(isoString)) { return null; }

        if (!DateTimeOffset._iso8601Regex.test(isoString)) {
            throw new Error(`Invalid input for instantiating DateTimeOffset: ${isoString}`);
        }

        const momentVal = moment.parseZone(isoString);
        const momentDate = momentVal.toDate();

        return new Date(momentDate.getFullYear(), momentDate.getMonth(), momentDate.getDate(), 0, 0, 0, 0);
    }

    public static toDemmarkDatetimeOffsetString(date: Date): string {
        if (_.isNil(date)) { return null; }

        const mdate = moment.parseZone(date);
        const mdateTz = mdate.clone().tz('Europe/Copenhagen');
        mdateTz.add(mdate.utcOffset() - mdateTz.utcOffset(), 'minutes');
        mdateTz.set('hour', 0);
        mdateTz.set('minute', 0);
        mdateTz.set('second', 0);
        mdateTz.set('millisecond', 0);
        return mdateTz.toISOString(true);
    }
}
