import { Polygon } from 'src/app/models/polygon.model';

export interface GeographicalValue {
    searchBy: AreaType | '';
    place?: string;
    municipality?: string[];
    region?: string[];
    mapView?: Polygon;
    polygonType?: 'Polygon' | 'MapPoint';
    polygon?: Polygon;
}

export enum AreaType {
    Place = 'Place',
    Municipality = 'Municipality',
    Region = 'Region',
    Polygon = 'Polygon',
    MapView = 'MapView'
}
