import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import _ from 'lodash';

import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DMPNotification } from 'src/app/models/dmp-notification.models';
import { DMPNotificationService } from 'src/app/services/api/dmp-notification.service';

@Component({
    selector: 'dataud-dmp-notification-area',
    templateUrl: './dataud-dmp-notification-area.component.html',
    styleUrls: ['./dataud-dmp-notification-area.component.scss']
})
export class DMPNotificationAreaComponent implements OnInit {
    public dmpNotifications: DMPNotification[];
    public isShowingDMPNotification = false;
    private _fullNotifications: DMPNotification[];

    private _userClaims = { sub: 'anonymous' }; // DataUd is not support user login now

    private _visibilitySubject: Subject<any>;
    private _visibilitySubscription: Subscription;

    constructor(private _dmpNotificationService: DMPNotificationService, private _cd: ChangeDetectorRef) {
        this._visibilitySubject = new Subject();
        this._visibilitySubscription = this._visibilitySubject
            .pipe(
                map((data, index) => {
                    this.isShowingDMPNotification = !this.isShowingDMPNotification;

                    return {
                        shown: this.isShowingDMPNotification,
                        applyUserFilter: index === 0
                    };
                }),
                switchMap(({ shown, applyUserFilter }) => {

                    if (!shown) {
                        this._cd.markForCheck();
                        return of(null);
                    }

                    return this.fetchNotifications(applyUserFilter);
                })
            ).subscribe();

        this._dmpNotificationService.toggleShowingNotification$.subscribe({
            next: () => this._visibilitySubject.next()
        });
    }

    ngOnInit(): void {
        this._visibilitySubject.next();
    }

    private fetchNotifications(applyUserFilter: boolean): Observable<any> {
        return this._dmpNotificationService.getNotifications()
            .pipe(
                tap(items => {
                    this._fullNotifications = _.cloneDeep(items);
                    this._dmpNotificationService.updateNotificationCount(items.length);
                    this.dmpNotifications = applyUserFilter ? this.handleDMPNotification(items) : items;

                    if (!this.dmpNotifications?.length) {
                        this.isShowingDMPNotification = false;
                    }

                    this._cd.markForCheck();
                })
            );
    }

    private handleDMPNotification(feeds: DMPNotification[]): DMPNotification[] {
        return this._dmpNotificationService.getUserNotifications(this._userClaims, feeds);
    }

    public onNotificationDismissed(item: DMPNotification): void {
        this._dmpNotificationService.dismissNotification(this._userClaims, item, this._fullNotifications);
        const removeIndex = this.dmpNotifications.findIndex(x => x.notificationId === item.notificationId);
        if (removeIndex >= 0) {
            this.dmpNotifications.splice(removeIndex, 1);
            if (!this.dmpNotifications?.length) {
                this.isShowingDMPNotification = false;
            }
        }

        this._cd.markForCheck();
    }
}
