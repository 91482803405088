<ul class="pagination">
  <li class="page-item disabled">
    <a class="page-link" href="#"><i class="fas fa-xs fa-chevron-left"></i></a>
  </li>
  <li class="page-item active">
    <a class="page-link" href="#">1</a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#">2</a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#">3</a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#">4</a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#">5</a>
  </li>
  <li class="page-item">
    <a class="page-link" href="#"><i class="fas fa-xs fa-chevron-right"></i></a>
  </li>
</ul>