import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ErrorHandler, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, UrlSerializer, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { of, Observable } from 'rxjs';
import { debounceTime, map, catchError, tap, distinctUntilChanged } from 'rxjs/operators';

import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';

import * as _ from 'lodash';

import { FilterDispatchService } from 'src/app/services/communication/filter-dispatch.service';
import { Filter } from 'src/app/models/filter.model';
import { GeographicalValue, AreaType } from '../../value-accessor/geographical-selection/dataud-geographical-selection.component.model';
import { PeriodValue } from '../../value-accessor/period-selection/dataud-period-selection.models';
import { SearchLinkService } from 'src/app/services/communication/search-link.service';
import { PolygonService } from 'src/app/services/api/polygon.service';
import { MetaDataService } from 'src/app/services/api/metadata.service';
import { Metadata } from 'src/app/models/metadata.models';
import { MainSelectionValue } from '../../value-accessor/main-selection/dataud-main-selection.model';
import { ToasterService } from 'src/app/services/communication/toaster.service';
import { ToastType } from 'src/app/models/toast.models';
import { Polygon as LPolygon } from 'src/app/models/polygon.model';

import { StringExtensions } from 'src/app/utils/string-extensions';
import { DatafordelerWfsService } from 'src/app/services/datafordeler/datafordeler-wfs.service';
import Geometry from 'ol/geom/Geometry';
import { GeographicalSelectionComponent } from '../../value-accessor/geographical-selection/dataud-geographical-selection.component';
import { MapInteractionService } from 'src/app/services/communication/map-interaction.service';

declare const $: any;

interface UrlQueryParams {
    et: string[]; // examination types
    cp: string[]; // chemistry parameters
    pg: string[]; // chemistry parameter group
    sp: string[]; // species
    sl: boolean;  // sample locations
    dr: string[]; // data responsible
    sw: string[]; // station owner
    so: string[]; // station operator
    mp: string[]; // measured parameter
    mt: string[]; // media types
    region: string[];
    municipality: string[];
    place: string;
    polygonId: string;
    startDate: string;
    endDate: string;
    showLatestExaminationsResultsOnly: boolean;
    // map view
    mvMinX: number;
    mvMaxX: number;
    mvMinY: number;
    mvMaxY: number;
    // map point
    mapPointX: number;
    mapPointY: number;
}

interface FilterFormValue {
    mainSelection: MainSelectionValue;
    geographicalSelection: GeographicalValue;
    period: PeriodValue;
    dataResponsibles: string[];
    stationOwners: string[];
    stationOperators: string[];
    measuredParameters: string[];
}

@Component({
    selector: 'dataud-filter',
    templateUrl: './dataud-filter.component.html',
    styleUrls: ['./dataud-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe]
})
export class FilterComponent implements OnInit, AfterViewInit {
    private readonly _urlDateFormat = 'dd/MM/yyyy';
    private readonly _urlNumberRegex = new RegExp('^(-?\\d*([.]\\d*)?)$|^-$');
    private readonly _stancodeRefValueRegex = /[0-9]{1,}_[0-9]{1,}/;

    public isLoading = true;
    public form: UntypedFormGroup;

    private _metadata: Metadata;
    private _geoJSON = new GeoJSON();

    public hasLink: boolean;
    public mainSelectionSearchBy: 'ExaminationTypes' | 'Chemistry' | 'Species' | 'SampleLocations';

    @ViewChild('subFilter') _subFilter: GeographicalSelectionComponent;

    constructor(
        fb: UntypedFormBuilder,
        metadataService: MetaDataService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _urlSerializer: UrlSerializer,
        private _filterDispatcher: FilterDispatchService,
        private _searchLinkService: SearchLinkService,
        private _polygonService: PolygonService,
        private _wfsService: DatafordelerWfsService,
        private _toasterService: ToasterService,
        private _errorHandler: ErrorHandler,
        private _translateService: TranslateService,
        private _cd: ChangeDetectorRef,
        private _datePipe: DatePipe,
        private _mapInteractionService: MapInteractionService,
    ) {
        this._metadata = metadataService.metadata;

        this.form = fb.group({
            mainSelection: null,
            geographicalSelection: null,
            period: null,
            dataResponsibles: null,
            stationOwners: null,
            stationOperators: null,
            measuredParameters: null
        });
    }

    ngOnInit(): void {
        this.form.disable();

        this.toFormValue(this._route.snapshot.queryParams).subscribe({
            next: preSelectedFormValue => {
                this.form.enable();

                // attach form change handlers
                this.form.controls.mainSelection.valueChanges.subscribe({
                    next: (value: MainSelectionValue) => {
                        this.mainSelectionSearchBy = value?.searchBy;
                    }
                });

                this.form.controls.mainSelection.statusChanges.pipe(
                    distinctUntilChanged(),
                ).subscribe({
                    next: (status) => {

                        if(status === 'VALID'){
                            this.enableSubFilters();
                        }
                        else{
                            this.disableSubFilters();
                        }
                    }
                });

                this.form.valueChanges
                    .pipe(
                        tap(() => this._filterDispatcher.reset()),
                        debounceTime(300)
                    ).subscribe({
                        next: v => {
                            if (this.form.valid) {
                                this._searchLinkService.dispatchLink(this.toUrl(v));
                                this._filterDispatcher.dispatch(this.toFilter(v));
                                this.hasLink = true;
                                return;
                            }

                            this.hasLink = false;
                            this._searchLinkService.dispatchLink('');
                        }
                    });

                if (!_.isNil(preSelectedFormValue)) {
                    this.form.patchValue(preSelectedFormValue);

                    if (preSelectedFormValue.mainSelection.searchBy === 'SampleLocations') {
                        this.form.controls.period.disable({ onlySelf: true, emitEvent: false });
                    }

                    this.form.updateValueAndValidity();
                }

                this.isLoading = false;
                this._cd.markForCheck();
            }
        });
    }

    ngAfterViewInit(): void {
        // Disable  sub-filters until we have a valid parameter
        if(this.form.controls.mainSelection.invalid){
            this.disableSubFilters();
        }
    }


    public setLoading(value): void {
        this.isLoading = value;
        this._cd.markForCheck();
    }

    private enableSubFilters(): void {
        const controls = this.form.controls;
        controls.geographicalSelection.enable({ onlySelf: true, emitEvent: false });
        controls.geographicalSelection.updateValueAndValidity();

        if (this.mainSelectionSearchBy !== 'SampleLocations') {
            controls.period.enable({ onlySelf: true, emitEvent: false });
            controls.period.updateValueAndValidity();
        }

        controls.dataResponsibles.enable({ onlySelf: true, emitEvent: false });
        controls.dataResponsibles.updateValueAndValidity();

        controls.stationOwners.enable({ onlySelf: true, emitEvent: false });
        controls.stationOwners.updateValueAndValidity();

        controls.stationOperators.enable({ onlySelf: true, emitEvent: false });
        controls.stationOperators.updateValueAndValidity();

        this._subFilter?.open();
        this._mapInteractionService.enableSearchByAreaButton();
        this._cd.markForCheck();
    }

    private disableSubFilters(): void {
        const controls = this.form.controls;
        controls.geographicalSelection.disable({ onlySelf: true, emitEvent: false });
        controls.period.disable({ onlySelf: true, emitEvent: false });
        controls.dataResponsibles.disable({ onlySelf: true, emitEvent: false });
        controls.stationOwners.disable({ onlySelf: true, emitEvent: false });
        controls.stationOperators.disable({ onlySelf: true, emitEvent: false });

        this._subFilter?.collapse();
        this._mapInteractionService.disableSearchByAreaButton();

        this.form.updateValueAndValidity();
        this._cd.markForCheck();
    }

    private toFilter(formValue: FilterFormValue): Filter {
        let result: Filter = null;

        // main selection
        const mainSelection = formValue.mainSelection;
        const searchByConstants = this._metadata.constants.searchBy;
        switch (mainSelection.searchBy) {
            case 'ExaminationTypes':
                result = {
                    searchBy: searchByConstants.examinationTypes,
                    searchValues: mainSelection.examinationTypes
                };
                if (mainSelection.examinationMediaTypes) {
                    mainSelection.mediaTypes = [mainSelection.examinationMediaTypes];
                }
                break;

            case 'Chemistry':

                if (mainSelection.chemicalParameters) {
                    result = {
                        searchBy: searchByConstants.chemistries,
                        searchValues: mainSelection.chemicalParameters
                    };
                } else if (mainSelection.chemicalParameterGroup) {
                    result = {
                        searchBy: "ChemicalParameterGroup",
                        searchValues: [mainSelection.chemicalParameterGroup]
                    };
                }
                mainSelection.mediaTypes = mainSelection.chemicalMediaTypes;
                break;

            case 'Species':
                result = {
                    searchBy: searchByConstants.species,
                    searchValues: mainSelection.species
                };
                break;

            case 'SampleLocations':
                result = {
                    searchBy: 'SampleLocations'
                };
                mainSelection.mediaTypes = mainSelection.locationMediaTypes;
        }

        // geographical selection
        if (!_.isEmpty(formValue.geographicalSelection?.searchBy)) {
            const geoSelection = formValue.geographicalSelection;
            const areaTypeConstants = this._metadata.constants.areaType;

            switch (geoSelection.searchBy) {
                case AreaType.Place:
                    result.area = {
                        type: areaTypeConstants.place,
                        searchText: geoSelection.place
                    };
                    break;

                case AreaType.Polygon:
                    result.area = {
                        type: areaTypeConstants.polygon,
                        geoJsonString: this._geoJSON.writeFeatures(geoSelection.polygon.features)
                    };
                    break;

                case AreaType.Municipality:
                    result.area = {
                        type: areaTypeConstants.municipality,
                        values: geoSelection.municipality
                    };
                    break;

                case AreaType.Region:
                    result.area = {
                        type: areaTypeConstants.region,
                        values: geoSelection.region
                    };
                    break;

                case AreaType.MapView:
                    result.area = {
                        type: areaTypeConstants.rectangle,
                        geoJsonString: this._geoJSON.writeFeatures(geoSelection.mapView.features)
                    };
                    break;
            }
        }

        // period
        result.period = {
            showLastResult: false
        };
        if (!_.isNil(formValue?.period?.startDate)) {
            result.period.fromDate = formValue?.period?.startDate;
        }

        if (!_.isNil(formValue?.period?.endDate)) {
            result.period.toDate = formValue?.period?.endDate;
        }

        if (_.isBoolean(formValue?.period?.showLatestExaminationsResultsOnly)) {
            result.period.showLastResult = formValue.period.showLatestExaminationsResultsOnly;
        }

        // Data responsible
        result.dataResponsibles = formValue.dataResponsibles;
        // Station
        result.stationOwners = formValue.stationOwners;
        result.stationOperators = formValue.stationOperators;
        result.measuredParameters = formValue.measuredParameters;

        // Medias
        if (mainSelection.searchBy !== 'Species') {
            result.mediaTypes = formValue.mainSelection.mediaTypes;
        }

        return result;
    }

    private toUrl(formValue: FilterFormValue): string {
        const queryParams = this.toUrlQueryParams(formValue);
        const tree = this._router.createUrlTree([], { queryParams });
        return this._urlSerializer.serialize(tree);
    }

    private toUrlQueryParams(formValue: FilterFormValue): Partial<UrlQueryParams> {
        const result: Partial<UrlQueryParams> = {};
        // main selection
        const mainSelection = formValue.mainSelection;
        switch (mainSelection.searchBy) {
            case 'ExaminationTypes':
                result.et = mainSelection.examinationTypes;
                if (mainSelection.examinationMediaTypes) {
                    mainSelection.mediaTypes = [mainSelection.examinationMediaTypes];
                }
                break;

            case 'Chemistry':
                result.cp = mainSelection.chemicalParameters;
                result.pg = mainSelection.chemicalParameterGroup ? [mainSelection.chemicalParameterGroup] : undefined;
                mainSelection.mediaTypes = mainSelection.chemicalMediaTypes;
                break;

            case 'Species':
                result.sp = mainSelection.species;
                break;

            case 'SampleLocations':
                result.sl = true;
                mainSelection.mediaTypes = mainSelection.locationMediaTypes;
                break;
        }

        if (mainSelection.mediaTypes?.length && mainSelection.searchBy !== 'Species') {
            result.mt = mainSelection.mediaTypes;
        }

        // geographical selection
        if (!_.isEmpty(formValue.geographicalSelection?.searchBy)) {
            const geoSelection = formValue.geographicalSelection;

            switch (geoSelection.searchBy) {
                case AreaType.Place:
                    result.place = geoSelection.place;
                    break;

                case AreaType.Polygon:
                    {
                        const polygonType = geoSelection.polygonType;

                        switch (polygonType) {
                            case 'Polygon':
                                result.polygonId = geoSelection.polygon.id;
                                break;

                            case 'MapPoint':
                                result.mapPointX = geoSelection.polygon.representativeCoordinate[0];
                                result.mapPointY = geoSelection.polygon.representativeCoordinate[1];
                                break;
                        }
                    }

                    break;

                case AreaType.Municipality:
                    result.municipality = geoSelection.municipality;
                    break;

                case AreaType.Region:
                    result.region = geoSelection.region;
                    break;

                case AreaType.MapView:
                    const rectParams = this.toRectangleParams(geoSelection.mapView.features[0]);
                    result.mvMinX = rectParams.minX;
                    result.mvMaxX = rectParams.maxX;
                    result.mvMinY = rectParams.minY;
                    result.mvMaxY = rectParams.maxY;
                    break;
            }
        }

        // period
        if (!_.isNil(formValue?.period?.startDate)) {
            result.startDate = this._datePipe.transform(formValue.period.startDate, this._urlDateFormat);
        }

        if (!_.isNil(formValue?.period?.endDate)) {
            result.endDate = this._datePipe.transform(formValue.period.endDate, this._urlDateFormat);
        }

        if (formValue?.period?.showLatestExaminationsResultsOnly) {
            result.showLatestExaminationsResultsOnly = formValue.period.showLatestExaminationsResultsOnly;
        }

        // Data responsible
        if (formValue.dataResponsibles?.length && mainSelection.searchBy !== 'SampleLocations') {
            result.dr = formValue.dataResponsibles;
        }
        // Station
        if (mainSelection.searchBy === 'SampleLocations') {
            if (formValue.stationOwners?.length) {
                result.sw = formValue.stationOwners;
            }
            if (formValue.stationOperators?.length) {
                result.so = formValue.stationOperators;
            }
            if (formValue.measuredParameters?.length) {
                result.mp = formValue.measuredParameters;
            }
        }

        return result;
    }

    private toRectangleParams(feature: Feature): { minX: number; maxX: number; minY: number; maxY: number } {
        const polygon: Geometry = feature.getGeometry();
        const coordinates: number[][] = (polygon as Polygon).getCoordinates()[0];

        return {
            minX: Math.min(...coordinates.map(c => c[0])),
            maxX: Math.max(...coordinates.map(c => c[0])),
            minY: Math.min(...coordinates.map(c => c[1])),
            maxY: Math.max(...coordinates.map(c => c[1])),
        };

    }

    private toFormValue(queryParams: Params): Observable<Partial<FilterFormValue>> {
        if (_.isEmpty(queryParams)) { return of(null); }

        try {
            const formValue: Partial<FilterFormValue> = {
                mainSelection: this.toMainSelectionValue(queryParams),
                period: this.toPeriodValue(queryParams),
                dataResponsibles: this.toDataResponsibleValue(queryParams),
                stationOwners: this.toStationOwnerValue(queryParams),
                stationOperators: this.toDataOperatorValue(queryParams),
                measuredParameters: this.toStancodeRefStringArray(queryParams, 'mp', this._metadata.substanceParameters.map(x => x.value), 'measured_parameter_selection.measured_parameter.label')
            };

            return this.toGeographicalValue(queryParams).pipe(
                map(geographicalValue => {
                    formValue.geographicalSelection = geographicalValue;

                    const result = _.pickBy(formValue, p => !_.isNil(p));
                    return _.isEmpty(result) ? null : result;
                }),
                catchError(error => {
                    this._errorHandler.handleError(error);
                    return of(null);
                })
            );
        } catch (error) {
            this._toasterService.toast({
                type: ToastType.GeneralError,
                data: {
                    title: this._translateService.instant('errors.url.title'),
                    message: error.message,
                    useTranslation: false
                }
            });
            return of(null);
        }
    }

    private toMainSelectionValue(queryParams: Params): MainSelectionValue {
        const mt = this.toStringArray(queryParams, 'mt', this._metadata.mediaTypes.map(x => x.value), 'main_selection.medias');

        // Build Map from old value to new value of chemiscal parameters
        const oldChemicalValueList = _.flatMap(
            this._metadata.substanceParameters.filter(x => x.oldParameters?.length)
                .map(x => x.oldParameters
                    .map((y: any) => { y.newValue = x.value; return y })
                )
        ) ?? [];
        const oldChemicalValueMap = new Map<string, string>();
        oldChemicalValueList.forEach(x => oldChemicalValueMap.set(`${x.scListId}_${x.scCode}`, x.newValue));

        let result: Partial<MainSelectionValue & { sl: boolean }> = {
            examinationTypes: this.toStringArray(queryParams, 'et', this._metadata.examinationTypes.map(x => x.value), 'main_selection.examination_type.label'),
            chemicalParameters: this.toStancodeRefStringArray(queryParams, 'cp', this._metadata.substanceParameters.map(x => x.value), 'main_selection.chemistry.label', oldChemicalValueMap),
            species: this.toStancodeRefStringArray(queryParams, 'sp', this._metadata.species.map(x => x.value), 'main_selection.species.label'),
            sl: this.toBoolean(queryParams, 'sl', 'Sample location')
        };

        if (result.examinationTypes?.length) {
            result.examinationMediaTypes = mt?.[0];
        }
        if (result.chemicalParameters?.length) {
            result.chemicalMediaTypes = mt;
        }
        if (result.sl) {
            result.locationMediaTypes = mt;
        }
        if (queryParams?.pg) {
            result.chemicalParameterGroup = queryParams?.pg;
        }

        result = _.pickBy(result, p => !_.isNil(p));

        if (_.isEmpty(result)) { return null; }

        const keys = _.keys(result).filter(x => x !== 'chemicalMediaTypes' && x !== 'examinationMediaTypes' && x !== 'locationMediaTypes');
        if (keys.length > 1) {
            throw new Error(this._translateService.instant('errors.url.multiple_filter_types'));
        }

        switch (keys[0]) {
            case 'examinationTypes':
                result.searchBy = 'ExaminationTypes';
                result.mediaTypes = result.examinationMediaTypes ? [result.examinationMediaTypes] : null;
                break;

            case 'chemicalParameters':
                result.searchBy = 'Chemistry';
                result.mediaTypes = result.chemicalMediaTypes;
                break;

            case "chemicalParameterGroup":
                result.searchBy = 'Chemistry';
                result.mediaTypes = result.chemicalMediaTypes;
                break;

            case 'species':
                result.searchBy = 'Species';
                break;

            case 'sl':
                result.searchBy = 'SampleLocations';
                result.mediaTypes = result.locationMediaTypes
                break;
        }

        return result as MainSelectionValue;
    }

    private toStringArray(queryParams: Params, paramKey: string, acceptList: string[], typeKey: string): string[] {
        const param = _.get(queryParams, paramKey);
        let result = null;

        if (_.isString(param)) {
            result = [param];
        }

        if (_.isArray(param) && !_.isEmpty(param)) {
            result = param;
        }

        if (!_.isEmpty(result)) {
            const notFound = result.find(x => !acceptList.includes(x));

            if (!_.isNil(notFound)) {
                const message = StringExtensions.format(this._translateService.instant('errors.url.not_found'),
                    this._translateService.instant(typeKey), notFound);
                throw new Error(message);
            }
        }

        return result;
    }

    private toStancodeRefStringArray(queryParams: Params, paramKey: string, acceptList: string[], typeKey: string, oldListMap?: Map<string, string>): string[] {
        const param = _.get(queryParams, paramKey);
        let result: string[] = null;

        if (_.isString(param)) {
            result = [param];
        }

        if (_.isArray(param) && !_.isEmpty(param)) {
            result = param;
        }

        if (!_.isEmpty(result)) {
            const invalidFormat = result.find(x => !this._stancodeRefValueRegex.test(x));

            if (!_.isNil(invalidFormat)) {
                const message = StringExtensions.format(this._translateService.instant('errors.url.invalid_stancoderef_format'),
                    this._translateService.instant(typeKey), invalidFormat);
                throw new Error(message);
            }

            let notFound = result.find(x => !acceptList.includes(x));
            if (oldListMap) {
                // Lookup the query param from old list of parameter, if it match any we return that mapping into result
                const foundInOldList = Array.from(oldListMap.entries()).filter(x => result.includes(x[0]) && acceptList.includes(x[1]));
                if (foundInOldList) {
                    foundInOldList.forEach((x) => {
                        result.push(x[1]);
                    });

                    notFound = null;
                }
            }

            if (!_.isNil(notFound)) {
                const message = StringExtensions.format(this._translateService.instant('errors.url.not_found'),
                    this._translateService.instant(typeKey), notFound);
                throw new Error(message);
            }
        }

        return result;
    }

    private toPeriodValue(queryParams: Params): PeriodValue {
        let result: Partial<PeriodValue> = {
            startDate: this.toDate(queryParams, 'startDate'),
            endDate: this.toDate(queryParams, 'endDate'),
            showLatestExaminationsResultsOnly: this.toBoolean(queryParams, 'showLatestExaminationsResultsOnly', 'Show latest examination results only')
        };
        result = _.pickBy(result, p => !_.isNil(p));

        if (_.isEmpty(result)) { return null; }

        this.validatePeriod(result);
        return result;
    }

    private validatePeriod(period: PeriodValue): void {
        if (_.isNil(period.startDate) || _.isNil(period.endDate)) { return; }

        if (period.startDate > period.endDate) {
            throw new Error(this._translateService.instant('errors.url.invalid_period'));
        }
    }

    private toDate(queryParams: Params, paramKey: string): Date {
        const param = _.get(queryParams, paramKey);
        return _.isEmpty(param) ? null : (new Date(param.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')));
    }

    private toBoolean(queryParams: Params, paramKey: string, type: string): boolean {
        const stringValue = this.toString(queryParams, paramKey, type);
        return stringValue?.toLowerCase() === 'true' ? true : null;
    }

    private toGeographicalValue(queryParams: Params): Observable<GeographicalValue> {
        let result: Partial<GeographicalValue & { polygonId: string, mapPointCoordinate: number[] }> = {
            place: this.toString(queryParams, 'place', 'Place'),
            municipality: this.toStringArray(queryParams, 'municipality',
                this._metadata.municipalities.map(x => x.value), 'area_selection.search.municipality.label'),
            region: this.toStringArray(queryParams, 'region',
                this._metadata.regions.map(x => x.value), 'area_selection.search.region.label'),
            polygonId: this.toString(queryParams, 'polygonId', 'Polygon'),
            mapView: this.toRectanglePolygon(queryParams),
            mapPointCoordinate: this.toMapPointCoordinate(queryParams)
        };

        result = _.pickBy(result, p => !_.isNil(p));
        const keys = _.keys(result);

        if (keys.length > 1) {
            throw new Error(this._translateService.instant('errors.url.mutiple_area_criterias'));
        }

        // polygon
        if (!_.isEmpty(result.polygonId)) {
            return this._polygonService.getPolygon(result.polygonId).pipe(map(fs => {
                return {
                    searchBy: AreaType.Polygon,
                    polygonType: 'Polygon',
                    polygon: {
                        id: result.polygonId,
                        features: fs
                    }
                };
            }));
        }

        // map point
        if (!_.isEmpty(result.mapPointCoordinate)) {
            return this._wfsService.getLakeFeaturesByPoint(result.mapPointCoordinate).pipe(map(fs => {
                return {
                    searchBy: AreaType.Polygon,
                    polygonType: 'MapPoint',
                    polygon: {
                        features: fs,
                        representativeCoordinate: result.mapPointCoordinate
                    }
                };
            }));
        }

        if (_.isEmpty(result)) {
            return of(null);
        }

        const key = keys[0];

        switch (key) {
            case 'place':
                result.searchBy = AreaType.Place;
                break;

            case 'municipality':
                result.searchBy = AreaType.Municipality;
                break;

            case 'region':
                result.searchBy = AreaType.Region;
                break;

            case 'mapView':
                result.searchBy = AreaType.MapView;
                break;
        }

        return of(result as GeographicalValue);
    }

    private toRectanglePolygon(queryParams: Params): LPolygon {
        let rectParams: any = {
            minX: this.toFloat(queryParams, 'mvMinX', 'errors.url.map_view.min_x'),
            maxX: this.toFloat(queryParams, 'mvMaxX', 'errors.url.map_view.max_x'),
            minY: this.toFloat(queryParams, 'mvMinY', 'errors.url.map_view.min_y'),
            maxY: this.toFloat(queryParams, 'mvMaxY', 'errors.url.map_view.max_x'),
        };

        rectParams = _.pickBy(rectParams, p => !_.isNil(p));
        const keys = _.keys(rectParams);

        if (keys.length === 0) { return null; }

        if (keys.length < 4) {
            throw new Error(this._translateService.instant('errors.url.invalid_map_view'));
        }

        if (rectParams.minX >= rectParams.maxX || rectParams.minY >= rectParams.maxY) {
            throw new Error(this._translateService.instant('errors.url.invalid_map_view'));
        }

        return {
            features: [
                new Feature({
                    geometry: fromExtent([rectParams.minX, rectParams.minY, rectParams.maxX, rectParams.maxY])
                })
            ]
        };
    }

    private toMapPointCoordinate(queryParams: Params): number[] {
        let rectParams: any = {
            x: this.toFloat(queryParams, 'mapPointX', 'errors.url.map_point.x'),
            y: this.toFloat(queryParams, 'mapPointY', 'errors.url.map_point.y'),
        };

        rectParams = _.pickBy(rectParams, p => !_.isNil(p));
        const keys = _.keys(rectParams);

        if (keys.length === 0) { return null; }

        if (keys.length < 2) {
            throw new Error(this._translateService.instant('errors.url.invalid_map_point'));
        }

        return [rectParams.x, rectParams.y];
    }

    private toFloat(queryParams: Params, paramKey: string, typeKey: string): number {
        const stringValue = this.toString(queryParams, paramKey, typeKey);

        if (_.isEmpty(stringValue)) { return null; }

        if (!this._urlNumberRegex.test(stringValue)) {
            const errorMessage = StringExtensions.format(this._translateService.instant('errors.url.invalid_number'),
                this._translateService.instant(typeKey), stringValue);
            throw new Error(errorMessage);
        }

        return parseFloat(stringValue);
    }

    private toString(queryParams: Params, paramKey: string, typeKey: string, acceptList: string[] = null): string {
        const value = _.get(queryParams, paramKey);

        if (_.isNil(value)) { return null; }

        if (Array.isArray(value)) {
            const message = StringExtensions.format(this._translateService.instant('errors.url.multiple_values'),
                this._translateService.instant(typeKey));
            throw new Error(message);
        }

        if (!_.isEmpty(acceptList)) {
            const notFound = acceptList.find(x => !acceptList.includes(value));

            if (!_.isNil(notFound)) {
                const message = StringExtensions.format(this._translateService.instant('errors.url.not_found'),
                    this._translateService.instant(typeKey), notFound);
                throw new Error(message);
            }
        }

        return value;
    }

    private toDataResponsibleValue(queryParams: Params): string[] {
        const result = this.toStringArray(
            queryParams,
            'dr',
            this._metadata.dataResponsibles?.map(x => x.cvr) || [],
            'data_responsible_selection.data_responsible'
        );

        return _.isEmpty(result) ? null : result;
    }

    private toStationOwnerValue(queryParams: Params): string[] {
        const result = this.toStringArray(
            queryParams,
            'sw',
            this._metadata.stationOwners?.map(x => x.cvr) || [],
            'station_owner_selection.station_owner'
        );

        return _.isEmpty(result) ? null : result;
    }

    private toDataOperatorValue(queryParams: Params): string[] {
        const result = this.toStringArray(
            queryParams,
            'so',
            this._metadata.stationOperators?.map(x => x.cvr) || [],
            'station_operator_selection.station_operator'
        );

        return _.isEmpty(result) ? null : result;
    }

    public reset() {
        // Reset everything except the main selection SearchBy
        const searchBy = this.form.value.mainSelection?.searchBy ?? 'Chemistry';
        this.form.reset();
        this.form.patchValue({ mainSelection: { searchBy } });
    }
}
