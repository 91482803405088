import { Injectable } from '@angular/core';

import { ApiUrlService } from './api-url.service';
import { DownloadRequest } from './requests/download.request';
import { RawPeriodRequest, toRawPeriodRequest } from './requests/raw-requests';
import { HttpClient } from '@angular/common/http';
import { HttpHelper } from 'src/app/utils/http.helper';
import { BehaviorSubject, Observable } from 'rxjs';
interface RawDownloadRequest extends Omit<DownloadRequest, 'period'> {
    period?: RawPeriodRequest;
}

@Injectable()
export class DownloadService {
    constructor(private _htttp: HttpClient, private _apiUrlService: ApiUrlService) {
    }


    public download(request: DownloadRequest): Observable<boolean> {
        const rawRequest = this.toRawDownloadRequest(request);
        const url = this._apiUrlService.download();
        const loadingSubject = new BehaviorSubject<boolean>(true);

        HttpHelper.fetchBlob(this._htttp, url, rawRequest).subscribe({
            next: (res: any) => {
                loadingSubject.next(false);
                HttpHelper.downloadBlob(res, `Download-${new Date().toLocaleDateString()}.csv`);
            },
            error: () => {
                loadingSubject.next(false);
            }
        });

        return loadingSubject.asObservable();
    }

    private toRawDownloadRequest(request: DownloadRequest): RawDownloadRequest {
        return {
            ...request,
            period: toRawPeriodRequest(request.period)
        };
    }
}
