import { Component, HostBinding, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dataud-desktop-side-bar',
    templateUrl: './dataud-desktop-side-bar.component.html',
    styleUrls: ['./dataud-desktop-side-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopSideBarComponent {
    @HostBinding('class.expanded') public expanded = true;
    @Output() reset = new EventEmitter<any>();

    public toggle(): void {
        this.expanded = !this.expanded;
    }

    public onResetClicked(): void {
        this.reset.emit();
    }
}
