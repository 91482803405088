<div class="d-flex flex-column justify-content-between flex-fill">
    <div class="filters-container flex-fill" [class.has-link]="hasLink" [formGroup]="form">
        <div class="filter-item">
            <dataud-main-selection formControlName="mainSelection"></dataud-main-selection>
        </div>

        <hr>
        <div class="filter-item">
            <dataud-geographical-selection #subFilter formControlName="geographicalSelection" (beginUpload)="setLoading(true)" (endUpload)="setLoading(false)">
                <ng-container *ngIf="mainSelectionSearchBy !== 'SampleLocations'">
                    <hr class="my-4 mx-0">
                    <div class="filter-item">
                        <dataud-period-selection formControlName="period"></dataud-period-selection>
                    </div>
                </ng-container>

                <hr class="my-4 mx-0">

                <div *ngIf="mainSelectionSearchBy !== 'SampleLocations'" class="filter-item">
                    <dataud-data-responsible-selection formControlName="dataResponsibles"></dataud-data-responsible-selection>
                </div>

                <ng-container *ngIf="mainSelectionSearchBy === 'SampleLocations'">
                    <div class="filter-item">
                        <dataud-station-owner-selection formControlName="stationOwners"></dataud-station-owner-selection>
                    </div>
                    <div class="filter-item mt-4">
                        <dataud-station-operator-selection formControlName="stationOperators"></dataud-station-operator-selection>
                    </div>
                    <div *ngIf="false" class="filter-item mt-4">
                        <dataud-measured-parameter-selection formControlName="measuredParameters"></dataud-measured-parameter-selection>
                    </div>
                </ng-container>
            </dataud-geographical-selection>
        </div>

    </div>

    <dataud-save-search [class.d-none]="!hasLink"></dataud-save-search>
</div>

<dataud-loading-spinner *ngIf="isLoading"></dataud-loading-spinner>