import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiUrlService } from './api-url.service';
import { HydroStationResponse } from './responses/station.response';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class HydroStationService {
    constructor(private _htttp: HttpClient, private _apiUrlService: ApiUrlService) {
    }

    public station(id: string): Observable<HydroStationResponse> {
        const url = this._apiUrlService.getStation(id);
        return this._htttp.get<HydroStationResponse[]>(url).pipe(
            map((stations) =>{
                if(!stations?.length) return null;
                return this.includedLatestResult(stations[0]);
            })
        );
    }

    private includedLatestResult(station: HydroStationResponse) {
        // Find latest result date for each measurement point
        station.measurementPoints?.forEach((mp: any) => {
            if (mp.examinations.length === 0) return;
            mp.latestExamination = mp.examinations.reduce((a, b) => {
                return new Date(a.latestResult) > new Date(b.latestResult) ? a : b;
            });
        });
        // Find latest result date for station (latest result date for all measurement points)
        const latestResultDate = station.measurementPoints?.reduce((a, b) => {
            if (!a.latestExamination) return b;
            if (!b.latestExamination) return a;
            return new Date(a.latestExamination.latestResult) > new Date(b.latestExamination.latestResult) ? a : b;
        })?.latestExamination?.latestResult;

        station.latestResult = latestResultDate ? new Date(latestResultDate) : null;
        return station;
    }
}
