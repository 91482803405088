import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import _ from 'lodash';

import { DMPNotification, DMPNotificationTypeEnum } from 'src/app/models/dmp-notification.models';

@Component({
    selector: 'dataud-dmp-notification-box',
    templateUrl: './dataud-dmp-notification-box.component.html',
    styleUrls: ['./dataud-dmp-notification-box.component.scss']
})
export class DMPNotificationBoxComponent {
    private _model: DMPNotification;
    @Input() public set model(value: DMPNotification) {
        this._model = value;
    }
    public get model(): DMPNotification { return this._model; }

    @HostBinding('class.dmp-notification-info')
    public get info(): boolean { return this._model?.type === DMPNotificationTypeEnum.Info; }

    @HostBinding('class.dmp-notification-warning')
    public get warning(): boolean { return this._model?.type === DMPNotificationTypeEnum.Warning; }

    @HostBinding('class.dmp-notification-error')
    public get error(): boolean { return this._model?.type === DMPNotificationTypeEnum.Error; }

    @HostBinding('class.dmp-notification-default')
    public get default(): boolean { return _.isEmpty(this._model?.type); }

    @Output() public dismiss = new EventEmitter<any>();

    public onDismiss(): void {
        this.dismiss.emit();
    }
}
