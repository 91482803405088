import {
    Component, ChangeDetectionStrategy, OnInit, ViewChild, ViewContainerRef,
    ComponentFactoryResolver, Injector, ComponentRef, Type, ChangeDetectorRef
} from '@angular/core';

import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ResultsComponent } from '../../display/results/dataud-results.component';
import { FilterComponent } from '../../display/filter/dataud-filter.component';
import { AppConfig } from 'src/app/configurations/app-config';
import { LoadingIndicatorService } from 'src/app/services/communication/loading-indicator.service';
import { MobileViewComponent } from '../mobile-view/dataud-mobile-view.component';

@Component({
    selector: 'dataud-content-layout',
    templateUrl: './dataud-content-layout.component.html',
    styleUrls: ['./dataud-content-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ContentLayoutComponent implements OnInit {
    private readonly _mobileBreakPoint = AppConfig.layout.mobileBreakpoint;

    private _resultComponentRef: ComponentRef<ResultsComponent>;
    private _filterComponentRef: ComponentRef<FilterComponent>;

    @ViewChild('desktopFilter', { read: ViewContainerRef, static: true }) private _desktopFilterVC: ViewContainerRef;
    @ViewChild('desktopResult', { read: ViewContainerRef, static: true }) private _desktopResultVC: ViewContainerRef;
    @ViewChild('mobileView', { static: true }) private _mobileView: MobileViewComponent;

    public isLoading: boolean;

    // welcome dialog
    public welcomeShown: boolean;
    public notShowAgain = false;

    constructor(private _componentFactoryResolver: ComponentFactoryResolver,
                private _injector: Injector,
                private _cd: ChangeDetectorRef,
                loadingIndicator: LoadingIndicatorService) {
        loadingIndicator.loadingState$.subscribe({
            next: state => {
                this.isLoading = state;
                this._cd.markForCheck();
            }
        });
    }

    ngOnInit(): void {
        this.welcome();

        this._filterComponentRef = this.createCreateComponentRef(FilterComponent);
        this._resultComponentRef = this.createCreateComponentRef(ResultsComponent);
        this.updateResponsiveView(window.innerWidth >= this._mobileBreakPoint);

        fromEvent(window, 'resize').pipe(
            map(() => window.innerWidth),
            debounceTime(100),
            map(x => x >= this._mobileBreakPoint),
            distinctUntilChanged()
        ).subscribe({
            next: desktopMode => this.updateResponsiveView(desktopMode)
        });
    }

    private createCreateComponentRef<T>(component: Type<T>): ComponentRef<T> {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(component);
        return componentFactory.create(this._injector);
    }

    private updateResponsiveView(desktopMode: boolean): void {
        this.updateComponentContainerByWindowWidth(this._filterComponentRef, this._desktopFilterVC,
            this._mobileView.filtersViewContainerRef, desktopMode);
        this.updateComponentContainerByWindowWidth(this._resultComponentRef, this._desktopResultVC,
            this._mobileView.resultsViewContainerRef, desktopMode);
    }

    private updateComponentContainerByWindowWidth<T>(componentRef: ComponentRef<T>,
                                                     desktopViewContainerRef: ViewContainerRef, mobileViewContainerRef: ViewContainerRef,
                                                     desktopMode: boolean): void {

        let attachViewRef = null;
        let detachViewRef = null;

        if (desktopMode) {
            attachViewRef = desktopViewContainerRef;
            detachViewRef = mobileViewContainerRef;
        } else {
            attachViewRef = mobileViewContainerRef;
            detachViewRef = desktopViewContainerRef;
        }

        const index = detachViewRef.indexOf(componentRef.hostView);

        if (index >= 0) {
            detachViewRef.detach(index);
        }

        attachViewRef.insert(componentRef.hostView);
    }

    private welcome(): void {
        this.notShowAgain = localStorage.getItem(AppConfig.webStorage.localStorage.welcomDialog.notShowAgain) === 'true';

        if (!this.notShowAgain) {
            this.welcomeShown = true;
        }

        this._cd.markForCheck();
    }

    public start(): void {
        localStorage.setItem(AppConfig.webStorage.localStorage.welcomDialog.notShowAgain, this.notShowAgain ? 'true' : 'false');
        this.welcomeShown = false;
        this._cd.markForCheck();
    }

    public resetFilters() {
        this._filterComponentRef.instance.reset();
    }
}
