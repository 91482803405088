<div class="box">
    <div *ngIf="info" class="top">
        <i class="far fa-info-circle"></i>
        <span>{{ 'dmp_notification.info' | translate }}</span>
        <i class="far fa-times dismiss" (click)="onDismiss()"></i>
    </div>
    <div *ngIf="warning" class="top">
        <i class="far fa-exclamation-triangle"></i>
        <span>{{ 'dmp_notification.warning' | translate }}</span>
        <i class="far fa-times dismiss" (click)="onDismiss()"></i>
    </div>
    <div *ngIf="error" class="top">
        <i class="far fa-times-octagon"></i>
        <span>{{ 'dmp_notification.error' | translate }}</span>
        <i class="far fa-times dismiss" (click)="onDismiss()"></i>
    </div>
    <div *ngIf="default" class="top">
        <i class="far fa-comment-alt-lines"></i>
        <span>{{ 'dmp_notification.message' | translate }}</span>
        <i class="far fa-times dismiss" (click)="onDismiss()"></i>
    </div>

    <div class="detail">
        <div *ngIf="model?.title" class="title">{{ model.title }}</div>
        <div [innerHTML]="model?.description"></div>
    </div>
</div>