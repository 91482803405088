<ng-container *ngIf="type === 'radio'">
    <div class="custom-control custom-radio">
        <input type="radio" [attr.id]="id" class="custom-control-input" [checked]="checked" [disabled]="disabled"/>
        <label class="custom-control-label" [attr.for]="id">{{label}}</label>
    </div>
</ng-container>

<ng-container *ngIf="type === 'checkbox'">
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" [attr.id]="id" [checked]="checked" [disabled]="disabled"/>
        <label class="custom-control-label" [attr.for]="id">{{label}}</label>
    </div>
</ng-container>