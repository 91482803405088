import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

export class HttpHelper {
    public static getFileNameFromContentDispositionHeader(headers: HttpHeaders, key: string): string {
        const contentDispositionHeader = 'content-disposition';
        return headers?.get(contentDispositionHeader)?.split(';')[1]?.split(key)[1]?.split('=')[1]?.replace(/\"/g, '').trim();
    }

    public static getBlobResponseOptions(): {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType: 'blob';
        withCredentials?: boolean;
    } {
        return { responseType: 'blob', observe: 'response' };
    }

    public static fetchBlob(httpClient: HttpClient, url: string, payload: object, fileName?: string) {
        return httpClient.post(url, payload, HttpHelper.getBlobResponseOptions())
    }

    public static downloadBlob(res, fileName?: string): void {
        try {
            const serverFileName = HttpHelper.getFileNameFromContentDispositionHeader(res.headers, 'filename');
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.body]));
            link.download = serverFileName ?? fileName;
            link.click();
        } catch (e) {
            console.log(`Failed to download. `, e);
        }
    }
}
