import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable()
export class SearchLinkService {
    private _linkSubject: Subject<string>;

    constructor() {
        this._linkSubject = new Subject<string>();
    }

    public dispatchLink(link: string): void {
        this._linkSubject.next(link);
    }

    public get link$(): Observable<string> {
        return this._linkSubject.asObservable();
    }
}
