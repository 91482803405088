import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import * as _ from 'lodash';

import { ToasterService } from '../communication/toaster.service';
import { ToastType, HttpErrorData } from 'src/app/models/toast.models';

interface HttpServerError {
    datetime: string;
    httpCode: string;
    message: string;
    statusCode: number;
}

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(private _toastService: ToasterService) {
    }

    handleError(error: any): void {
        if (this.handleHttpError(error)) { return; }

        console.error(error);
    }

    private handleHttpError(error: any): boolean {
        if (error instanceof HttpErrorResponse) {
            this.notifyHttpError(error);
            return true;
        }

        return false;
    }

    private notifyHttpError(error: HttpErrorResponse): void {
        // default http error
        const errorData: HttpErrorData = {
            status: error.status,
            statusText: error.statusText,
            url: error.url,
            dateTime: new Date(),
            message: error.message
        };

        // customize for errors coming from api
        // Todo: using datetime for determined server, find better solution later
        if (_.isString(error?.error?.datetime)) {
            const serverError = error.error as HttpServerError;
            errorData.dateTime = new Date(serverError.datetime);
            errorData.message = serverError.message;
        }

        this._toastService.toast({
            type: ToastType.HttpError,
            data: errorData
        });
    }
}
