<div class="header-panel">
    <div class="header">
        <div text-role="primary-header">
            {{'content_headers.search_environmental_data' | translate}}
        </div>
        <button type="button" class="btn reset-btn dmp-link" (click)="onResetClicked()">
            <i class="far fa-undo"></i>
            <span>{{'content_headers.reset' | translate}}</span>
        </button>
    </div>

    <button type="button" class="btn expand-btn link-primary" [class.expanded]="expanded" (click)="toggle()">
        <i class="fa-regular" [class.fa-arrow-left-to-line]="expanded" [class.fa-arrow-right-to-line]="!expanded"></i>
    </button>
</div>

<div class="content-container flex-fill" [class.invisible]="!expanded">
    <ng-content></ng-content>
</div>