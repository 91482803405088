export interface MainSelectionValue {
    searchBy: 'ExaminationTypes' | 'Chemistry' | 'Species' | 'SampleLocations';
    examinationTypes: string[];
    chemicalParameters: string[];
    chemicalParameterGroup: string;
    species: string[];
    chemicalMediaTypes: string[];
    locationMediaTypes: string[];
    examinationMediaTypes: string;
    mediaTypes: string[];
}

export enum ChemistryOption {
    ChemicalParameters = 'chemicalParameters',
    ChemicalParameterGroup = 'chemicalParameterGroup'
}
