<dataud-collapse-container #collapseSubFilter [header]="'secondary_selection.select_filters' | translate" [formGroup]="form">
    <div class="row">
        <div class="col-5 pr-0">
            <dataud-check-box [handleCheckedManually]="true" [label]="'area_selection.map_area.label' | translate" [checked]="mode === 'MapView'" [disabled]="isDisabled"
                (clicked)="updateMode('MapView')">
            </dataud-check-box>
        </div>

        <div class="col-4 pl-0">
            <dataud-check-box [handleCheckedManually]="true" [label]="'area_selection.draw_area.label' | translate" [checked]="mode === 'Polygon'" [disabled]="isDisabled"
                (clicked)="updateMode('Polygon')">
            </dataud-check-box>
        </div>
    </div>

    <div *ngIf="mode === 'MapView' && form.controls.mapView.value && mapSelectionArea" class="form-group-reverse d-flex align-items-center">
        <div class="d-flex align-items-center flex-wrap area-display">
            <span>{{'area_selection.map_area.legend' | translate}}</span>
            <div class="area-text"> ({{mapSelectionArea}})</div>
        </div>
        <i class="far fa-horizontal-rule fa-rotate-90"></i>
        <button class="btn p-0 text-white" (click)="deleteMapView()">
            <i class="fas fa-trash-alt text-danger"></i>
        </button>
    </div>

    <div class="form-group-reverse">
        <div class="row">
            <div class="col-5 pr-0">
                <dataud-check-box [handleCheckedManually]="true" [label]="'area_selection.search.sampling_location.label' | translate" [disabled]="isDisabled"
                    [checked]="searchType === 'Place'" (clicked)="updateSearchType('Place')">
                </dataud-check-box>
            </div>

            <div class="col-4 p-0">
                <dataud-check-box [handleCheckedManually]="true" [label]="'area_selection.search.municipality.label' | translate" [disabled]="isDisabled"
                    [checked]="searchType === 'Municipality'" (clicked)="updateSearchType('Municipality')">
                </dataud-check-box>
            </div>

            <div class="col-3 pl-0">
                <dataud-check-box [handleCheckedManually]="true" [label]="'area_selection.search.region.label' | translate" [disabled]="isDisabled"
                    [checked]="searchType === 'Region'" (clicked)="updateSearchType('Region')">
                </dataud-check-box>
            </div>
        </div>

        <div *ngIf="searchType === 'Place'" class="search-input-container form-group-reverse">
            <input class="form-control search-text" type="text" [attr.placeholder]="'area_selection.search.sampling_location.placeholder' | translate" formControlName="place">
            <i class="far fa-search text-info"></i>
        </div>

        <div *ngIf="searchType === 'Municipality'" class="form-group-reverse">
            <dataud-select formControlName="municipality" [placeholder]="'area_selection.search.municipality.placeholder' | translate" [items]="municipalitySource"
                bindLabel="displayName" bindValue="value" [multiple]="true">
            </dataud-select>

            <div *ngIf="form.controls.municipality.value && mapSelectionArea" class="area-display-text-container">
                <span class="area-display-text">{{'area_selection.search.area_legend_display' | translate}} ({{mapSelectionArea}})</span>
            </div>
        </div>

        <div *ngIf="searchType === 'Region'" class="form-group-reverse">
            <dataud-select formControlName="region" [placeholder]="'area_selection.search.region.placeholder' | translate" [items]="regionSource" bindLabel="displayName"
                bindValue="value" [multiple]="true">
            </dataud-select>

            <div *ngIf="form.controls.region.value && mapSelectionArea" class="area-display-text-container">
                <span class="area-display-text">{{'area_selection.search.area_legend_display' | translate}} ({{mapSelectionArea}})</span>
            </div>
        </div>
    </div>

    <div *ngIf="mode === 'Polygon'">
        <div class="d-flex justify-content-between flex-wrap">
            <button [disabled]="isDisabled" class="btn polygon-btn form-group-reverse" (click)="toggleDrawAreaSelection('Polygon')" [class.selected]="drawAreaSelection === 'Polygon'">
                <i class="fas fa-draw-polygon fa-lg"></i>
                <span class="d-block">{{'area_selection.draw_area.polygon' | translate}}</span>
            </button>

            <button [disabled]="isDisabled" class="btn polygon-btn form-group-reverse" (click)="toggleDrawAreaSelection('Rectangle')" [class.selected]="drawAreaSelection === 'Rectangle'">
                <i class="fas fa-vector-square fa-lg"></i>
                <span class="d-block">{{'area_selection.draw_area.rectangle' | translate}}</span>
            </button>

            <button [disabled]="isDisabled" class="btn polygon-btn form-group-reverse" (click)="openUploadDialog(fileInput)">
                <i class="fas fa-upload fa-lg"></i>
                <span class="d-block">{{'area_selection.draw_area.upload' | translate}}</span>
            </button>

            <button class="btn polygon-btn form-group-reverse" (click)="toggleDrawAreaSelection('MapPoint');" [class.selected]="drawAreaSelection === 'MapPoint'"
                [disabled]="!canSearchByMapPoint || isDisabled">
                <i class="fas fa-map-marker-alt fa-lg"></i>
                <span class="d-block">{{'area_selection.draw_area.search_on_map' | translate}}</span>
            </button>
        </div>

        <input #fileInput type="file" class="d-none" accept=".geojson" (change)="onPolygonUpload(fileInput)" />

        <div *ngIf="form.controls.polygon.value && mapSelectionArea" class="form-group-reverse d-flex align-items-center">
            <ng-container *ngIf="form.controls.polygonType.value == 'Polygon'">
                <span class="mb-1 area-display-text" [class.disabled-text]="isDrawingPolygon">{{'area_selection.draw_area.area_legend_display' | translate}}
                    ({{mapSelectionArea}})</span>
            </ng-container>

            <ng-container *ngIf="form.controls.polygonType.value == 'MapPoint'">
                <span class="mb-1 area-display-text" [class.disabled-text]="isDrawingPolygon">{{'area_selection.draw_area.area_lake_display' |
                    translate}} ({{mapSelectionArea}})</span>
            </ng-container>

            <i class="far fa-horizontal-rule fa-rotate-90"></i>
            <button [disabled]="isDisabled" class="btn p-0 text-white" (click)="deletePolygon()">
                <i class="fas fa-trash-alt text-danger"></i>
            </button>
        </div>
    </div>

    <ng-content></ng-content>

</dataud-collapse-container>