import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';

import * as _ from 'lodash';

import { SearchLinkService } from 'src/app/services/communication/search-link.service';
import { Clipboard } from 'src/app/utils/clipboard';

@Component({
    selector: 'dataud-save-search',
    templateUrl: 'dataudv-save-search.component.html',
    styleUrls: ['./dataudv-save-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [Location]
})
export class SaveSearchComponent implements OnDestroy {
    public link: string;
    public canCopy = false;

    private _subscription: Subscription;

    constructor(private _searchLinkService: SearchLinkService, private _location: Location, private _cd: ChangeDetectorRef) {
        this._subscription = _searchLinkService.link$.subscribe({
            next: link => {
                this._location.replaceState(link);

                if (_.isEmpty(link)) {
                    this.link = null;
                    this.canCopy = false;
                } else {
                    this.link = window.location.href;
                    this.canCopy = true;
                }

                _cd.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public copyToClipboard(): void {
        Clipboard.copy(this.link);
    }
}
