<ng-container [formGroup]="form">
    <div class="row">
        <div class="col-12 col-md-6 form-group position-relative">
            <div class="mb-2">
                <span>{{'period_selection.see_results_from' | translate}}</span>
            </div>

            <dataud-date-picker formControlName="startDate" [invalid]="form.errors?.invalidDateRange || form.controls.startDate.errors?.invalidDate" [minDate]="minDate"
                [maxDate]="maxDate" [scrollContainer]="'.filters-container'">
            </dataud-date-picker>

            <div class="position-relative">
                <div *ngIf="form.controls.startDate.errors?.invalidDate" class="invalid-feedback">{{'period_selection.invalid_date' | translate}}</div>
                <div *ngIf="form.errors?.invalidDateRange" class="invalid-feedback">{{'period_selection.invalid_date_range' | translate}}</div>
            </div>
        </div>

        <div class="col-12 col-md-6 form-group position-relative">
            <div class="mb-2">
                <span>{{'period_selection.see_results_to' | translate}}</span>
            </div>

            <dataud-date-picker formControlName="endDate" [invalid]="form.errors?.invalidDateRange || form.controls.endDate.errors?.invalidDate" [minDate]="minDate"
                [maxDate]="maxDate" [scrollContainer]="'.filters-container'">
            </dataud-date-picker>

            <div class="position-relative">
                <div *ngIf="form.controls.endDate.errors?.invalidDate" class="invalid-feedback">{{'period_selection.invalid_date' | translate}}</div>
            </div>
        </div>
    </div>

    <dataud-check-box [label]="'period_selection.limit_search_to_latest_examinations' | translate" [type]="'checkbox'" formControlName="showLatestExaminationsResultsOnly">
    </dataud-check-box>
</ng-container>