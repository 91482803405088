import {
    Component, Input, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectionStrategy,
    OnDestroy, forwardRef, ChangeDetectorRef, HostListener
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { Subject, Subscription, combineLatest, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';
import moment from 'moment';
import Inputmask from 'inputmask';

import { AppConfig } from 'src/app/configurations/app-config';

declare const $: any;

@Component({
    selector: 'dataud-date-picker',
    templateUrl: './dataud-date-picker.component.html',
    styleUrls: ['./dataud-date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        }
    ]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() defaultViewDate: Date;
    @Input() scrollContainer: string | Element;
    @Input() invalid: boolean;

    public isDisabled: boolean;

    @ViewChild('input', { static: true }) private _inputElRef: ElementRef;
    private get _inputEl(): HTMLInputElement {
        return this._inputElRef?.nativeElement as HTMLInputElement;
    }

    private _language: string;

    private _initSubject: Subject<boolean>;
    private _valueSubject: Subject<Date | string>;
    private _outValueSubject: BehaviorSubject<Date>;
    private _outValueSubscription: Subscription;
    private _innerSubscriptions: Subscription[];
    private _isPatchingValue = false;

    private _inputMask: Inputmask;
    private readonly _inputValidationRegex = /[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/;
    private readonly _dateFormat = 'DD/MM/YYYY';

    constructor(private _ngZone: NgZone, private _cd: ChangeDetectorRef) {
        this._initSubject = new Subject<boolean>();
        this._valueSubject = new Subject<Date | string>();
        this._innerSubscriptions = [];
        this._language = localStorage.getItem(AppConfig.webStorage.localStorage.currentLanguage);
        this._inputMask = new Inputmask('99/99/9999', { greedy: false });

        $.fn.datepicker.dates.da = {
            days: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
            daysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
            daysMin: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
            months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
            monthsShort: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Maj', 'Juni', 'Juli', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dec.'],
            today: 'I dag',
            clear: 'Ryd',
            format: 'dd/mm/yyyy',
            titleFormat: 'MM yyyy', /* Leverages same syntax as 'format' */
            weekStart: 1
        };
    }

    ngOnInit(): void {
        this._innerSubscriptions.push(
            combineLatest([
                this._valueSubject,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    this._ngZone.runOutsideAngular(() => {
                        this._isPatchingValue = true;
                        let initDate: Date;

                        if (_.isNil(value)) {
                            $(this._inputEl).datepicker('clearDates');
                        } else {
                            initDate = new Date(value);
                            $(this._inputEl).datepicker('update', initDate);
                        }

                        // create new outter flow with init value to prevent trigger search when selecting previous value
                        this._outValueSubscription?.unsubscribe();
                        this._outValueSubject = new BehaviorSubject<Date>(initDate);
                        this._outValueSubscription = this._outValueSubject
                            .pipe(
                                map(date => {
                                    if (_.isNil(date)) {
                                        return null;
                                    }

                                    // sync outter date
                                    return (new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)).getTime();
                                })
                            )
                            .subscribe({
                                next: dateNumber => {
                                    const outterValue = _.isNil(dateNumber) ? undefined : new Date(dateNumber);

                                    this._ngZone.run(() => {
                                        this._onChange(outterValue);
                                    });
                                }
                            });

                        this._isPatchingValue = false;
                    });
                }
            })
        );



        this._ngZone.runOutsideAngular(() => {
            this._inputMask.mask(this._inputEl);
            const me = this;

            $(this._inputEl).datepicker({
                autoclose: true,
                templates: {
                    leftArrow: '<i class="far fa-chevron-left"></i>',
                    rightArrow: '<i class="far fa-chevron-right"></i>'
                },
                clearBtn: true,
                startDate: this.minDate,
                endDate: this.maxDate,
                defaultViewDate: this.defaultViewDate,
                language: this._language,
                keyboardNavigation: false,
                // https://bootstrap-datepicker.readthedocs.io/en/stable/options.html#zindexoffset
                // To make the date picker appear on top of everything. So by setting z-index: 2000
                zIndexOffset: 2000,
                format: {
                    toDisplay: (date: Date) => {
                        if (_.isNil(date)) {
                            return 'null';
                        }

                        return moment(date).format(this._dateFormat);
                    },
                    toValue: (date: string) => {
                        return me.convertInputStringToDate(date);
                    }
                },
                forceParse: false
            });

            $(this._inputElRef.nativeElement).datepicker().on('changeDate', (e: { dates: Date[] }) => {
                if (this._isPatchingValue) { return; }

                this._outValueSubject?.next(e.dates[0]);
            });

            $(this._inputElRef.nativeElement).on('input', () => {
                // manually raise outter change because Bootstrap date picker doesn't fire any event for input
                const date = this.convertInputStringToDate($(this._inputEl).val());
                this._outValueSubject?.next(date);
            });

            if (!_.isNil(this.scrollContainer)) {
                $(this.scrollContainer).on('scroll', () => {
                    const scrollOffset = $(this.scrollContainer).offset();
                    const scrollHeight = $(this.scrollContainer).height();
                    const inputOffset = $(this._inputEl).offset();

                    if (inputOffset.top > scrollOffset.top + scrollHeight) {
                        $(this._inputElRef.nativeElement).datepicker('hide');
                    } else {
                        $(this._inputElRef.nativeElement).datepicker('place');
                    }
                });
            }
        });

        this._initSubject.next(true);
    }

    ngOnDestroy(): void {
        this._outValueSubscription?.unsubscribe();
        this._innerSubscriptions.forEach(sub => sub.unsubscribe());
    }

    //#region ControlValueAccessor

    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: Date | string): void {
        this._valueSubject.next(obj);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this._cd.markForCheck();
    }

    //#endregion

    //#region Validator

    validate(control: AbstractControl): ValidationErrors {
        const value = control?.value;
        const input = this._inputEl?.value;

        if (_.isNil(value) && !_.isEmpty(input)) {
            return { invalidDate: true };
        }

        return null;
    }

    //#endregion


    @HostListener('window:resize')
    private autoCloseWhenWindowResize(): void {
        this._ngZone.runOutsideAngular(() => {
            $(this._inputEl).datepicker('hide');
        });
    }

    @HostListener('click')
    private onclick(): void {
        this._ngZone.runOutsideAngular(() => {
            $(this._inputEl).datepicker('show');
        });
    }

    private convertInputStringToDate(inputString): Date {
        if (!this._inputValidationRegex.test(inputString)) {
            return null;
        }

        const m = moment(inputString, this._dateFormat);

        if (m.isValid()) {
            const date = m.toDate();
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
        }

        return null;
    }
}
