import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoadingIndicatorService {
    private _loadingSubject: Subject<boolean>;

    constructor() {
        this._loadingSubject = new Subject<boolean>();
    }

    public get loadingState$(): Observable<boolean> {
        return this._loadingSubject.asObservable();
    }

    public start(): void {
        this._loadingSubject.next(true);
    }

    public end(): void {
        this._loadingSubject.next(false);
    }
}
