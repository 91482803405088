import { Injectable, TemplateRef } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { MapMarker } from 'src/app/components/layout/map/map.models';
import { Polygon } from 'src/app/models/polygon.model';
import { GeographicalRequest } from 'src/app/models/geographical-request.model';

@Injectable()
export class MapInteractionService {
    private _markersSubject: Subject<MapMarker[]>;
    private _zoomExtendSubject: Subject<boolean>;
    private _drawingStateSubject: Subject<'Polygon' | 'Rectangle' | null>;
    private _drawingState$: Observable<'Polygon' | 'Rectangle' | null>;
    private _markerPopupSubject: Subject<TemplateRef<any>>;
    private _markerClickSubject: Subject<any>;
    private _searchPolygonSubject: Subject<Polygon>;
    private _showGeographicRequestSubject: Subject<GeographicalRequest>;
    private _selectMarkerIds: Subject<string[]>;
    private _highlightedMarkerIds: Subject<string[]>;
    private _centerSubject: Subject<number[]>;
    private _searchMapViewSubject: Subject<Polygon>;
    private _mapSearchState: Subject<boolean>;
    private _mapSearchStateObservable$: Observable<boolean>;
    private _exceedMaxStationWarningStateSubject: Subject<boolean>;
    private _exceedMaxStationWarningState$: Observable<boolean>;
    private _mapSelectionAreaSubject: Subject<string>;
    private _searchByMapPointAbilitySubject: Subject<boolean>;
    private _mapClickSubject: Subject<number[]>;
    private _mapPointSelectableSubject: Subject<boolean>;
    private _closePopupSubject: Subject<any>;
    private _openSelectedMarkerPopupSubject: Subject<any>;
    private _toggleSearchByAreaButtonSubject: Subject<boolean>;

    constructor() {
        this._markersSubject = new Subject<MapMarker[]>();
        this._zoomExtendSubject = new Subject<boolean>();
        this._drawingStateSubject = new Subject<'Polygon' | 'Rectangle' | null>();
        this._markerPopupSubject = new Subject<TemplateRef<any>>();
        this._markerClickSubject = new Subject<any>();
        this._drawingState$ = this._drawingStateSubject.pipe(distinctUntilChanged());
        this._searchPolygonSubject = new Subject<Polygon>();
        this._showGeographicRequestSubject = new Subject<GeographicalRequest>();
        this._selectMarkerIds = new Subject<string[]>();
        this._highlightedMarkerIds = new Subject<string[]>();
        this._centerSubject = new Subject<number[]>();
        this._searchMapViewSubject = new Subject<Polygon>();
        this._mapSearchState = new Subject<boolean>();
        this._mapSearchStateObservable$ = this._mapSearchState.pipe(distinctUntilChanged());
        this._exceedMaxStationWarningStateSubject = new Subject<boolean>();
        this._exceedMaxStationWarningState$ = this._exceedMaxStationWarningStateSubject.pipe(distinctUntilChanged());
        this._mapSelectionAreaSubject = new Subject<string>();
        this._searchByMapPointAbilitySubject = new Subject<boolean>();
        this._mapClickSubject = new Subject<number[]>();
        this._mapPointSelectableSubject = new Subject<boolean>();
        this._closePopupSubject = new Subject<any>();
        this._openSelectedMarkerPopupSubject = new Subject<any>();
        this._toggleSearchByAreaButtonSubject = new Subject<boolean>();
    }

    public updateMarkers(markers: MapMarker[]): void {
        this._markersSubject.next(markers);
    }

    public get markers$(): Observable<MapMarker[]> {
        return this._markersSubject.asObservable();
    }

    public zoomExtend(raiseMapViewChange = false): void {
        this._zoomExtendSubject.next(raiseMapViewChange);
    }

    public get zoomExtendRequest$(): Observable<boolean> {
        return this._zoomExtendSubject.asObservable();
    }
    public startDrawing(mode: 'Polygon' | 'Rectangle'): void {
        this._drawingStateSubject.next(mode);
    }

    public endDrawing(): void {
        this._drawingStateSubject.next(null);
    }

    public get drawingState$(): Observable<'Polygon' | 'Rectangle' | null> {
        return this._drawingState$;
    }

    public requestSearchPolygon(polygon: Polygon): void {
        this._searchPolygonSubject.next(polygon);
    }

    public get searchPolygon$(): Observable<Polygon> {
        return this._searchPolygonSubject.asObservable();
    }

    public requestShowGeographicData(request: GeographicalRequest): void {
        this._showGeographicRequestSubject.next(request);
    }

    public get showGeographicRequest$(): Observable<GeographicalRequest> {
        return this._showGeographicRequestSubject.asObservable();
    }

    public setMarkerPopup(template: TemplateRef<any>): void {
        this._markerPopupSubject.next(template);
    }

    public get markerPopup$(): Observable<TemplateRef<any>> {
        return this._markerPopupSubject.asObservable();
    }

    public emitMarkerClick(data: any): void {
        this._markerClickSubject.next(data);
    }

    public get clickMarkerData$(): Observable<any> {
        return this._markerClickSubject.asObservable();
    }

    public selectMarkers(ids: string[]): void {
        this._selectMarkerIds.next(ids);
    }

    public get selectMarkerIds$(): Observable<string[]> {
        return this._selectMarkerIds.asObservable();
    }

    public highlightMarkers(ids: string[]): void {
        this._highlightedMarkerIds.next(ids);
    }

    public get _highlightedMarkerIds$(): Observable<string[]> {
        return this._highlightedMarkerIds.asObservable();
    }

    public setCenter(center: number[]): void {
        this._centerSubject.next(center);
    }

    public get center$(): Observable<number[]> {
        return this._centerSubject.asObservable();
    }

    public requestSearchByMapView(polygon: Polygon): void {
        this._searchMapViewSubject.next(polygon);
    }

    public get searchMapViewRequest$(): Observable<Polygon> {
        return this._searchMapViewSubject.asObservable();
    }

    public startSearchByMapView(): void {
        this._mapSearchState.next(true);
    }

    public endSearchByMapView(): void {
        this._mapSearchState.next(false);
    }

    public get mapSearchState$(): Observable<boolean> {
        return this._mapSearchStateObservable$;
    }

    public showExceedMaxStationWarning(): void {
        this._exceedMaxStationWarningStateSubject.next(true);
    }

    public hideExceedMaxStationWarning(): void {
        this._exceedMaxStationWarningStateSubject.next(false);
    }

    public get exceedMaxStationWarningState$(): Observable<boolean> {
        return this._exceedMaxStationWarningState$;
    }

    public setMapSelectionArea(area: string): void {
        this._mapSelectionAreaSubject.next(area);
    }

    public get mapSelectionArea$(): Observable<string> {
        return this._mapSelectionAreaSubject.asObservable();
    }

    public updateSearchByMapPointAbility(available: boolean): void {
        this._searchByMapPointAbilitySubject.next(available);
    }

    public get searchByMapPointAbility$(): Observable<boolean> {
        return this._searchByMapPointAbilitySubject.asObservable();
    }

    public updateMapClickCoordinate(coordinate: number[]): void {
        this._mapClickSubject.next(coordinate);
    }

    public get mapClickCoordinate$(): Observable<number[]> {
        return this._mapClickSubject.asObservable();
    }

    public updateMapPointSelectable(value: boolean): void {
        this._mapPointSelectableSubject.next(value);
    }

    public get mapPointSelectable$(): Observable<boolean> {
        return this._mapPointSelectableSubject.asObservable();
    }

    public closePopup(){
        this._closePopupSubject.next();
    }

    public get closePopup$(): Observable<any> {
        return this._closePopupSubject.asObservable();
    }

    public openSelectedMarkerPopup(markerId, markerData: any){
        this._openSelectedMarkerPopupSubject.next({ markerId, markerData });
    }

    public get openSelectedMarkerPopup$(): Observable<any> {
        return this._openSelectedMarkerPopupSubject.asObservable();
    }

    public enableSearchByAreaButton(){
        this._toggleSearchByAreaButtonSubject.next(true);
    }
    
    public disableSearchByAreaButton(){
        this._toggleSearchByAreaButtonSubject.next(false);
    }

    public get toggleSearchByAreaButton$(): Observable<boolean> {
        return this._toggleSearchByAreaButtonSubject.asObservable();
    }
}
