import * as _ from 'lodash';

import { PeriodFilter } from 'src/app/models/filter.model';
import { DateTimeOffset } from 'src/app/utils/datetimeoffset';

export interface RawPeriodRequest extends Omit<PeriodFilter, 'fromDate' | 'toDate'> {
    fromDate?: string;
    toDate?: string;
}

export function toRawPeriodRequest(value: PeriodFilter): RawPeriodRequest {
    if (_.isEmpty(value)) { return null; }

    return {
        showLastResult: value.showLastResult,
        fromDate: DateTimeOffset.toDemmarkDatetimeOffsetString(value.fromDate),
        toDate: DateTimeOffset.toDemmarkDatetimeOffsetString(value.toDate)
    };
}
