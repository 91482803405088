import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

import { ApiUrlService } from './api-url.service';
import { SearchRequest } from './requests/search.request';
import { SearchResponse } from './responses/search.response';
import { DateTimeOffset } from 'src/app/utils/datetimeoffset';
import { TypeResult } from 'src/app/models/type-result.model';
import { StationResult } from 'src/app/models/station-result.model';
import { RawPeriodRequest, toRawPeriodRequest } from './requests/raw-requests';
import { SearchErrorResponse } from './responses/search-error.response';

interface RawSearchRequest extends Omit<SearchRequest, 'period'> {
    period?: RawPeriodRequest;
}

interface RawTypeResult extends Omit<TypeResult, 'firstExaminationDate' | 'lastExaminationDate'> {
    firstExaminationDate: string;
    lastExaminationDate: string;
}

interface RawStationResult extends Omit<StationResult, 'results'> {
    results: RawTypeResult[];
}

interface RawSearchResponse {
    canDownloadAll: boolean;
    exceedMaxStation: boolean;
    results: RawTypeResult[];
    stations: RawStationResult[];
    errors: SearchErrorResponse[];
}

@Injectable()
export class SearchService {
    constructor(private _htttp: HttpClient, private _apiUrlService: ApiUrlService) {
    }

    public search(request: SearchRequest): Observable<SearchResponse> {
        const url = this._apiUrlService.search();
        return this._htttp.post<RawSearchResponse>(url, this.toRawSearchRequest(request))
            .pipe(map(this.toSearchResponse.bind(this)));
    }

    //#region Converters

    private toRawSearchRequest(request: SearchRequest): RawSearchRequest {
        return {
            ...request,
            period: toRawPeriodRequest(request.period)
        };
    }

    private toSearchResponse(raw: RawSearchResponse): SearchResponse {
        return {
            ...raw,
            results: raw.results.map(this.toTypeResult),
            stations: raw.stations.map(station => {
                return {
                    ...station,
                    results: station.results.map(this.toTypeResult)
                };
            })
        };
    }

    private toTypeResult(raw: RawTypeResult): TypeResult {
        return {
            ...raw,
            firstExaminationDate: DateTimeOffset.toLocalDate(raw.firstExaminationDate),
            lastExaminationDate: DateTimeOffset.toLocalDate(raw.lastExaminationDate)
        };
    }

    //#endregion
}
