import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, forwardRef, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {
    UntypedFormBuilder, UntypedFormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR,
    NG_VALIDATORS, Validator, AbstractControl, ValidationErrors
} from '@angular/forms';

import { Subject, Subscription, combineLatest } from 'rxjs';

import * as _ from 'lodash';
import naturalCompare from "string-natural-compare";

import { MetaDataService } from 'src/app/services/api/metadata.service';
import { ParameterGroup, SubstanceParameter, ValueDisplayItem } from 'src/app/models/metadata.models';
import { MainSelectionValue } from './dataud-main-selection.model';
import { TranslateService } from '@ngx-translate/core';

import S2Selection from 'src/app/models/select2-selection';

import { ChemistryOption } from './dataud-main-selection.model'
import { SelectComponent } from '../../base/select/dataud-select.component';

declare const $: any;

@Component({
    selector: 'dataud-main-selection',
    templateUrl: './dataud-main-selection.component.html',
    styleUrls: ['dataud-main-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MainSelectionComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MainSelectionComponent),
            multi: true
        }
    ]
})
export class MainSelectionComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    public searchBy: string | 'ExaminationTypes' | 'Chemistry' | 'Species' | 'SampleLocations' = 'Chemistry';

    public readonly ChemistryOption = ChemistryOption;

    // form
    public form: UntypedFormGroup;
    public speciesForm: UntypedFormGroup;
    private readonly _initialFormValue = {
        searchBy: 'Chemistry',
        examinationTypes: null,
        chemicalParameters: null,
        chemicalParameterGroup: null,
        species: null,
        SampleLocations: null,
        mediaTypes: null,
        chemicalMediaTypes: null,
        locationMediaTypes: null,
        examinationMediaTypes: null
    };
    public isDisabled: boolean;
    public chemistryOption = ChemistryOption.ChemicalParameters;
    public chemistryParamsLink = "https://parameterlisten.miljoeportal.dk/";

    // dropdown sources
    public examinationTypeSource: ValueDisplayItem[];
    public chemistrySource: SubstanceParameter[];
    public parameterGroupSource: ParameterGroup[];
    public speciesSource: ValueDisplayItem[];
    public mediaSource: ValueDisplayItem[];
    public excludedMarinLoggerstationMediaSource: ValueDisplayItem[];

    // flow pipelines
    private _initSubject: Subject<boolean>;
    private _valueSubject: Subject<MainSelectionValue>;
    private _innerSubscriptions: Subscription[];

    private _chemistryNaturalCompareCI = (a: SubstanceParameter, b: SubstanceParameter) => naturalCompare(a.displayName, b.displayName, { caseInsensitive: true });
    private _chemistryParameterGroupNaturalCompareCI = (a: ParameterGroup, b: ParameterGroup) => naturalCompare(a.name, b.name, { caseInsensitive: true });

    private _labelReplaces: string;
    private _labelAcronyms: string;

    @ViewChild('examinationTypesSelect') private _examinationTypesSelect: SelectComponent;
    @ViewChild('species') private _speciesElRef: ElementRef;

    constructor(fb: UntypedFormBuilder,
        metadataService: MetaDataService,
        private _cd: ChangeDetectorRef,
        private _translateService: TranslateService) {
        // loading metadata
        const metadata = metadataService.metadata;
        this.examinationTypeSource = metadata.examinationTypes;
        this.chemistrySource = metadata.substanceParameters.sort(this._chemistryNaturalCompareCI);
        this.parameterGroupSource = metadata.parameterGroups.sort(this._chemistryParameterGroupNaturalCompareCI);
        this.speciesSource = metadata.species;
        this.mediaSource = metadata.mediaTypes;
        this.excludedMarinLoggerstationMediaSource = metadata.mediaTypes ?? [];

        // build form
        this.form = fb.group(this._initialFormValue, { validators: this.validateForm });
        this.speciesForm = fb.group({ speciesSelection: null });

        // set up flow
        this._initSubject = new Subject<boolean>();
        this._valueSubject = new Subject<MainSelectionValue>();
        this._innerSubscriptions = [
            combineLatest([
                this._valueSubject,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    if (_.isNil(value)) {
                        this.form.reset(this._initialFormValue, { onlySelf: true, emitEvent: true });
                        this.searchBy = this._initialFormValue.searchBy;
                    } else {
                        if (value.searchBy === 'ExaminationTypes') {
                            this._cd.detectChanges();

                            const selectedMedia = value.examinationMediaTypes;
                            if (selectedMedia) {
                                const mediaExaminationTypes = metadata.mediaTypeExaminationTypeMapping.find(x => x.mediaType === selectedMedia)?.examinationTypes ?? [];
                                this.examinationTypeSource = metadata.examinationTypes.filter(x => mediaExaminationTypes.includes(x.value));
                            } else {
                                this.examinationTypeSource = metadata.examinationTypes;
                            }

                            this._cd.detectChanges();
                            this._examinationTypesSelect?.updateData();
                        }
                        if (value.chemicalParameterGroup) {
                            this.updateChemistryOptionState(ChemistryOption.ChemicalParameterGroup);
                        }
                        this.form.patchValue(value, { onlySelf: true, emitEvent: false });
                        this.speciesForm.patchValue({ speciesSelection: value?.species }, { onlySelf: true, emitEvent: false });
                        this.searchBy = value.searchBy;
                    }

                    this._cd.markForCheck();
                }
            }),

            combineLatest([
                this.form.controls.examinationMediaTypes.valueChanges,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    if (this.form.value.searchBy === 'ExaminationTypes') {

                        this.examinationTypeSource = [];
                        this._cd.detectChanges();

                        const selectedMedia = value;
                        if (selectedMedia) {
                            const mediaExaminationTypes = metadata.mediaTypeExaminationTypeMapping.find(x => x.mediaType === selectedMedia)?.examinationTypes ?? [];
                            this.examinationTypeSource = metadata.examinationTypes.filter(x => mediaExaminationTypes.includes(x.value));
                        } else {
                            this.examinationTypeSource = metadata.examinationTypes;
                        }

                        this.form.patchValue({ examinationTypes: null });
                        this._cd.detectChanges();
                        this._examinationTypesSelect?.updateData();
                    }
                }
            }),

            combineLatest([
                this.form.valueChanges,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    this._onChange(value);
                }
            }),
        ];

        Promise.all([
            this._translateService.get('main_selection.chemistry.replaces').toPromise(),
            this._translateService.get('main_selection.chemistry.acronyms').toPromise()
        ]).then(([replaces, acronyms]) => {
            this._labelReplaces = `${replaces}`;
            this._labelAcronyms = `${acronyms}`;
        });
    }

    ngOnInit(): void {
        this._initSubject.next(true);
    }

    ngOnDestroy(): void {
        this._innerSubscriptions.forEach(sub => sub.unsubscribe());
    }

    //#region ControlValueAccessor

    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: MainSelectionValue): void {
        this._valueSubject.next(obj);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;

        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }

        this._cd.markForCheck();
    }

    //#endregion

    //#region validator

    validate(control: AbstractControl): ValidationErrors {
        return this.form.valid ? null : { innerFormInvalid: true };
    }

    registerOnValidatorChange?(fn: () => void): void {
    }

    //#endregion

    private validateForm(control: AbstractControl): ValidationErrors {
        const value = control.value as MainSelectionValue;
        const searchBy = value.searchBy;

        switch (searchBy) {
            case 'ExaminationTypes':
                return _.isEmpty(value.examinationTypes) ? { required: 'Must select one examination type at least' } : null;

            case 'Chemistry':
                return _.isEmpty(value.chemicalParameters) && _.isEmpty(value.chemicalParameterGroup) ? { required: 'Must select one chemical parameter at least' } : null;

            case 'Species':
                return _.isEmpty(value.species) ? { required: 'Must select one species at least' } : null;

            case 'SampleLocations':
                return _.isEmpty(value.locationMediaTypes) ? { required: 'Must select one location media type at least' } : null;

            default:
                return null;
        }
    }

    private updateChemistryOptionState(option: ChemistryOption) {
        this.chemistryOption = option;

        if (option === ChemistryOption.ChemicalParameters) {
            this.form.patchValue({ chemicalParameterGroup: null }, { onlySelf: true, emitEvent: false });
        } else {
            this.form.patchValue({ chemicalParameters: null }, { onlySelf: true, emitEvent: false });
        }
        this._cd.markForCheck();
    }

    public updateSearchBy(value: 'ExaminationTypes' | 'Chemistry' | 'Species' | 'SampleLocations', chemistryOption?: ChemistryOption): void {
        if (this.searchBy === value && !chemistryOption) { return; }
        if (value === 'Chemistry' && chemistryOption) {
            this.updateChemistryOptionState(chemistryOption);
        }
        this.searchBy = value;
        this.form.patchValue({ searchBy: value });
        this._cd.markForCheck();
    }

    public selectTab(tabName: 'ExaminationTypes' | 'Chemistry' | 'Species' | 'SampleLocations') {
        this.updateSearchBy(tabName);
    }

    public getSelectionTitle() {
        let resourceKey = 'main_selection.select_parameter';
        if (this.searchBy === 'Species') {
            resourceKey = 'main_selection.select_species';
        }
        if (this.searchBy === 'ExaminationTypes') {
            resourceKey = 'main_selection.select_examinations';
        }
        if (this.searchBy === 'SampleLocations') {
            resourceKey = 'main_selection.select_locations';
        }

        return this._translateService.get(resourceKey);
    }

    public select2ChemistryFormatItemState(state: S2Selection<any>) {
        if (state.object) {
            const oldSubstances = state.object.oldParameters ?? [];
            const acronyms = state.object.acronyms;
            const primary = `<div>${state.object.displayName}</div>`;
            const secondary = oldSubstances.map(x => `<div><small><i class="fas fa-info-circle text-info osb-icon"></i><span class="mx-1">${this._labelReplaces}:</span><span class="font-italic">${x.displayName}</span></small></div>`).join("");
            const acronymsDiv = acronyms?.length ? `<div><small><i class="fas fa-info-circle text-info osb-icon"></i><span class="mx-1">${this._labelAcronyms}:</span><span class="font-italic">${acronyms.join(", ")}</span></small></div>` : "";

            return $(`<div class="substance-chemistry">${primary}${secondary}${acronymsDiv}</div>`);
        }

        return state.text;
    };

    public select2ChemistrySearchMatcherFn = (params, data) => {
        // params: which contains the search term
        // data: which is an item. The matcher happening for each item in the source and checking
        // the term against each item (data)
        const trimmedTerm = params.term?.trim();

        if (_.isEmpty(trimmedTerm)) {
            return data;
        }

        // Do not display the item if there is no 'text' property
        if (_.isEmpty(data.text)) {
            return null;
        }

        // Check matching by item.text
        if (data.text.toUpperCase().includes(trimmedTerm.toUpperCase())) {
            return data;
        }

        // Check matching by item.oldParameters
        if (data.object?.oldParameters?.length) {
            const oldParametersName = data.object.oldParameters.map(x => x.displayName).join(" ");

            if (oldParametersName.toUpperCase().includes(trimmedTerm.toUpperCase())) {
                return data;
            }
        }

        // Check matching by item.acronyms
        if (data.object?.acronyms?.length) {
            const acronyms = data.object.acronyms.join(" ");

            if (acronyms.toUpperCase().includes(trimmedTerm.toUpperCase())) {
                return data;
            }
        }

        // Return `null` if the term should not be displayed
        return null;
    }

    public onSpeciesSelectionChangeFn() {
        if (!(this._speciesElRef as any)?.isOpen) {
            this.form.patchValue({ species: this.speciesForm.value.speciesSelection });
        }
    }
    public onSpeciesSelectionClosedFn() {
        this.form.patchValue({ species: this.speciesForm.value.speciesSelection });
    }
}
