import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
    // add timestamp into query string to prevent browser caching HTTP304
    return new TranslateHttpLoader(http, '../assets/i18n/', `.json?_=${(new Date()).getTime()}`);
}

const translateOptions = {
    loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
    }
};

@NgModule({
    imports: [
        TranslateModule.forRoot(translateOptions)
    ],
    exports: [TranslateModule],
    providers: [TranslateService]
})
export class AppTranslateModule { }
