import regression from 'regression';

export class GeomertyUtils {
    public static isPolygonIncludingCrossingLines(coordinates: (number[])[]): boolean {
        const lineSegments = [];
        coordinates.reduce((prev, curr) => {
            lineSegments.push([prev, curr]);
            return curr;
        });
        const count = lineSegments.length;

        for (let i = 0; i < count - 2; i++) {
            const currentLineSegment = lineSegments[i];

            for (let j = i + 2; j < count; j++) {
                if (i === 0 && j === count - 1) {
                    continue;
                }

                const nextLineSegment = lineSegments[j];

                if (GeomertyUtils.intersect(currentLineSegment[0], currentLineSegment[1], nextLineSegment[0], nextLineSegment[1])) {
                    return true;
                }
            }
        }

        return false;
    }

    public static areAllPolygonPointsInTheSameLine(coordinates: (number[])[], tolerance: number): boolean {
        const points = coordinates.slice(0, -1);
        const linearRegressionResult = regression.linear(points, { precision: 9 });
        const a = linearRegressionResult.equation[0];
        const b = -1;
        const c = linearRegressionResult.equation[1];
        const denominator = Math.sqrt(Math.pow(a, 2) + 1);
        const distances = points.map(p => {
            return Math.abs(a * p[0] + b * p[1] + c) / denominator;
        });

        if (!distances.some(x => x >= tolerance)) {
            return true;
        }

        return false;
    }


    // Given three colinear points p, q, r, the function checks if point q lies on line segment 'pr'
    private static onSegment(p: number[], q: number[], r: number[]): boolean {
        if (q[0] <= Math.max(p[0], r[0]) && q[0] >= Math.min(p[0], r[0]) &&
            q[1] <= Math.max(p[1], r[1]) && q[1] >= Math.min(p[1], r[1])) {
            return true;
        }

        return false;
    }

    // To find orientation of ordered triplet (p, q, r).
    // The function returns following values
    // 0 --> p, q and r are colinear
    // 1 --> Clockwise
    // 2 --> Counterclockwise
    private static orientation(p: number[], q: number[], r: number[]): number {
        // See https://www.geeksforgeeks.org/orientation-3-ordered-points/ for details of below formula.

        const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);

        if (val === 0) { return 0; }  // colinear

        return (val > 0) ? 1 : 2; // clock or counterclock wise
    }

    // The main function that returns true if line segment 'p1q1'
    // and 'p2q2' intersect.
    private static intersect(p1: number[], q1: number[], p2: number[], q2: number[]): boolean {
        // Find the four orientations needed for general and
        // special cases
        const o1 = GeomertyUtils.orientation(p1, q1, p2);
        const o2 = GeomertyUtils.orientation(p1, q1, q2);
        const o3 = GeomertyUtils.orientation(p2, q2, p1);
        const o4 = GeomertyUtils.orientation(p2, q2, q1);

        // General case
        if (o1 !== o2 && o3 !== o4) {
            return true;
        }

        // Special Cases
        // p1, q1 and p2 are colinear and p2 lies on segment p1q1
        if (o1 === 0 && GeomertyUtils.onSegment(p1, p2, q1)) { return true; }

        // p1, q1 and q2 are colinear and q2 lies on segment p1q1
        if (o2 === 0 && GeomertyUtils.onSegment(p1, q2, q1)) { return true; }

        // p2, q2 and p1 are colinear and p1 lies on segment p2q2
        if (o3 === 0 && GeomertyUtils.onSegment(p2, p1, q2)) { return true; }

        // p2, q2 and q1 are colinear and q1 lies on segment p2q2
        if (o4 === 0 && GeomertyUtils.onSegment(p2, q1, q2)) { return true; }

        return false; // Doesn't fall in any of the above cases
    }
}
