<simple-notifications [options]="options"></simple-notifications>

<ng-template #httpErrorTemplate let-data="data">
    <div class="http-error-toast">
        <div class="title-part">
            <div class="left-part">
                <i class="far fa-exclamation-triangle fa-2x"></i>
            </div>

            <div class="right-part">
                <span class="error-title">{{'errors.http.title' | translate}}</span>

                <div class="buttons-container">
                    <button class="btn btn-transparent text-white" (click)="data.toggleDetailFn(data)">
                        <i class="far fa-fl" [class.fa-chevron-up]="!data.detailOpen" [class.fa-chevron-down]="data.detailOpen"></i>
                        <span class="ml-2 d-none d-md-inline">{{'errors.http.show_more_message' | translate}}</span>
                    </button>

                    <div class="close-btn-container">
                        <button class="btn p-0" (click)="data.closeFn()">
                            <i class="far fa-times close-btn text-white fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail-part">
            <div class="left-part d-none d-md-block"></div>

            <div class="right-part">
                <div class="detail-content collapse multi-collapse" [attr.id]="data.detailContainerElementId">
                    <div class="title-row">
                        <strong class="http-code">{{data.statusCode}} {{data.statusText}}</strong>

                        <button class="btn text-white" (click)="data.copyFn(data)">
                            <i class="fa-solid fa-clone copy-btn-icon"></i>
                            <span class="ml-2 d-none d-md-inline">{{'errors.http.copy_text' | translate}}</span>
                        </button>
                    </div>

                    <div class="row mb-1">
                        <span class="d-none d-md-block font-weight-bolder col-md-3 col-lg-2">{{'errors.http.timestamp' | translate}}:
                        </span>
                        <span class="d-none d-md-block col-md">{{data.dateTime | date: 'dd-MM-yyyy HH:mm'}}</span>

                        <div class="d-block d-md-none col">
                            <span class="font-weight-bolder">{{'errors.http.timestamp' | translate}}: </span>
                            <span>{{data.dateTime | date: 'dd-MM-yyyy HH:mm'}}</span>
                        </div>
                    </div>

                    <div class="row mb-1">
                        <span class="d-none d-md-block font-weight-bolder col-md-3 col-lg-2">{{'errors.http.url' | translate}}:
                        </span>
                        <span class="d-none d-md-block col-md">{{data.url}}</span>

                        <div class="d-block d-md-none col">
                            <span class="font-weight-bolder">{{'errors.http.url' | translate}}: </span>
                            <span>{{data.url}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <span class="d-none d-md-block font-weight-bolder col-md-3 col-lg-2">{{'errors.http.message' | translate}}:
                        </span>
                        <span class="d-none d-md-block col-md">{{data.message}}</span>

                        <div class="d-block d-md-none col">
                            <span class="font-weight-bolder">{{'errors.http.message' | translate}}: </span>
                            <span>{{data.message}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #inputErrorTemplate let-data="data">
    <div class="general-error">
        <i class="far fa-exclamation-triangle fa-2x mr-3"></i>

        <div *ngIf="data.useTranslation" class="error-display">
            <div *ngIf="data.title" class="title">{{data.title | translate}}: </div>
            <div class="message">{{data.message | translate}}</div>
        </div>

        <div *ngIf="!data.useTranslation" class="error-display">
            <div *ngIf="data.title" class="title">{{data.title}}: </div>
            <div class="message">{{data.message}}</div>
        </div>
    </div>
</ng-template>