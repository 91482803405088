import {
    Component, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef, NgZone,
    OnDestroy, ChangeDetectorRef, Renderer2, TemplateRef, HostBinding
} from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { map, tap, debounceTime, delayWhen, finalize, switchMap } from 'rxjs/operators';
import { Observable, forkJoin, Subject, Subscription, combineLatest, interval, of } from 'rxjs';

import proj4 from 'proj4';
import { projections } from '@dmp/lagvaelger-client-api';

import * as olProj4 from 'ol/proj/proj4';
import * as proj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import Map from 'ol/Map';
import * as Extent from 'ol/extent';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Draw, { createBox } from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import DragPan from 'ol/interaction/DragPan';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import GeoJSON from 'ol/format/GeoJSON';
import * as Condition from 'ol/events/condition';
import { fromExtent } from 'ol/geom/Polygon';
import Projection from 'ol/proj/Projection';
import { getArea } from 'ol/sphere';
import Geometry from 'ol/geom/Geometry';
import {
    LineString,
    MultiLineString,
    MultiPoint,
    MultiPolygon,
    Point,
    Polygon,
    LinearRing,
    SimpleGeometry,
    GeometryCollection
} from 'ol/geom';

import { Api } from '@dmp/lagvaelger-client-api';
import { createApp } from 'vue';
import { LayerControl, Attribution, LayerToggle } from '@dmp/lagvaelger-client-ui';
import { Coordinate } from 'ol/coordinate';

import LayerGroup from "ol/layer/Group.js";

import { ResizeObserver } from '@juggle/resize-observer';
import * as _ from 'lodash';

import { AppConfig } from 'src/app/configurations/app-config';
import { MapMarker } from './map.models';
import { MapInteractionService } from 'src/app/services/communication/map-interaction.service';
import { PolygonService } from 'src/app/services/api/polygon.service';
import { GeographicalRequest } from 'src/app/models/geographical-request.model';
import { AreaType } from '../../value-accessor/geographical-selection/dataud-geographical-selection.component.model';
import { GeographicalService } from 'src/app/services/api/geographical.service';
import { Polygon as PolygonModel } from 'src/app/models/polygon.model';
import { GeomertyUtils } from 'src/app/utils/geometry-utils';
import { AppSettingsService } from 'src/app/services/infrastructures/app-settings.service';
import { DatafordelerWfsService } from 'src/app/services/datafordeler/datafordeler-wfs.service';

declare const $: any;
declare const jsts: any;

interface OverlayLayerSelection {
    name: string;
    label: string;
    layer: TileLayer<any>;
    selected: boolean;
    searchable: boolean;
}

@Component({
    selector: 'dataud-map',
    templateUrl: './dataud-map.component.html',
    styleUrls: ['./dataud-map.component.scss'],
    providers: [DecimalPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnDestroy {
    constructor(
        private _ngZone: NgZone,
        private _cd: ChangeDetectorRef,
        private _renderer: Renderer2,
        private _mapInteractionService: MapInteractionService,
        private _polygonService: PolygonService,
        private _geographicalService: GeographicalService,
        private _decimalPipe: DecimalPipe,
        private _appSettingsService: AppSettingsService,
        private _wfsLakeService: DatafordelerWfsService,
    ) {
        this._language = localStorage.getItem(AppConfig.webStorage.localStorage.currentLanguage);
        this._initSubject = new Subject<boolean>();

        // setup parser for geometry calculation
        this._parser = new jsts.io.OL3Parser();
        this._parser.inject(
            Point,
            LineString,
            LinearRing,
            Polygon,
            MultiPoint,
            MultiLineString,
            MultiPolygon,
            GeometryCollection
        );

        this.setupCoordinateSystems();
        this.setupMapStyles();
        this.setupMapResizeHandler();
        this.setupMapInteractionHandlers();
    }

    private _language: string;
    private _mapConfig = AppConfig.map;

    @ViewChild('map', { static: true }) private _mapElRef: ElementRef;

    private _projection: Projection;
    private _dataLayerSource: VectorSource;
    private _dataLayer: VectorLayer<any>;
    private _mapView: View;
    private _map: Map;

    private _initSubject: Subject<boolean>;

    // Resize handler
    private _mapElResizeObserver: ResizeObserver;
    private _mapElResizeSubject: Subject<any>;
    private _mapElResizeSubscription: Subscription;
    private _mapElResizing: boolean;

    // Markers
    private _markers: MapMarker[] = [];
    private _markerStyle: Style;
    private _selectedMarkerStyle: Style;
    private _highlightedMarkerStyle: Style;
    private _selectedMarkerIds: string[] = [];
    private _highlightedMarkerIds: string[] = [];

    // Drawing
    private _inDrawingMode: boolean;
    public isSavingPolygon = false;
    private _drawSource: VectorSource;
    private _drawLayer: VectorLayer<any>;
    private _drawInteraction: Draw;
    private _validPolygonStyle: Style;
    private _featureChangeSubject: Subject<any>;

    // Applied polygon layer
    private _geoJSON = new GeoJSON();
    private _polygonSource: VectorSource;
    private _polygonLayer: VectorLayer<any>;
    private _infoPolygonStyle: Style;
    private _boundingBoxStyle: Style;
    private _polygonFeatures: Feature[];
    private _parser: any;

    // Popup
    private _overlay: Overlay;
    private _selectMarkerInteraction: Select;
    private _markerHoverHandler: (e: any) => void;
    public popupTemplate: TemplateRef<any>;
    @ViewChild('popupContainer', { static: true }) private _popupContainer: ElementRef;

    // Map View search
    public inSearchMode: boolean;
    public canSearchByMapView = false;
    public isDisabledSearchByMapArea = false;

    // Zoom extend
    private _isOnZoomExtend = false;
    private _fitExtent: Extent.Extent;

    // Zoom in/out
    private _zoomInOutSubject: Subject<number>;

    // Warnings
    public exceedMaxStationWarningShown = false;

    // Overlay layers
    private _mapPointSelectable: boolean;
    private _selectedLayerSearchable: boolean;

    // LV/DB
    private _lvDbMapLayer: LayerGroup;
    private _lvDbApi: any;
    private _baseToggleDatasetIds: string[];
    private layerControlCollapsed = true; // Default in a new session whould be "closed" and not expanded
    private listenerLayerControlToggle: () => void;

    @HostBinding('class.cursor-pointer') private get _canSelectPoint(): boolean {
        return this._mapPointSelectable && this._selectedLayerSearchable;
    }

    // Inner subscriptions
    private _innerSubsciptions: Subscription[] = [];

    ngOnInit(): void {
        this.setupMapDataLayer();
        this.setupDrawingInfrastructure();
        this.setupView();
        this.setupOverlayInfrastructure();
        this.setupLvDbPluginMapLayer();
        this.setupMap();
    }

    ngOnDestroy(): void {
        this.releaseMapResizeHandler();
        this._innerSubsciptions.forEach(sub => sub.unsubscribe());
        this.listenerLayerControlToggle?.();
    }
    //#region map

    private setupCoordinateSystems(): void {
        proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
        olProj4.register(proj4);
    }

    private setupMapStyles(): void {
        // marker style
        this._markerStyle = new Style({
            image: new Icon({
                src: 'assets/map-markers/station.svg',
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction'
            }),
            zIndex: 1
        });
        this._selectedMarkerStyle = new Style({
            image: new Icon({
                src: 'assets/map-markers/station-selected.svg',
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction'
            }),
            zIndex: 2
        });
        this._highlightedMarkerStyle = new Style({
            image: new Icon({
                src: 'assets/map-markers/station-selected.svg',
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction'
            }),
            zIndex: 3
        });

        // polygon styles
        this._validPolygonStyle = new Style({
            stroke: new Stroke({
                color: 'rgba(69, 159, 72, 1)',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgba(69, 159, 72, 0.3)',
            }),
        });
        this._infoPolygonStyle = new Style({
            stroke: new Stroke({
                color: 'rgba(249, 148, 3, 1)',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgba(249, 148, 3, 0.3)',
            }),
        });
        this._boundingBoxStyle = new Style({
            stroke: new Stroke({
                color: 'rgba(69, 159, 72, 0)'
            }),
            fill: new Fill({
                color: 'rgba(69, 159, 72, 0)'
            }),
        });
    }

    private setupMapDataLayer(): void {
        this._dataLayerSource = new VectorSource({});
        this._dataLayer = new VectorLayer({ source: this._dataLayerSource });
    }

    private setupView(): void {
        const mapConfig = this._mapConfig;
        const { resolutions, projection } = projections[25832];
        projection.setExtent(mapConfig.viewExtent);
        this._projection = projection;

        this._mapView = new View({
            zoom: mapConfig.defaultZoomLevel,
            resolutions,
            projection,
            minZoom: mapConfig.minZoomLevel,
            maxZoom: mapConfig.maxZoomLevel,
            center: Extent.getCenter(mapConfig.viewExtent),
            extent: mapConfig.viewExtent,
            enableRotation: false
        });

        this._mapView.on('change:center', this.onMapViewChange);
        this._mapView.on('change:resolution', this.onMapViewChange);
    }

    private onMapViewChange = () => {
        if (this._isOnZoomExtend) { return; }

        this._fitExtent = null;
        this.enableSearchByMapView();
    }

    private setupMap(): void {
        this._map = new Map({
            target: this._mapElRef.nativeElement,
            layers: [].concat(this._lvDbMapLayer, [this._polygonLayer, this._dataLayer, this._drawLayer]),
            overlays: [this._overlay],
            view: this._mapView,
            maxTilesLoading: 256
        });
        this._map.once('rendercomplete', () => {
            this.attachOverlayInteractions();
            this._initSubject.next(true);
            this.attachPointSelectionHandler();
            this.handleToggleLayerControl();
        });

        this.attachMapResizeHandler();
    }

    private handleToggleLayerControl() {
        // The expand state should also be remebered. Similar to the state of the datasets/layers
        let layerControlToggle = document.querySelector(".dataud-map .lv-c-lagvaelger-toggle__chevron");
        if(layerControlToggle){
            this.listenerLayerControlToggle = this._renderer.listen(layerControlToggle, 'click', (event) => {
                this.layerControlCollapsed = !this.layerControlCollapsed;
                localStorage.setItem(AppConfig.webStorage.localStorage.layerControlCollapsed, this.layerControlCollapsed.toString());
            });
        }
    }


    private recheckFitExtent = () => {
        if (!_.isNil(this._fitExtent)) {
            this.fitMapViewByExtent(this._fitExtent);
        }

        this._map.un('postrender', this.recheckFitExtent);
    }

    private setupMapResizeHandler(): void {
        this._mapElResizeSubject = new Subject<any>();
        this._mapElResizeSubscription = this._mapElResizeSubject
            .pipe(tap(() => this._mapElResizing = true), debounceTime(350))
            .subscribe({
                next: () => {
                    this._ngZone.runOutsideAngular(() => {
                        this._map.updateSize();
                        this._map.on('postrender', this.recheckFitExtent);
                    });
                    this._mapElResizing = false;
                    this.enableSearchByMapView();
                }
            });
        this._mapElResizeObserver = new ResizeObserver(() => {
            this._mapElResizeSubject.next(null);
        });
    }

    private attachMapResizeHandler(): void {
        this._mapElResizeObserver.observe(this._mapElRef.nativeElement);
    }

    private releaseMapResizeHandler(): void {
        this._mapElResizeObserver.disconnect();
        this._mapElResizeSubscription.unsubscribe();
    }

    private setupMapInteractionHandlers(): void {
        // draw markers
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.markers$.pipe(
                    tap(markers => this._markers = markers ?? [])
                ),
                this._initSubject
            ]).subscribe({
                next: () => this.drawMarkers()
            })
        );

        // zoom extend
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.zoomExtendRequest$.pipe(tap(() => this._fitExtent = null)),
                this._initSubject
            ]).pipe(
                delayWhen(() => interval(this._mapElResizing ? 400 : 0))
            ).subscribe({
                next: ([raiseMapViewChange]) => this.zoomExtend(raiseMapViewChange)
            })
        );

        // drawing
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.drawingState$.pipe(tap(state => this._inDrawingMode = !_.isNil(state))),
                this._initSubject,
            ]).subscribe({
                next: ([state]) => {
                    this._map?.removeInteraction(this._drawInteraction);
                    this._drawSource?.clear();

                    const inDrawingMode = !_.isNil(state);

                    if (inDrawingMode) {
                        this._polygonLayer.setStyle(this._infoPolygonStyle);
                        this._drawInteraction = this.createDrawInteraction(state);
                        this._map.addInteraction(this._drawInteraction);
                        this.detachOverlayInteractions();
                        this.closePopup();
                    } else {
                        this._polygonLayer.setStyle(this._validPolygonStyle);
                        this.attachOverlayInteractions();
                    }

                    this._cd.markForCheck();
                }
            })
        );

        // showing region, komune, polygon
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.showGeographicRequest$
                    .pipe(tap(() => this._mapInteractionService.setMapSelectionArea(null))),
                this._initSubject,
            ]).pipe(
                tap(() => this._polygonSource.clear(true)),
                switchMap(([request]) => this.toFeaturesAsync(request))
            ).subscribe({
                next: features => {
                    this._polygonFeatures = features;

                    if (_.isEmpty(features)) {
                        // re-enable search by map view if there no polygon apply when in search mode
                        if (this.inSearchMode) {
                            this.enableSearchByMapView();
                        }

                        return;
                    }

                    this._mapInteractionService.setMapSelectionArea(this.getAreaDisplay(features));
                    this._polygonSource.addFeatures(features);
                    const requestType = features[0].get('requestType');

                    if (requestType !== AreaType.MapView) {
                        this.zoomExtend();
                        this.enableSearchByMapView();
                    }
                }
            })
        );

        // popup template
        this._innerSubsciptions.push(
            this._mapInteractionService.markerPopup$
                .subscribe({
                    next: template => {
                        this.popupTemplate = template;
                        this._cd.markForCheck();
                    }
                })
        );

        // close popup
        this._innerSubsciptions.push(
            this._mapInteractionService.closePopup$.subscribe({
                next: _ => {
                    this.closePopup();
                }
            })
        )

        // select markers
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.selectMarkerIds$.pipe(tap(ids => this._selectedMarkerIds = ids ?? [])),
                this._initSubject,
            ]).subscribe({ next: this.updateMarkersStyle.bind(this) })
        );

        // open popup
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.openSelectedMarkerPopup$,
                this._initSubject,
            ]).subscribe({ next: ([{markerId, markerData}]) => this.openSelectedMarkerPopup(markerId, markerData) })
        );

        // highlight markers
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService._highlightedMarkerIds$.pipe(tap(ids => this._highlightedMarkerIds = ids ?? [])),
                this._initSubject,
            ]).subscribe({ next: this.updateMarkersStyle.bind(this) })
        );

        // set center
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.center$,
                this._initSubject,
            ]).subscribe({
                next: ([center]) => {
                    this._mapView.setCenter(center);
                }
            })
        );

        // map view search
        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.mapSearchState$,
                this._initSubject
            ]).pipe(map(([state], index) => {
                return { state, index };
            })).subscribe({
                next: ({ state, index }) => {
                    // Check map view polygon inthe first time to disble search
                    if (index === 0) {
                        if (this._polygonFeatures?.[0]?.get('requestType') === AreaType.MapView) {
                            this.canSearchByMapView = false;
                        }
                    }

                    this.inSearchMode = state;
                    this._cd.markForCheck();
                }
            })
        );

        // showing exceed max station count
        this._innerSubsciptions.push(
            this._mapInteractionService.exceedMaxStationWarningState$
                .subscribe({
                    next: state => {
                        this.exceedMaxStationWarningShown = state;
                        this._cd.markForCheck();
                    }
                })
        );

        // zoom in/out
        this._zoomInOutSubject = new Subject<number>();
        this._innerSubsciptions.push(
            combineLatest([
                this._initSubject,
                this._zoomInOutSubject
            ]).subscribe({
                next: ([, delta]) => {
                    const zoomLevel = this._map.getView().getZoom() + delta;
                    this._mapView.cancelAnimations();
                    this._mapView.animate({
                        zoom: zoomLevel,
                        duration: this._mapConfig.animationDuration
                    });
                }
            })
        );

        // map search point
        this._innerSubsciptions.push(
            this._mapInteractionService.mapPointSelectable$
                .subscribe({
                    next: value => {
                        this._mapPointSelectable = value;
                        this._cd.markForCheck();
                    }
                })
        );

        this._innerSubsciptions.push(
            combineLatest([
                this._mapInteractionService.mapPointSelectable$,
                this._mapInteractionService.searchByMapPointAbility$,
                this._initSubject
            ]).subscribe({
                next: ([selectable, ability]) => {
                    if (this._inDrawingMode) { return; }

                    const style = (selectable && ability) ? this._infoPolygonStyle : this._validPolygonStyle;
                    this._polygonLayer.setStyle(style);
                }
            })
        );

        // Toggle search in area button
        this._innerSubsciptions.push(
            this._mapInteractionService.toggleSearchByAreaButton$.subscribe({
                next: value => {
                    this.isDisabledSearchByMapArea = !value;
                    this._cd.markForCheck();
                }
            })
        );
    }

    private setupLvDbPluginMapLayer() {
        const appSettings = this._appSettingsService.appSettings;
        const currentLocale = localStorage.getItem(AppConfig.webStorage.localStorage.currentLanguage) === "da" ? "da-DK" : "en-US";

        // Read map dataset ids from settings
        const lakesDatasetId = appSettings?.datasetLakes;
        const streamsDatasetId = appSettings?.datasetStreams;
        this._baseToggleDatasetIds = [ appSettings.datasetMap, appSettings.datasetOrthophoto ];

        const overlays = [ streamsDatasetId, lakesDatasetId ];
        const datasetState = overlays.map((id, _index) => ({ id, visible: true, opacity: 1 }))
            .concat(this._baseToggleDatasetIds.map((id, index) => ({ id, visible: index === 0, opacity: 1 })));

        // Init and load API with predefined state list of datasets
        this._lvDbApi = new Api({
            onlyRenderable: true,
            datasetState: datasetState,
            endpoint: appSettings?.datacatalogueUrl
        });
        this._lvDbMapLayer = this._lvDbApi.getOlGroup();

        this._lvDbApi.on("change:visible", ($event: { id: string; visible: boolean }) => {
            if ($event?.id === lakesDatasetId) {
                this._selectedLayerSearchable = $event.visible;
                this.updateSearchByMapPointAbility();
            }
        });

        // The load() function is reading from localStorage first, so use .load(datasetState) to force loading
        // from state or you can clear the localStorage manually
        this._lvDbApi.setLocale(currentLocale).then(() => {
            this._lvDbApi.load().then(() => {
                // Set WFS settings for lake selection
                const lakeDataset = this._lvDbApi.activeDatasets.getArray().find(x => x.id === lakesDatasetId);
                this._wfsLakeService.setLakeDataset(lakeDataset);
            });
        });

        // Mount Attribution componnent
        const layersAttribution = document.getElementById("layerAttribution");
        createApp(Attribution , {
            api: this._lvDbApi,
        }).mount(layersAttribution);

        // Mount LV component with neccessary props
        const layerSelector = document.getElementById("layerSelector");
        if(localStorage.getItem(AppConfig.webStorage.localStorage.layerControlCollapsed)){
            this.layerControlCollapsed = localStorage.getItem(AppConfig.webStorage.localStorage.layerControlCollapsed) === "true";
        }
        createApp(LayerControl, {
            api: this._lvDbApi,
            hiddenDatasets: this._baseToggleDatasetIds,
            collapsed: this.layerControlCollapsed,
        }).mount(layerSelector);


        // Mount LayerToggle with neccessary props
        const baseLayerToggler = document.getElementById("baseLayerToggler");
        createApp(LayerToggle, {
            api: this._lvDbApi,
            datasets: this._baseToggleDatasetIds
        }).mount(baseLayerToggler);
    }
    //#endregion

    //#region Markers
    private drawMarkers(): void {
        this._ngZone.runOutsideAngular(() => {
            this.closePopup();
            this._dataLayerSource.clear(true);
            const markerFeatures: Feature<Geometry>[] = this._markers.map(this.convertMapMarkerToFeature.bind(this));
            this._dataLayerSource.addFeatures(markerFeatures);
        });
    }

    private convertMapMarkerToFeature(marker: MapMarker): Feature {
        const feature = new Feature({
            geometry: new Point(marker.coordinates),
            data: marker.data,
            id: marker.id
        });
        const style = this.getMarkerStyleById(marker.id);
        feature.setStyle(style);

        return feature;
    }

    private updateMarkersStyle(): void {
        this._ngZone.runOutsideAngular(() => {
            const features = this._dataLayerSource.getFeatures();
            features.forEach(feature => {
                const id = feature.getProperties().id;
                const style = this.getMarkerStyleById(id);
                feature.setStyle(style);
            });
        });
    }

    private openSelectedMarkerPopup(markerId, markerData): void {
        this._ngZone.runOutsideAngular(() => {
            if(!markerId){
                this.closePopup();
            }
            else{
                this._mapInteractionService.emitMarkerClick(markerData);
                this._mapInteractionService.selectMarkers(markerId);
                const features = this._dataLayerSource.getFeatures();
                let selectedFeature = features.find(x => x.getProperties().id == markerId);
                this._overlay.setPosition((selectedFeature.getGeometry() as Point).getCoordinates());
            }
        });
    }

    private getMarkerStyleById(id: string): Style {
        return this._highlightedMarkerIds.includes(id)
            ? this._highlightedMarkerStyle
            : (this._selectedMarkerIds.includes(id) ? this._selectedMarkerStyle : this._markerStyle);
    }

    //#endregion

    //#region Zoom extend

    private zoomExtend(raiseMapViewChange = false): void {
        this._ngZone.runOutsideAngular(() => {
            let featureArea = 0;
            let zoomPadding = [20, 20, 20, 20];

            // won't zoom to featire if its area = 0
            if (!_.isEmpty(this._polygonFeatures)) {
                featureArea = this.getArea(this._polygonFeatures);

                if (this._polygonFeatures[0].get('requestType') === AreaType.MapView) {
                    zoomPadding = [0, 0, 0, 0];
                }
            }

            if (_.isEmpty(this._markers) && featureArea === 0) { return; }

            let extent = Extent.createEmpty();

            if (featureArea > 0) {
                // if there is any features on map, we should not care about marker because they must be inside features
                this._polygonFeatures.forEach(f => {
                    extent = Extent.extend(extent, f.getGeometry().getExtent());
                });
            } else {
                this._markers.forEach(marker => {
                    extent = Extent.extend(extent, new Point(marker.coordinates).getExtent());
                });
            }

            this._fitExtent = extent;
            this.fitMapViewByExtent(extent, this._mapConfig.animationDuration, zoomPadding, raiseMapViewChange);
        });
    }

    private fitMapViewByExtent(extent: Extent.Extent, animationDuration = 0, padding = [20, 20, 20, 20], raiseMapViewChange = false): void {
        this._isOnZoomExtend = true;
        this._mapView.cancelAnimations();
        this._mapView.fit(extent, {
            duration: animationDuration,
            padding,
            maxZoom: this._mapConfig.maxZoomLevel,
            callback: () => {
                this._isOnZoomExtend = false;

                if (raiseMapViewChange) {
                    this.enableSearchByMapView();
                }
            }
        });
    }

    //#endregion

    //#region Popup

    private setupOverlayInfrastructure(): void {
        this._overlay = new Overlay({
            element: this._popupContainer.nativeElement,
            autoPan: {
                animation: {
                    duration: 250
                }
            },
            stopEvent: false
        });

        // Marker click
        this._selectMarkerInteraction = new Select({
            layers: [this._dataLayer],
            condition: Condition.singleClick,
            style: null
        });

        this._selectMarkerInteraction.on('select', e => {
            if (this.isPopUpHovering()) { return; }

            const feature = e.selected[0];

            if (_.isNil(feature)) {
                this.closePopup();
                return;
            }

            const markerData = feature.getProperties().data;
            const markerId = feature.getProperties().id;

            if (_.isNil(markerData)) {
                this.closePopup();
                return;
            }

            this._mapInteractionService.emitMarkerClick(markerData);
            this._mapInteractionService.selectMarkers(markerId ? [markerId] : []);
            this._overlay.setPosition((feature.getGeometry() as Point).getCoordinates());
        });

        // Marker hover
        this._markerHoverHandler = e => {
            // disable some map interaction while hovering on overlay to support scrolling inside overlay, selecting text
            const stoppedInteractions = this._map.getInteractions().getArray()
                .filter(x => x instanceof MouseWheelZoom || x instanceof DragPan || x instanceof DoubleClickZoom);

            if (this.isPopUpHovering()) {
                stoppedInteractions.forEach(i => i.setActive(false));
                return;
            }
            stoppedInteractions.forEach(i => i.setActive(true));

            const features = this._map.getFeaturesAtPixel(e.pixel, { layerFilter: layer => layer === this._dataLayer });

            if (_.isEmpty(features)) {
                this._renderer.removeClass(this._mapElRef.nativeElement, 'cursor-pointer');
                return;
            }

            this._renderer.addClass(this._mapElRef.nativeElement, 'cursor-pointer');
        };
    }

    private attachOverlayInteractions(): void {
        if (!this._map.getInteractions().getArray().includes(this._selectMarkerInteraction)) {
            this._map.addInteraction(this._selectMarkerInteraction);
        }

        this._map.on('pointermove', this._markerHoverHandler);
    }

    private detachOverlayInteractions(): void {
        this._map.removeInteraction(this._selectMarkerInteraction);
        this._map.un('pointermove', this._markerHoverHandler);
    }

    private isPopUpHovering(): boolean {
        if (!this._popupContainer || !this._popupContainer.nativeElement) { return false; }

        return $(this._popupContainer.nativeElement).filter(':hover').length > 0;
    }

    private closePopup(): void {
        this._overlay.setPosition(null);
    }

    //#endregion

    //#region Drawing

    private setupDrawingInfrastructure(): void {
        // sources
        this._drawSource = new VectorSource();
        this._drawSource.on('addfeature', () => { this._featureChangeSubject.next(); });
        this._drawSource.on('removefeature', () => { this._featureChangeSubject.next(); });
        this._polygonSource = new VectorSource();

        // layers
        this._drawLayer = new VectorLayer({
            source: this._drawSource
        });
        this._polygonLayer = new VectorLayer({
            source: this._polygonSource,
            style: this._validPolygonStyle
        });

        this._featureChangeSubject = new Subject<any>();
        this._innerSubsciptions.push(
            this._featureChangeSubject.pipe(
                debounceTime(50)
            ).subscribe({
                next: () => {
                    if (_.isEmpty(this._drawSource.getFeatures())) { return; }

                    const feature = this._drawSource.getFeatures()[0];

                    if (this.isEmptyFeature(feature)) {
                        this._drawSource.clear(true);
                        return;
                    }

                    this._drawLayer.setStyle(this._validPolygonStyle);
                    this.applyPolygon();
                }
            })
        );
    }

    private createDrawInteraction(mode: 'Polygon' | 'Rectangle'): Draw {
        let interaction: Draw = null;

        switch (mode) {
            case 'Polygon':
                interaction = new Draw({
                    source: this._drawLayer.getSource(),
                    type: 'Polygon',
                    freehandCondition: Condition.never,
                    geometryFunction: (coordinates, optGeometry) => {
                        let geometry: SimpleGeometry = optGeometry;
                        const coord: Coordinate[] = coordinates[0] as Coordinate[];
                        if (geometry) {
                            if (coord?.length) {
                                geometry.setCoordinates([coord.concat([coord[0]])]);
                            }
                            else {
                                geometry.setCoordinates([]);
                            }
                        }
                        else {
                            geometry = new Polygon(coordinates as Coordinate[][]);
                        }

                        this.refineDrawingPolygon(geometry as any);
                        return geometry;
                    }
                });
                break;

            case 'Rectangle':
                interaction = new Draw({
                    source: this._drawLayer.getSource(),
                    type: 'Circle',
                    freehandCondition: Condition.never,
                    geometryFunction: createBox()
                });
        }

        interaction.on('drawstart', () => {
            this._drawLayer.getSource().clear();
            this._drawLayer.setStyle(null);
        });

        return interaction;
    }

    private refineDrawingPolygon(geometry: Polygon): void {
        const geoCoords = (geometry as Polygon).getCoordinates()[0];

        if (geoCoords.length > 3) {
            if (GeomertyUtils.areAllPolygonPointsInTheSameLine(geoCoords, AppConfig.polygon.minimumAcceptableDistance)
                || GeomertyUtils.isPolygonIncludingCrossingLines(geoCoords)) {
                geometry.setCoordinates([]);
            }
        }
    }

    private applyPolygon(): void {
        this.isSavingPolygon = true;
        this._cd.markForCheck();

        const features = [this._drawSource.getFeatures()[0]];

        this._polygonService.savePolygon({ geoJsonString: this._geoJSON.writeFeatures(features) }).pipe(
            finalize(() => {
                this.isSavingPolygon = false;
                this._cd.markForCheck();
            })
        ).subscribe({
            next: id => {
                this._mapInteractionService.requestSearchPolygon({
                    id,
                    features
                });
                this._mapInteractionService.endDrawing();
            }
        });
    }

    private isEmptyFeature(feature: Feature): boolean {
        const coordinates = (feature.getGeometry() as any).getCoordinates();
        return coordinates.length === 0;
    }

    //#endregion

    //#region Show geographical data

    private toFeaturesAsync(request: GeographicalRequest): Observable<Feature[]> {
        if (_.isNil(request)) { return of(null); }

        switch (request.type) {
            case AreaType.Polygon: {
                return of(request.features);
            }

            case AreaType.MapView: {
                const polygonGeometry = request.features[0].getGeometry();
                const feature = new Feature({
                    geometry: polygonGeometry,
                    requestType: request.type
                });
                feature.setStyle(this._boundingBoxStyle);
                return of([feature]);
            }

            case AreaType.Region:
                return this.toCombinedPolygon(request.ids, id => this._geographicalService.getRegion(id));

            case AreaType.Municipality:
                return this.toCombinedPolygon(request.ids, id => this._geographicalService.getMunicipality(id));

            default:
                return of(null);
        }
    }

    private toCombinedPolygon(ids: string[], polygonDataFactory: (id: string) => Observable<any>): Observable<Feature[]> {
        const tasks = ids.map(id => {
            return polygonDataFactory(id).pipe(map(data => {
                if (_.isEmpty(data)) { return null; }

                const geometry = new GeoJSON().readGeometry(data);
                const area = this.getArea([new Feature({ geometry })]);

                return area === 0 ? null : geometry;
            }));
        });

        return forkJoin(tasks).pipe(map(geometries => {
            const appliedGeometries = geometries.filter(x => !_.isNil(x));
            return appliedGeometries.map(geometry => new Feature({ geometry }));
        }));
    }

    private getArea(features: Feature[]): number {
        if (_.isEmpty(features)) { return 0; }

        const unionedJstsGeometry = features.map(f => {
            const jsonGeometry = new GeoJSON().writeGeometry(f.getGeometry());
            const jstsGeoJSONReader = new jsts.io.GeoJSONReader();
            const geometry = jstsGeoJSONReader.read(jsonGeometry);
            return geometry;
        })
            .reduceRight((a, b) => a.union(b));

        return getArea(this._parser.write(unionedJstsGeometry), { projection: this._projection });
    }

    private getAreaDisplay(features: Feature[]): string {
        const areaValue = this.getArea(features);

        if (areaValue === 0) { return null; }

        let area = '';
        if (areaValue > 10000) {
            const valueDisplay = this._decimalPipe.transform(Math.round((areaValue / 1000000) * 100) / 100, '1.0-9', this._language);
            area = `${valueDisplay} km²`;
        } else {
            const valueDisplay = this._decimalPipe.transform(Math.round(areaValue * 100) / 100, '1.0-9', this._language);
            area = `${valueDisplay} m²`;
        }

        return area;
    }

    //#endregion

    //#region Map view search

    public requestSearchByMapView(): void {
        const polygon = this.toPolygonModel(this.getMapViewPolygon());
        this._mapInteractionService.requestSearchByMapView(polygon);
        this.canSearchByMapView = false;
        this._cd.markForCheck();
    }

    private toPolygonModel(polygon: Polygon): PolygonModel {
        return { features: [new Feature({ geometry: polygon })] };
    }

    private getMapViewPolygon(): Polygon {
        const extent = this._mapView.calculateExtent();
        return fromExtent(extent);
    }

    private enableSearchByMapView(): void {
        this._ngZone.run(() => {
            this.canSearchByMapView = true;
            this._cd.markForCheck();
        });
    }

    //#endregion

    //#region Zoom in/out
    public zoom(delta: number): void {
        this._zoomInOutSubject.next(delta);
    }

    //#endregion

    //#region Overlay layers
    private updateSearchByMapPointAbility(): void {
        this._mapInteractionService.updateSearchByMapPointAbility(this._selectedLayerSearchable);
        this._cd.markForCheck();
    }

    private attachPointSelectionHandler(): void {
        this._map.on('click', e => {
            const features = this._map.getFeaturesAtPixel(e.pixel, {
                layerFilter: layer => layer === this._dataLayer
            });

            if (!_.isEmpty(features) || this.isPopUpHovering()) { return; }

            this._mapInteractionService.updateMapClickCoordinate(e.coordinate);
        });
    }
    //#endregion
}
