<ng-container [formGroup]="form">
    <div class="row form-group">
        <div class="col">
            <div class="mb-2">
                <span>{{'data_responsible_selection.filter_by' | translate}}</span>
            </div>

            <dataud-select class="form-group-reverse" formControlName="dataResponsibles" bindLabel="name" bindValue="cvr" [items]="dataResponsibleSource"
                [placeholder]="'data_responsible_selection.placeholder' | translate" [multiple]="true" [isSelectingOnClose]="true">
            </dataud-select>
        </div>
    </div>
</ng-container>