import { Component, ChangeDetectionStrategy, ViewChild, ViewContainerRef, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';

import * as _ from 'lodash';

import { MapInteractionService } from 'src/app/services/communication/map-interaction.service';
import { MobileViewInteractionService } from 'src/app/services/communication/mobile-view-interaction.service';

@Component({
    selector: 'dataud-mobile-view',
    templateUrl: './dataud-mobile-view.component.html',
    styleUrls: ['./dataud-mobile-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileViewComponent implements OnDestroy {
    public isOpen = false;
    private _innerSubscriptions: Subscription[];

    public mode: 'Filter' | 'Result' | null;

    @ViewChild('filters', { read: ViewContainerRef, static: true }) public filtersViewContainerRef: ViewContainerRef;
    @ViewChild('results', { read: ViewContainerRef, static: true }) public resultsViewContainerRef: ViewContainerRef;

    @Output() reset = new EventEmitter<any>();

    constructor(mapInteractionService: MapInteractionService,
                private _mobileViewInteractionService: MobileViewInteractionService,
                private _cd: ChangeDetectorRef) {

        this._innerSubscriptions = [
            mapInteractionService.drawingState$
                .subscribe({
                    next: state => {
                        if (!_.isNil(state)) {
                            this.close();
                        }
                    }
                }),

            mapInteractionService.mapPointSelectable$.subscribe({
                next: selectable => {
                    if (selectable) {
                        this.close();
                    }
                }
            }),

            this._mobileViewInteractionService.openTabRequest$
                .subscribe({ next: this.doOpen.bind(this) })
        ];
    }

    ngOnDestroy(): void {
        this._innerSubscriptions.forEach(s => s.unsubscribe());
    }

    public close(): void {
        this.isOpen = false;
        this.mode = null;
        this._cd.markForCheck();
    }

    public open(mode: 'Filter' | 'Result'): void {
        if (this.mode === mode) {
            this.close();
            return;
        }

        this.doOpen(mode);
    }

    private doOpen(mode: 'Filter' | 'Result'): void {
        this.isOpen = true;
        this.mode = mode;
        this._cd.markForCheck();
    }

    public onResetClicked(): void {
        this.reset.emit();
    }
}
