<div class="mobile-container" [class.d-none]="!isOpen">
    <div class="header-panel" [class.filter]="mode === 'Filter'">
        <span *ngIf="mode === 'Filter'" text-role="primary-header">
            {{'content_headers.search_environmental_data' | translate}}
            <button type="button" class="btn reset-btn dmp-link" (click)="onResetClicked()">
                <i class="far fa-undo"></i>
                {{'content_headers.reset' | translate}}
            </button>
        </span>

        <span *ngIf="mode === 'Result'" text-role="primary-header">
            {{'content_headers.results' | translate}}
        </span>

        <button class="btn p-0" (click)="close()">
            <i class="far fa-plus fa-2x fa-rotate-45"></i>
        </button>
    </div>

    <div class="content" [class.d-block]="mode === 'Filter'">
        <ng-container #filters></ng-container>
    </div>

    <div class="content" [class.d-flex]="mode === 'Result'">
        <ng-container #results></ng-container>
    </div>
</div>

<div class="d-flex justify-content-between align-items-stretch">
    <button class="btn tab-btn" (click)="open('Filter')" [class.filter-checked]="mode === 'Filter'">
        <i class="far fa-sliders-h"></i>
        <span class="ml-2">{{'content_headers.filters' | translate}}</span>
    </button>

    <div class="separator"></div>

    <button class="btn tab-btn" (click)="open('Result')" [class.result-checked]="mode === 'Result'">
        <i class="far fa-th-list"></i>
        <span class="ml-2">{{'content_headers.results' | translate}}</span>
    </button>
</div>