import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { forkJoin } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import * as _ from 'lodash';

import { AppSettingsService } from './app-settings.service';
import { AppConfig } from '../../configurations/app-config';
import { MetaDataService } from '../api/metadata.service';

/**
 * Service for app initialization
 * Using for loading server configuration, pre-selecting culture/language
 */
@Injectable()
export class AppInitiationService {
    private _language: string;

    constructor(protected httpClient: HttpClient,
                private _appSettingsService: AppSettingsService,
                private _translateService: TranslateService,
                private _metaDataService: MetaDataService) {
    }

    public loadSettings(): Promise<void> {
        this.presetLanguage();

        return new Promise((resolve, reject) => {
            forkJoin([
                this._appSettingsService.loadAppSetting$.pipe(concatMap(() => this._metaDataService.loadMetadata(this._language))),
                this._translateService.use(this._language),
            ]).subscribe({
                next: () => resolve(),
                error: error => reject(error)
            });
        });
    }

    private presetLanguage(): void {
        const languageLocalStorageKey = AppConfig.webStorage.localStorage.currentLanguage;
        const supportLanguages = AppConfig.language.supportLanguages;
        const storedLanguage = localStorage.getItem(languageLocalStorageKey);
        const selectedLanguage = supportLanguages.some(x => x === storedLanguage) ? storedLanguage : AppConfig.language.defaultLanguage;

        if (_.isNil(storedLanguage)) {
            localStorage.setItem(languageLocalStorageKey, selectedLanguage);
        }

        this._language = selectedLanguage;
    }
}
