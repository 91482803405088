import { Component, ChangeDetectorRef, ChangeDetectionStrategy, forwardRef, OnInit, OnDestroy, EventEmitter, Output, ErrorHandler, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormBuilder, NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { Subject, Subscription, combineLatest, BehaviorSubject, iif, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';

import * as _ from 'lodash';

import { containsCoordinate } from 'ol/extent';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { extend } from 'ol/extent';

import { PolygonService } from 'src/app/services/api/polygon.service';
import { GeographicalValue, AreaType } from './dataud-geographical-selection.component.model';
import { MapInteractionService } from 'src/app/services/communication/map-interaction.service';
import { ToasterService } from 'src/app/services/communication/toaster.service';
import { ToastType } from 'src/app/models/toast.models';
import { GeographicalRequest } from 'src/app/models/geographical-request.model';
import { Polygon as PolygonModel } from 'src/app/models/polygon.model';
import { ValueDisplayItem } from 'src/app/models/metadata.models';
import { MetaDataService } from 'src/app/services/api/metadata.service';
import { AppConfig } from 'src/app/configurations/app-config';
import { DatafordelerWfsService } from 'src/app/services/datafordeler/datafordeler-wfs.service';
import { CollapseContainerComponent } from '../../layout/collapse-container/dataud-collapse-container.component';

declare const $: any;

interface FormValue {
    mode: 'MapView' | 'Search' | 'Polygon';
    searchType: 'Place' | 'Municipality' | 'Region';
    place: string;
    municipality: string[];
    region: string[];
    mapView: PolygonModel;
    polygonType: 'Polygon' | 'MapPoint';
    polygon: PolygonModel;
}

@Component({
    selector: 'dataud-geographical-selection',
    templateUrl: './dataud-geographical-selection.component.html',
    styleUrls: ['./dataud-geographical-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeographicalSelectionComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GeographicalSelectionComponent),
            multi: true
        }
    ]
})
export class GeographicalSelectionComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    // form
    public mode: 'MapView' | 'Search' | 'Polygon' = 'MapView';
    public isDisabled: boolean;
    public form: UntypedFormGroup;
    private readonly _initialFormValue: FormValue = {
        mode: 'MapView',
        searchType: null,
        place: null,
        municipality: null,
        region: null,
        mapView: null,
        polygonType: 'Polygon',
        polygon: null
    };
    private readonly _defaultOutterGeographicalValue: GeographicalValue = { searchBy: '' };
    private readonly _SEARCH_PLACE_MIN_CHARACTERS = 1;

    // search
    public searchType: 'Place' | 'Municipality' | 'Region';
    public municipalitySource: ValueDisplayItem[];
    public regionSource: ValueDisplayItem[];

    // flow
    private _readySubject: Subject<boolean>;

    private _valueSubject: Subject<GeographicalValue>;
    private _outterValueSubject: BehaviorSubject<GeographicalValue>;
    private _outterValueSubscription: Subscription;
    private _innerSubscriptions: Subscription[];

    // drawing
    public drawAreaSelection: 'Polygon' | 'Rectangle' | 'MapPoint' = 'Polygon';

    // upload
    private readonly _maximumUploadedFileSize = 1048576; // 1MB
    private _geoJSON = new GeoJSON();
    @Output() beginUpload = new EventEmitter();
    @Output() endUpload = new EventEmitter();

    // search lake by map point
    public canSearchByMapPoint: boolean;
    private _mapSelectedPointCoordinateSubject: Subject<number[]>;

    // map
    public mapSelectionArea: string;

    @ViewChild('collapseSubFilter') _collapseSubFilter: CollapseContainerComponent;

    constructor(
        fb: UntypedFormBuilder,
        metadataService: MetaDataService,
        errorHandler: ErrorHandler,
        private _polygonService: PolygonService,
        private _mapInteractionService: MapInteractionService,
        private _toastService: ToasterService,
        private _wfsService: DatafordelerWfsService,
        private _cd: ChangeDetectorRef
    ) {
        // get source
        const metadata = metadataService.metadata;
        this.municipalitySource = metadata.municipalities;
        this.regionSource = metadata.regions;

        // build form
        this.form = fb.group(this._initialFormValue, { validators: this.validateForm.bind(this) });

        // wfs flow
        this._mapSelectedPointCoordinateSubject = new Subject<number[]>();

        // setup flow
        this._readySubject = new Subject<boolean>();
        this._valueSubject = new Subject<GeographicalValue>();

        this._innerSubscriptions = [
            // value in
            combineLatest([
                this._valueSubject,
                this._readySubject
            ]).subscribe({
                next: ([value]) => {
                    this._outterValueSubscription?.unsubscribe();
                    let hasInValue = false;

                    if (!_.isNil(value)) {
                        hasInValue = true;
                        const formValue = this.toFormValue(value);
                        this.form.patchValue(formValue, { onlySelf: true, emitEvent: false });
                        this.mode = formValue.mode;
                        this.searchType = formValue.searchType;
                    } else {
                        const initFormValue = { ...this._initialFormValue };
                        this.form.reset(initFormValue, { onlySelf: true, emitEvent: false });
                        this.mode = initFormValue.mode;
                        this.searchType = initFormValue.searchType;
                    }
                    this._mapInteractionService.requestShowGeographicData(this.toGeographicalRequest(this.form.value));
                    this.toggleMapSeachByMode(this.form.value.mode);

                    // manually zoom extend for Map View case
                    if (hasInValue && !_.isNil(this.form.value.mapView)) {
                        this._mapInteractionService.zoomExtend();
                    }

                    if (this.mode === 'Polygon') {
                        const polygonType = this.form.value.polygonType;

                        switch (polygonType) {
                            case 'Polygon':
                                // set default drawing type for Draw mode
                                this.drawAreaSelection = 'Polygon';
                                this._mapInteractionService.startDrawing('Polygon');
                                break;

                            case 'MapPoint':
                                this.drawAreaSelection = 'MapPoint';
                                this._mapInteractionService.updateMapPointSelectable(true);
                                break;
                        }
                    }

                    this._cd.markForCheck();

                    // setup outer flow based on init value
                    const initValue = this.toGeographicalValue(this.form.value);
                    this._outterValueSubject = new BehaviorSubject<GeographicalValue>(initValue);
                    this._outterValueSubscription = this._outterValueSubject
                        .pipe(distinctUntilChanged())
                        .subscribe({ next: v => this._onChange(v) });
                }
            }),

            // value out
            this.form.valueChanges.pipe(map(value => {
                this._mapInteractionService.requestShowGeographicData(this.toGeographicalRequest(value));
                return this.toGeographicalValue(value);
            })).subscribe({
                next: (value) => {
                    this._outterValueSubject?.next(value);

                    // Reset point selectable state if mode changes, this will affect the cursor-pointer style on the map
                    this._mapInteractionService.updateMapPointSelectable(this.mode === 'Polygon' && this.drawAreaSelection === 'MapPoint');
                    this._cd.markForCheck();
                }
            }),

            // drawing state
            combineLatest([
                this._mapInteractionService.drawingState$,
                this._readySubject
            ]).subscribe({
                next: ([state]) => {
                    if (_.isNil(state)) {
                        this.drawAreaSelection = null;
                    }

                    this._cd.markForCheck();
                }
            }),

            // handle drawn polygon from map
            combineLatest([
                this._mapInteractionService.searchPolygon$,
                this._readySubject
            ]).subscribe({
                next: ([polygon]) => {
                    this.form.patchValue({
                        polygon,
                        polygonType: 'Polygon'
                    });
                    this._cd.markForCheck();
                }
            }),

            // map view
            this.form.controls.mode.valueChanges.subscribe({
                next: this.toggleMapSeachByMode.bind(this)
            }),

            combineLatest([
                this._mapInteractionService.searchMapViewRequest$,
                this._readySubject
            ]).subscribe({
                next: ([polygon]) => {
                    this.form.patchValue({ mapView: polygon });
                }
            }),

            // map selection area
            this._mapInteractionService.mapSelectionArea$
                .subscribe({
                    next: area => {
                        this.mapSelectionArea = area;
                        this._cd.markForCheck();
                    }
                }),

            // search by map point
            this._mapInteractionService.searchByMapPointAbility$
                .subscribe({
                    next: ability => {
                        this.canSearchByMapPoint = ability;
                        this._cd.markForCheck();
                    }
                }),

            this._mapInteractionService.mapClickCoordinate$
                .pipe(
                    concatMap(coordinate => {
                        // Only feed coordinate to the pipe when it is correct mode and selection is MapPoint
                        // This to prevent wrong loading state or unnecessary handle of the point click
                        return iif(
                            () => this.canSearchByMapPoint && this.mode === 'Polygon' && this.drawAreaSelection === 'MapPoint',
                            of(coordinate)
                        );
                    })
                )
                .subscribe({
                    next: c => this._mapSelectedPointCoordinateSubject.next(c)
                }),

            this._mapSelectedPointCoordinateSubject
                .pipe(
                    tap(() => {
                        this.beginUpload.emit();
                        this._cd.markForCheck();
                    }),
                    switchMap(c => {
                        return this._wfsService.getLakeFeaturesByPoint(c).pipe(map(features => [c, features]));
                    }),
                    catchError(e => {
                        // manually handling error here to keep the flow alive for next request
                        errorHandler.handleError(e);
                        return of(null);
                    }),
                    tap((res) => {
                        this.endUpload.emit();

                        // error case
                        if (_.isNil(res)) { return; }

                        const features = res[1];

                        if (_.isEmpty(features)) {
                            this._toastService.toast({
                                type: ToastType.GeneralError,
                                data: {
                                    message: 'area_selection.draw_area.no_lake_notification',
                                    useTranslation: true
                                }
                            });
                        } else {
                            this.form.patchValue({
                                polygon: {
                                    features,
                                    representativeCoordinate: res[0]
                                }, polygonType: 'MapPoint'
                            });
                        }

                        this.endUpload.emit();
                        this._cd.markForCheck();
                    }),
                ).subscribe()
        ];
    }

    ngOnInit(): void {
        this._readySubject.next(true);
    }

    ngOnDestroy(): void {
        this._outterValueSubscription?.unsubscribe();
        this._innerSubscriptions.forEach(sub => sub.unsubscribe());
    }

    //#region ControlValueAccessor

    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: GeographicalValue): void {
        this._valueSubject.next(obj);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;

        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }

        this._cd.markForCheck();
    }

    //#endregion

    //#region validator

    validate(control: AbstractControl): ValidationErrors {
        return this.form.valid ? null : { innerFormInvalid: true };
    }

    registerOnValidatorChange?(fn: () => void): void {
    }

    //#endregion

    //#region Draw area selection
    public toggleDrawAreaSelection(selection: 'Polygon' | 'Rectangle' | 'MapPoint'): void {
        switch (selection) {
            case 'Polygon':
            case 'Rectangle':
                {
                    if (this.drawAreaSelection === selection) {
                        this.drawAreaSelection = null;
                        this.endDrawing();
                    }
                    else {
                        this.drawAreaSelection = selection;
                        this._mapInteractionService.startDrawing(selection);
                    }
                }
                break;

            case 'MapPoint':
                {
                    this._mapInteractionService.endDrawing();

                    if (this.drawAreaSelection === selection) {
                        this.drawAreaSelection = null;
                    } else {
                        this.drawAreaSelection = selection;
                        this._mapInteractionService.updateMapPointSelectable(true);
                    }
                }
        }

        this._mapInteractionService.updateMapPointSelectable(this.drawAreaSelection === 'MapPoint');
        this._cd.markForCheck();
    }

    private endDrawing(): void {
        this._mapInteractionService.endDrawing();
    }

    //#endregion

    //#region Upload

    public openUploadDialog(input: HTMLInputElement): void {
        input.click();
    }

    public onPolygonUpload(input: HTMLInputElement): void {
        const file = input.files[0];

        if (_.isNil(file)) { return; }

        const fileSize = file.size;

        if (fileSize > this._maximumUploadedFileSize) {
            this._toastService.toast({
                type: ToastType.GeneralError,
                data: {
                    message: 'errors.polygon_upload.file_size_limit',
                    useTranslation: true
                }
            });
            return;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => this.processUploadFile(fileReader, input);
        fileReader.readAsText(file);
    }

    private processUploadFile(fileReader: FileReader, input: HTMLInputElement): void {
        const features = this.readFeatures(fileReader);
        input.value = null;

        if (_.isEmpty(features)) { return; }

        this.beginUpload.emit();
        this._polygonService.savePolygon({ geoJsonString: this._geoJSON.writeFeatures(features) })
            .pipe(finalize(() => this.endUpload.emit()))
            .subscribe({
                next: id => {
                    const polygon = {
                        id,
                        features
                    };
                    this.form.patchValue({ polygon, polygonType: 'Polygon' });
                    this.drawAreaSelection = null;
                    this.endDrawing();
                    this._mapInteractionService.updateMapPointSelectable(false);
                    this._cd.markForCheck();
                }
            });
    }

    private readFeatures(fileReader: FileReader): Feature[] {
        try {
            const features = this._geoJSON.readFeatures(fileReader.result) as Feature[];

            // validate
            const isValid = this.isUploadedFeaturesValid(features);
            if (!isValid) { return null; }

            return features;
        }
        catch {
            this._toastService.toast({
                type: ToastType.GeneralError,
                data: {
                    message: 'errors.polygon_upload.invalid_format',
                    useTranslation: true
                }
            });
        }

        return null;
    }

    private isUploadedFeaturesValid(features: Feature[]): boolean {
        if (_.isEmpty(features)) { return true; }

        // check boundary
        const extent = features.map(f => f.getGeometry().getExtent())
            .reduceRight((a, b) => extend(a, b));
        const coords = [[extent[0], extent[1]], [extent[2], extent[3]]];
        const insideMapBoudary = coords.every(c => containsCoordinate(AppConfig.map.viewExtent, c));
        if (!insideMapBoudary) {
            this._toastService.toast({
                type: ToastType.GeneralError,
                data: {
                    message: 'errors.polygon_upload.outside_map_boudary',
                    useTranslation: true
                }
            });
            return false;
        }

        return true;
    }

    //#endregion

    private validateForm(control: AbstractControl): ValidationErrors {
        const value = control.value as FormValue;

        switch (value.mode) {
            case 'Search':
                switch (value.searchType) {
                    case 'Place':
                        const trimmedValue = value.place?.trim() ?? '';
                        return (trimmedValue.length < this._SEARCH_PLACE_MIN_CHARACTERS) ? { minimumCharacter: true } : null;

                    case 'Municipality':
                        return _.isEmpty(value.municipality) ? { municipalityRequired: true } : null;

                    case 'Region':
                        return _.isEmpty(value.region) ? { regionRequired: true } : null;
                }
                break;

            case 'Polygon':
                return _.isEmpty(value.polygon?.features) ? { polygonRequired: true } : null;
        }

        return null;
    }

    private toFormValue(value: GeographicalValue): FormValue {
        const result = { ...this._initialFormValue };

        switch (value.searchBy) {
            case AreaType.Place:
                result.mode = 'Search';
                result.searchType = 'Place';
                result.place = value.place;
                break;

            case AreaType.Polygon:
                result.mode = 'Polygon';
                result.polygon = value.polygon;
                result.polygonType = value.polygonType;
                break;

            case AreaType.Municipality:
                result.mode = 'Search';
                result.searchType = 'Municipality';
                result.municipality = value.municipality;
                break;

            case AreaType.Region:
                result.mode = 'Search';
                result.searchType = 'Region';
                result.region = value.region;
                break;

            case AreaType.MapView:
                result.mode = 'MapView';
                result.mapView = value.mapView;
                break;
        }

        return result;
    }

    private toGeographicalValue(value: FormValue): GeographicalValue {
        switch (value.mode) {
            case 'Search':
                {
                    switch (value.searchType) {
                        case 'Place':
                            const trimmedValue = value.place?.trim();
                            return _.isEmpty(trimmedValue)
                                ? this._defaultOutterGeographicalValue
                                : {
                                    searchBy: _.isEmpty(trimmedValue) ? '' : AreaType.Place,
                                    place: trimmedValue
                                };

                        case 'Municipality':
                            return _.isNil(value.municipality)
                                ? this._defaultOutterGeographicalValue
                                : {
                                    searchBy: AreaType.Municipality,
                                    municipality: value.municipality
                                };

                        case 'Region':
                            return _.isNil(value.region)
                                ? this._defaultOutterGeographicalValue
                                : {
                                    searchBy: AreaType.Region,
                                    region: value.region
                                };
                    }
                }
                break;

            case 'Polygon':
                return _.isEmpty(value.polygon)
                    ? this._defaultOutterGeographicalValue
                    : {
                        searchBy: AreaType.Polygon,
                        polygonType: value.polygonType,
                        polygon: value.polygon
                    };

            case 'MapView':
                return _.isNil(value.mapView)
                    ? null
                    : {
                        searchBy: AreaType.MapView,
                        mapView: value.mapView
                    };

            default:
                return this._defaultOutterGeographicalValue;
        }
    }

    private toGeographicalRequest(formValue: FormValue): GeographicalRequest {
        switch (formValue.mode) {
            case 'Polygon':
                if (!_.isEmpty(formValue.polygon?.features)) {
                    return {
                        type: AreaType.Polygon,
                        features: formValue.polygon.features
                    };
                }
                break;

            case 'Search': {
                switch (formValue.searchType) {
                    case 'Municipality':
                        if (!_.isNil(formValue.municipality)) {
                            return {
                                type: AreaType.Municipality,
                                ids: formValue.municipality
                            };
                        }
                        break;

                    case 'Region':
                        if (!_.isNil(formValue.region)) {
                            return {
                                type: AreaType.Region,
                                ids: formValue.region
                            };
                        }
                        break;
                }
            }
                           break;

            case 'MapView':
                if (!_.isNil(formValue.mapView)) {
                    return {
                        type: AreaType.MapView,
                        features: formValue.mapView.features
                    };
                }
                break;

            default:
                return null;
        }

        return null;
    }

    private toggleMapSeachByMode(mode: string): void {
        if (mode === 'MapView') {
            this._mapInteractionService.startSearchByMapView();
            return;
        }

        this._mapInteractionService.endSearchByMapView();
    }

    public updateMode(value: 'MapView' | 'Search' | 'Polygon'): void {
        if (this.mode === value) { return; }

        this.endDrawing();
        this.mode = value;
        this.searchType = null;

        switch (value) {
            case 'Polygon':
                this.form.patchValue({ mode: value });

                if (_.isEmpty(this.drawAreaSelection)) {
                    this.toggleDrawAreaSelection('Polygon');
                }
                break;

            default:
                this.form.patchValue({ mode: value });
                break;
        }

        this._cd.markForCheck();
    }

    public updateSearchType(value: 'Place' | 'Municipality' | 'Region'): void {
        if (this.searchType === value) { return; }

        this.updateMode("Search");
        this.searchType = value;
        this.form.patchValue({ searchType: value });
        this._cd.markForCheck();
    }

    public deleteMapView(): void {
        this.form.patchValue({ mapView: null });
        this._cd.markForCheck();
    }

    public deletePolygon(): void {
        this.form.patchValue({ polygon: null });
        this._cd.markForCheck();
    }

    public collapse(): void {
        this._collapseSubFilter.collapse();
    }

    public open(): void {
        this._collapseSubFilter.open();
    }
}
