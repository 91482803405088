<div #map class="map-el"></div>
<div class="position-relative dataud-map">
    <div id="layerAttribution"></div>
    <div id="layerSelector"></div>
</div>

<div class="top-left-btn-container">
    <div *ngIf="inSearchMode" class="search-btn-container">
        <button type="button" class="btn btn-primary btn-search-on-map" [class.disabled]="!canSearchByMapView || isDisabledSearchByMapArea" (click)="requestSearchByMapView()">
            <i class="far fa-search fa-lg"></i>
            <span class="ml-2 d-none d-md-inline">{{'map.search_by_map_view' | translate}}</span>
        </button>
    </div>
</div>

<div class="bottom-container">
    <div class="position-relative">
        <div *ngIf="exceedMaxStationWarningShown" class="warning-container">
            <div class="d-flex align-items-center py-2">
                <div class="icon-container">
                    <i class="fas fa-exclamation-circle fa-2x"></i>
                </div>

                <div>
                    <div class="title">{{'map.warnings.exceedMaxStation.title' | translate}}</div>
                    <div class="description">{{'map.warnings.exceedMaxStation.description' | translate}}</div>
                </div>
            </div>
        </div>

        <div id="baseLayerToggler" [class.above-warning]="exceedMaxStationWarningShown"></div>

        <div class="ol-zoom ol-unselectable" [class.above-warning]="exceedMaxStationWarningShown" style="pointer-events: auto;">
            <div class="ol-control">
                <button class="ol-zoom-in" type="button" title="Zoom in" (click)="zoom(1)">+</button>
                <button class="ol-zoom-out" type="button" title="Zoom out" (click)="zoom(-1)">–</button>
            </div>
        </div>
    </div>
</div>

<div #popupContainer class="popup-container" (click)="$event.stopPropagation();">
    <div class="arrow"></div>
    <ng-template [ngIf]="popupTemplate" [ngTemplateOutlet]="popupTemplate"></ng-template>
</div>

<dataud-loading-spinner *ngIf="isSavingPolygon"></dataud-loading-spinner>