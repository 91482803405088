import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { Toast } from 'src/app/models/toast.models';

@Injectable()
export class ToasterService {
    private _toasterSubject: Subject<Toast>;

    constructor() {
        this._toasterSubject = new Subject<Toast>();
    }

    public toast(toast: Toast): void {
        this._toasterSubject.next(toast);
    }

    public get toast$(): Observable<Toast> {
        return this._toasterSubject.asObservable();
    }
}
