export interface Toast {
    type: ToastType;
    data: HttpErrorData | GeneralError;
}

export enum ToastType {
    HttpError = 'HttpError',
    GeneralError = 'InputError'
}

export class HttpErrorData {
    status: number;
    statusText: string;
    url: string;
    dateTime: Date;
    message: string;
}

export class GeneralError {
    message: string;
    title?: string;
    useTranslation: boolean;
}
