export enum DMPNotificationTypeEnum {
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Default = 'default'
}

export interface DMPNotification {
    notificationId?: number;
    title?: string;
    description?: string; // Contains HTML
    validFrom?: string;
    validTo?: string;
    type?: string;
}

export interface DMPNotificationUserState {
    sub: string;
    dismissIds: number[];
}
