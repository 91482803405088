import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettingsService } from '../infrastructures/app-settings.service';
import { DMPNotification, DMPNotificationUserState } from 'src/app/models/dmp-notification.models';

@Injectable()
export class DMPNotificationService {

    constructor(private _http: HttpClient, private _appSettings: AppSettingsService) {
        this.setupBridgeCommunation();
    }

    public get notificationCount$(): Observable<number> {
        return this._notificationCountSubject.asObservable();
    }

    public get toggleShowingNotification$(): Observable<any> {
        return this._toggleShowingNotificationSubject.asObservable();
    }
    private _storageKey = 'notification.user.dismiss';
    private _storage: Storage = localStorage;

    //#region bridge communcication
    private _notificationCountSubject: Subject<number>;
    private _toggleShowingNotificationSubject: Subject<any>;

    getNotifications(): Observable<DMPNotification[]> {
        const url = this._appSettings.appSettings.DmpNotificationUri;
        return this._http.get<DMPNotification[]>(url)
            .pipe(map(items => {
                items?.forEach((x: any) => {
                    x.notificationId = x.NotificationId;
                    x.title = x.Title;
                    x.description = x.Description;
                    x.validFrom = x.ValidFrom;
                    x.validTo = x.ValidTo;
                    x.type = x.Type;
                });
                return items;
            }));
    }

    public dismissNotification(userClaims: any, item: DMPNotification, feeds: DMPNotification[]): void {
        const states: DMPNotificationUserState[] = JSON.parse(this._storage.getItem(this._storageKey)) ?? [];
        let userState = states.find(x => x.sub === userClaims.sub);

        if (!userState) {
            userState = { sub: userClaims.sub, dismissIds: [] };
            states.push(userState);
        }
        userState.dismissIds.push(item.notificationId);
        this.optimizeStorageAndSave(states, feeds);
    }

    private optimizeStorageAndSave(states: DMPNotificationUserState[], feeds: DMPNotification[]): void {
        const feedsIds = feeds.map(x => x.notificationId);
        // For Ids that are no longer in the feeds, just remove them
        states.forEach(userState => {
            const expiredItemsId = userState.dismissIds.filter(id => !feedsIds.includes(id));
            userState.dismissIds = userState.dismissIds.filter(id => !expiredItemsId.includes(id));
        });
        // Save
        this._storage.setItem(this._storageKey, JSON.stringify(states));
    }

    public getUserNotifications(userClaims: any, feeds: DMPNotification[]): DMPNotification[] {
        const states: DMPNotificationUserState[] = JSON.parse(this._storage.getItem(this._storageKey)) ?? [];
        const userState = states.find(x => x.sub === userClaims.sub);

        if (!userState) { return feeds; }
        return feeds.filter(x => !userState.dismissIds.includes(x.notificationId));
    }

    private setupBridgeCommunation(): void {
        this._notificationCountSubject = new Subject<number>();
        this._toggleShowingNotificationSubject = new Subject<any>();
    }

    public updateNotificationCount(count: number): void {
        this._notificationCountSubject.next(count);
    }

    public toggleShowingNotifications(): void {
        this._toggleShowingNotificationSubject.next();
    }
}
