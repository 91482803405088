<ul class="nav nav-tabs" role="tablist">
    <li class="nav-item" role="presentation">
        <div class="nav-link main-selection-tab" [class.active]="searchBy === 'Chemistry'" data-bs-toggle="tab" tabindex="-1" role="tab" (click)="selectTab('Chemistry')">
            {{ 'main_selection.tabs.chemistry' | translate }}
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="nav-link main-selection-tab" [class.active]="searchBy === 'Species'" data-bs-toggle="tab" tabindex="-1" role="tab" (click)="selectTab('Species')">
            {{ 'main_selection.tabs.species' | translate }}
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="nav-link main-selection-tab" [class.active]="searchBy === 'ExaminationTypes'" data-bs-toggle="tab" tabindex="-1" role="tab"
            (click)="selectTab('ExaminationTypes')">
            {{ 'main_selection.tabs.examination' | translate }}
        </div>
    </li>
    <li class="nav-item" role="presentation">
        <div class="nav-link main-selection-tab" [class.active]="searchBy === 'SampleLocations'" data-bs-toggle="tab" tabindex="-1" role="tab"
            (click)="selectTab('SampleLocations')">
            {{ 'main_selection.tabs.sample_location' | translate }}
        </div>
    </li>
</ul>

<dataud-collapse-container [header]="getSelectionTitle() | async" [formGroup]="form">
    <!-- ExaminationTypes -->
    <div [class.d-none]="searchBy !== 'ExaminationTypes'">
        <div class="filter-item">
            <div class="mb-2">
                <span>{{'main_selection.medias' | translate}}</span>
            </div>

            <dataud-select *ngIf="searchBy === 'ExaminationTypes'" class="form-group-reverse" formControlName="examinationMediaTypes"
                [placeholder]="'main_selection.medias_placeholder' | translate" [multiple]="false" [allowClear]="true" [items]="excludedMarinLoggerstationMediaSource"
                [closeOnSelect]="true" bindLabel="displayName" bindValue="value">
            </dataud-select>
        </div>

        <div class="mt-3">
            <div class="mb-2">
                <span>{{'main_selection.examination_type.label' | translate}}</span>
            </div>

            <dataud-select #examinationTypesSelect *ngIf="searchBy === 'ExaminationTypes'" class="form-group-reverse" formControlName="examinationTypes"
                [placeholder]="'main_selection.examination_type.placeholder' | translate" [multiple]="true" [items]="examinationTypeSource" [isSelectingOnClose]="true"
                bindLabel="displayName" bindValue="value">
            </dataud-select>
        </div>
    </div>

    <!-- Chemistry -->
    <div class="row" [class.d-none]="searchBy !== 'Chemistry'">
        <div class="col">
            <!-- ChemicalParameters { -->
            <dataud-check-box [handleCheckedManually]="true" [checked]="chemistryOption === ChemistryOption.ChemicalParameters"
                [label]="'main_selection.chemistry.label' | translate" [disabled]="isDisabled" (clicked)="updateSearchBy('Chemistry', ChemistryOption.ChemicalParameters)">
            </dataud-check-box>

            <dataud-select *ngIf="searchBy === 'Chemistry' && chemistryOption === ChemistryOption.ChemicalParameters" class="form-group-reverse"
                formControlName="chemicalParameters" [placeholder]="'main_selection.chemistry.placeholder' | translate" [multiple]="true" [items]="chemistrySource"
                [isSelectingOnClose]="true" bindLabel="displayName" bindValue="value" [formatItemStateFn]="select2ChemistryFormatItemState.bind(this)"
                [searchMatcherFn]="select2ChemistrySearchMatcherFn">
            </dataud-select>
            <!-- } ChemicalParameters -->

            <div class="mt-3"></div>

            <!-- ChemicalParameterGroup { -->
            <dataud-check-box [handleCheckedManually]="true" [checked]="chemistryOption === ChemistryOption.ChemicalParameterGroup"
                [label]="'main_selection.chemistry.substance_groups.label' | translate" [disabled]="isDisabled"
                (clicked)="updateSearchBy('Chemistry', ChemistryOption.ChemicalParameterGroup)">
            </dataud-check-box>

            <dataud-select *ngIf="searchBy === 'Chemistry' && chemistryOption === ChemistryOption.ChemicalParameterGroup" class="form-group-reverse"
                formControlName="chemicalParameterGroup" [allowClear]="true" [placeholder]="'main_selection.chemistry.substance_groups.placeholder' | translate" [multiple]="false"
                [items]="parameterGroupSource" [isSelectingOnClose]="true" bindLabel="name" bindValue="id">
            </dataud-select>
            <!-- } ChemicalParameterGroup -->

            <hr class="my-4 mx-0">
            <div class="filter-item">
                <div class="mb-2">
                    <span>{{'main_selection.medias' | translate}}</span>
                </div>

                <dataud-select *ngIf="searchBy === 'Chemistry'" class="form-group-reverse" formControlName="chemicalMediaTypes"
                    [placeholder]="'main_selection.medias_placeholder' | translate" [multiple]="true" [items]="excludedMarinLoggerstationMediaSource" [isSelectingOnClose]="true"
                    bindLabel="displayName" bindValue="value">
                </dataud-select>

                <div class="mt-2">
                    <small class="d-flex align-items-center">
                        <span>{{'main_selection.read_more_about_chemistry_parameters' | translate}}</span>
                        <a class="ml-1" target="_blank" [attr.href]="chemistryParamsLink">{{'main_selection.chemistry_parameters_url' | translate}}</a>
                    </small>
                </div>
            </div>
        </div>
    </div>

    <!-- Species -->
    <div class="row form-group" [class.d-none]="searchBy !== 'Species'" [formGroup]="speciesForm">
        <div class="col">
            <dataud-check-box [handleCheckedManually]="true" [checked]="searchBy === 'Species'" [label]="'main_selection.species.label' | translate" [disabled]="isDisabled"
                (clicked)="updateSearchBy('Species')">
            </dataud-check-box>

            <ng-select #species *ngIf="searchBy === 'Species'" formControlName="speciesSelection" class="mt-3" [bindLabel]="'displayName'" [bindValue]="'value'"
                [items]="speciesSource" [multiple]="true" [closeOnSelect]="false" [virtualScroll]="true" [placeholder]="'main_selection.species.placeholder' | translate"
                (change)="onSpeciesSelectionChangeFn()" (close)="onSpeciesSelectionClosedFn()">
            </ng-select>
        </div>
    </div>

    <!-- SampleLocations -->
    <div class="row" [class.d-none]="searchBy !== 'SampleLocations'">
        <div class="col">
            <div class="mb-2">
                <span>{{'main_selection.medias' | translate}}</span>
            </div>

            <dataud-select *ngIf="searchBy === 'SampleLocations'" class="form-group-reverse" formControlName="locationMediaTypes"
                [placeholder]="'main_selection.sample_location_medias_placeholder' | translate" [multiple]="true" [items]="mediaSource" [isSelectingOnClose]="true"
                bindLabel="displayName" bindValue="value">
            </dataud-select>
        </div>
    </div>
</dataud-collapse-container>