import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, forwardRef, ChangeDetectorRef } from '@angular/core';
import {
    UntypedFormBuilder, UntypedFormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR,
    NG_VALIDATORS, Validator, AbstractControl, ValidationErrors
} from '@angular/forms';

import { Subject, Subscription, combineLatest } from 'rxjs';

import * as _ from 'lodash';

import { MetaDataService } from 'src/app/services/api/metadata.service';
import { DataResponsible } from './dataud-data-responsible.model';

@Component({
    selector: 'dataud-data-responsible-selection',
    templateUrl: './dataud-data-responsible-selection.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DataResponsibleSelectionComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DataResponsibleSelectionComponent),
            multi: true
        }
    ]
})
export class DataResponsibleSelectionComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public isDisabled: boolean;

    // dropdown sources
    public dataResponsibleSource: DataResponsible[];

    // flow pipelines
    private _initSubject: Subject<boolean>;
    private _valueSubject: Subject<DataResponsible>;
    private _innerSubscriptions: Subscription[];

    constructor(fb: UntypedFormBuilder, metadataService: MetaDataService, private _cd: ChangeDetectorRef) {
        // loading metadata
        const metadata = metadataService.metadata;
        this.dataResponsibleSource = metadata.dataResponsibles;

        // build form
        this.form = fb.group({ dataResponsibles: null });

        // set up flow
        this._initSubject = new Subject<boolean>();
        this._valueSubject = new Subject<DataResponsible>();
        this._innerSubscriptions = [
            combineLatest([
                this._valueSubject,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    this.form.patchValue({ dataResponsibles: value }, { onlySelf: true, emitEvent: false });
                    this._cd.markForCheck();
                }
            }),

            combineLatest([
                this.form.valueChanges,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    this._onChange(value?.dataResponsibles);
                }
            })
        ];
    }

    ngOnInit(): void {
        this._initSubject.next(true);
    }

    ngOnDestroy(): void {
        this._innerSubscriptions.forEach(sub => sub.unsubscribe());
    }

    // #region ControlValueAccessor
    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: DataResponsible): void {
        this._valueSubject.next(obj);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;

        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }

        this._cd.markForCheck();
    }
    // #endregion

    // #region validator
    validate(control: AbstractControl): ValidationErrors {
        return this.form.valid ? null : { innerFormInvalid: true };
    }

    registerOnValidatorChange?(fn: () => void): void {
    }
    // #endregion
}
