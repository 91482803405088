import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';

import { AppSettingsService } from '../infrastructures/app-settings.service';

@Injectable()
export class ApiUrlService {
    constructor(private _appSettings: AppSettingsService, private _router: Router, private _urlSerializer: UrlSerializer) {
    }

    private get _root(): string {
        // using runtime getter because appsetting is not load when DI creates instance
        return this._appSettings.appSettings.apiRootUrl;
    }

    private fineUri(url: string): string {
        return url.replace(/([^:]\/)\/+/g, '$1');
    }

    public metadata(language: string): string {
        const queryParams = { language };
        const urlTree = this._router.createUrlTree(['metadata'], { queryParams });
        const url = `${this._root}${this._urlSerializer.serialize(urlTree)}`;
        return this.fineUri(url);
    }

    public search(): string {
        return this.fineUri(`${this._root}/search`);
    }

    public savePolygon(): string {
        return this.fineUri(`${this._root}/polygon`);
    }

    public getPolygon(id: string): string {
        return this.fineUri(`${this._root}/polygon/${id}`);
    }

    public download(): string {
        return this.fineUri(`${this._appSettings.appSettings.apiRootUrl}/download`);
    }

    public getRegion(id: string): string {
        return this.fineUri(`${this._root}/geojson/region/${id}`);
    }

    public getMunicipality(id: string): string {
        return this.fineUri(`${this._root}/geojson/municipality/${id}`);
    }

    public searchSampleLocations(): string {
        return this.fineUri(`${this._root}/station/search`);
    }

    public downloadSampleLocations(): string {
        return this.fineUri(`${this._appSettings.appSettings.apiRootUrl}/station/download`);
    }

    public getStation(id: string): string {
        return this.fineUri(`${this._appSettings.appSettings.hydroApiUrl}/stations?stationId=${id}`);
    }
}
