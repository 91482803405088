import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// localization
import localeDa from '@angular/common/locales/da';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeDa, 'da');
registerLocaleData(localeEn, 'en');

// 3rd-party modules
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgSelectModule } from '@ng-select/ng-select';

// DataUd defined modules
import { AppRoutingModule } from './app-routing.module';
import { AppTranslateModule } from './app.translate.module';

import { AppComponent } from './app.component';
import { AppConfig } from './configurations/app-config';

// Base
import { SelectComponent } from './components/base/select/dataud-select.component';
import { DatePickerComponent } from './components/base/date-picker/dataud-date-picker.component';
import { CheckBoxComponent } from './components/base/check-box/dataud-check-box.component';

// Layout
import { MainLayoutComponent } from './components/layout/main-layout/dataud-main-layout.component';
import { ContentLayoutComponent } from './components/layout/content-layout/dataud-content-layout.component';
import { PageHeaderComponent } from './components/layout/page-header/dataud-page-header.component';
import { MapComponent } from './components/layout/map/dataud-map.component';
import { CollapseContainerComponent } from './components/layout/collapse-container/dataud-collapse-container.component';
import { LoadingSpinnerComponent } from './components/layout/loading-spinner/dataud-loading-spinner.component';
import { DesktopSideBarComponent } from './components/layout/desktop-side-bar/dataud-desktop-side-bar.component';
import { MobileViewComponent } from './components/layout/mobile-view/dataud-mobile-view.component';
import { DMPNotificationAreaComponent } from './components/layout/dmp-notification-area/dataud-dmp-notification-area.component';

// Display
import { FilterComponent } from './components/display/filter/dataud-filter.component';
import { ResultsComponent } from './components/display/results/dataud-results.component';
import { SaveSearchComponent } from './components/display/save-search/dataudv-save-search.component';
import { DMPNotificationBoxComponent } from './components/display/dmp-notification-box/dataud-dmp-notification-box.component';

// Value Accessors
import { MainSelectionComponent } from './components/value-accessor/main-selection/dataud-main-selection.component';
import { GeographicalSelectionComponent } from './components/value-accessor/geographical-selection/dataud-geographical-selection.component';
import { PeriodSelectionComponent } from './components/value-accessor/period-selection/dataud-period-selection.component';

// Utils
import { PaginationComponent } from './components/utils/pagination/dataud-pagination.component';

// Services
import { AppInitiationService } from './services/infrastructures/app-initiation.service';
import { MetaDataService } from './services/api/metadata.service';
import { GeographicalService } from './services/api/geographical.service';
import { ApiUrlService } from './services/api/api-url.service';
import { AppSettingsService } from './services/infrastructures/app-settings.service';
import { FilterDispatchService } from './services/communication/filter-dispatch.service';
import { SearchService } from './services/api/search.service';
import { LoadingIndicatorService } from './services/communication/loading-indicator.service';
import { MapInteractionService } from './services/communication/map-interaction.service';
import { ToasterComponent } from './components/utils/toaster/dataud-toaster.component';
import { GlobalErrorHandlerService } from './services/infrastructures/global-error-handler.service';
import { ToasterService } from './services/communication/toaster.service';
import { SearchLinkService } from './services/communication/search-link.service';
import { PolygonService } from './services/api/polygon.service';
import { DownloadService } from './services/api/download.service';
import { MobileViewInteractionService } from './services/communication/mobile-view-interaction.service';
import { SampleLocationService } from './services/api/sample-location.service';
import { DatafordelerWfsService } from './services/datafordeler/datafordeler-wfs.service';
import { DMPNotificationService } from './services/api/dmp-notification.service';
import { DataResponsibleSelectionComponent } from './components/value-accessor/data-responsible-selection/dataud-data-responsible-selection.component';
import { StationOwnerSelectionComponent } from './components/value-accessor/station-owner-selection/dataud-station-owner-selection.component';
import { StationOperatorSelectionComponent } from './components/value-accessor/station-operator-selection/dataud-station-operator-selection.component';
import { MeasuredParameterSelectionComponent } from './components/value-accessor/measured-parameter/dataud-measured-parameter-selection.component';
import { HydroStationService } from './services/api/hydro-station.service';

@NgModule({
    declarations: [
        AppComponent,

        // Base
        SelectComponent,
        DatePickerComponent,
        CheckBoxComponent,

        // Layout
        MainLayoutComponent,
        ContentLayoutComponent,
        PageHeaderComponent,
        DesktopSideBarComponent,
        MapComponent,
        CollapseContainerComponent,
        MobileViewComponent,
        LoadingSpinnerComponent,
        DMPNotificationAreaComponent,

        // Display
        FilterComponent,
        ResultsComponent,
        SaveSearchComponent,
        DMPNotificationBoxComponent,

        // Value Accessors
        MainSelectionComponent,
        GeographicalSelectionComponent,
        PeriodSelectionComponent,
        DataResponsibleSelectionComponent,
        StationOwnerSelectionComponent,
        StationOperatorSelectionComponent,
        MeasuredParameterSelectionComponent,

        // Utils
        PaginationComponent,
        ToasterComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SimpleNotificationsModule.forRoot(),
        ScrollingModule,
        AppTranslateModule,
        AppRoutingModule,
        NgSelectModule,
    ],
    providers: [
        AppInitiationService,
        AppSettingsService,
        ApiUrlService,
        MetaDataService,
        GeographicalService,
        SearchService,
        FilterDispatchService,
        LoadingIndicatorService,
        MapInteractionService,
        ToasterService,
        SearchLinkService,
        PolygonService,
        DownloadService,
        MobileViewInteractionService,
        SampleLocationService,
        DatafordelerWfsService,
        DMPNotificationService,
        HydroStationService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitiation: AppInitiationService) => async () => await appInitiation.loadSettings(),
            deps: [AppInitiationService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: LOCALE_ID,
            useFactory: () => localStorage.getItem(AppConfig.webStorage.localStorage.currentLanguage)
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
