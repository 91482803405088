import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, timeout, tap, } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configurations/app-settings.interface';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    public appSettings: AppSettings;

    constructor(protected httpClient: HttpClient) { }

    public get loadAppSetting$(): Observable<AppSettings> {
        return this.httpClient.get<AppSettings>('config')
            .pipe(
                timeout(3000),
                retry(4),
                tap(response => this.appSettings = response)
            );
    }
}