<div class="card border-dark {{role}}">
    <div class="card-header" [class.all-rounded]="isCollapsed">
        <div class="header-container">
            <span text-role="secondary-header">{{header}}</span>

            <button class="btn collapse-btn link-primary" (click)="toggle()">
                <i class="fa-solid" [class.fa-chevron-down]="isCollapsed" [class.fa-chevron-up]="!isCollapsed"></i>
            </button>
        </div>
    </div>

    <div class="collapse" #contentContainer>
        <div class="card-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>