import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable()
export class MobileViewInteractionService {
    private _openTabRequestSubject: Subject<'Filter' | 'Result'>;

    constructor() {
        this._openTabRequestSubject = new Subject<'Filter' | 'Result'>();
    }

    public requestOpenTab(request: 'Filter' | 'Result'): void {
        this._openTabRequestSubject.next(request);
    }

    public get openTabRequest$(): Observable<'Filter' | 'Result'> {
        return this._openTabRequestSubject.asObservable();
    }
}
