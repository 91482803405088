import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from '@angular/forms';

import { Subject, Subscription, combineLatest } from 'rxjs';

import * as _ from 'lodash';

import { MetaDataService } from 'src/app/services/api/metadata.service';
import { PeriodValue } from './dataud-period-selection.models';

@Component({
    selector: 'dataud-period-selection',
    templateUrl: './dataud-period-selection.component.html',
    styleUrls: ['./dataud-period-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PeriodSelectionComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PeriodSelectionComponent),
            multi: true
        }
    ]
})
export class PeriodSelectionComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
    public form: UntypedFormGroup;

    public minDate: Date;
    public maxDate: Date;

    private _initSubject: Subject<boolean>;
    private _valueSubject: Subject<PeriodValue>;
    private _innerSubscriptions: Subscription[];

    constructor(fb: UntypedFormBuilder,
                metadataService: MetaDataService,
                private _cd: ChangeDetectorRef) {
        const metadata = metadataService.metadata;
        this.minDate = metadata.minPeriodDate;
        this.maxDate = metadata.maxPeriodDate;

        this._initSubject = new Subject<boolean>();
        this._valueSubject = new Subject<PeriodValue>();
        this._innerSubscriptions = [];

        this.form = fb.group({
            startDate: null,
            endDate: null,
            showLatestExaminationsResultsOnly: [false]
        }, { validators: [this.validateForm.bind(this)] });

        this.form.statusChanges.subscribe({ next: () => this._cd.markForCheck() });
    }

    ngOnInit(): void {
        this._innerSubscriptions.push(
            combineLatest([
                this._valueSubject,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    this.form.patchValue({
                        startDate: value?.startDate,
                        endDate: value?.endDate,
                        showLatestExaminationsResultsOnly: value?.showLatestExaminationsResultsOnly,
                    }, { onlySelf: true, emitEvent: false });
                }
            })
        );

        this._innerSubscriptions.push(
            combineLatest([
                this.form.valueChanges,
                this._initSubject
            ]).subscribe({
                next: ([value]) => {
                    this._onChange(value);
                }
            })
        );

        this._initSubject.next(true);
    }

    ngOnDestroy(): void {
        this._innerSubscriptions.forEach(sub => sub.unsubscribe());
    }

    //#region ControlValueAccessor

    private _onChange = (fn: any) => { };
    private _onTouch = () => { };

    writeValue(obj: PeriodValue): void {
        this._valueSubject.next(obj);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    //#endregion

    //#region validator

    validate(control: AbstractControl): ValidationErrors {
        return this.form.valid ? null : { innerFormInvalid: true };
    }

    registerOnValidatorChange?(fn: () => void): void {
    }

    //#endregion

    private validateForm(control: AbstractControl): ValidationErrors {
        const formValue = control.value as PeriodValue;

        if (_.isNil(formValue.startDate) || _.isNil(formValue.endDate)) { return null; }

        const startDate = new Date(formValue.startDate);
        const endDate = new Date(formValue.endDate);

        return startDate > endDate ? { invalidDateRange: true } : null;
    }
}
