import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

import { contains as containsFilter } from 'ol/format/filter';
import { AppConfig } from 'src/app/configurations/app-config';
import { Dataset } from '@dmp/lagvaelger-client-api';

@Injectable()
export class DatafordelerWfsService {
    private _lakeDataset: Dataset;

    constructor() {
    }

    public setLakeDataset(ds: Dataset) {
        this._lakeDataset = ds;
    }

    public getLakeFeaturesByPoint(coordinate: number[]): Observable<Feature[]> {
        const wfsSetting = AppConfig.map.wfs.lake;

        if (!this._lakeDataset) {
            return of(null);
        }

        return from(this._lakeDataset.query(containsFilter('point', new Point(coordinate), wfsSetting.srsName)));
    }
}
