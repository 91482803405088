import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

import { ApiUrlService } from './api-url.service';
import { PolygonSaveRequest } from './requests/polygon-save.request';

@Injectable()
export class PolygonService {
    private _geoJSON = new GeoJSON();

    constructor(private _htttp: HttpClient, private _apiUrlService: ApiUrlService) {
    }

    public savePolygon(request: PolygonSaveRequest): Observable<string> {
        const url = this._apiUrlService.savePolygon();
        return this._htttp.post<{ id: string }>(url, request).pipe(map(x => x.id));
    }

    public getPolygon(id: string): Observable<Feature[]> {
        const url = this._apiUrlService.getPolygon(id);
        return this._htttp.get(url).pipe(map(res => this._geoJSON.readFeatures(res) as Feature[]));
    }
}
