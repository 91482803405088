<ng-container [formGroup]="form">
    <div class="row form-group">
        <div class="col">
            <div class="mb-2">
                <span>{{'measured_parameter_selection.filter_by' | translate}}</span>
            </div>

            <dataud-select class="form-group-reverse" formControlName="measuredParameters" bindLabel="displayName" bindValue="value" [items]=" measuredParameterSource"
                [placeholder]="'measured_parameter_selection.placeholder' | translate" [multiple]="true" [isSelectingOnClose]="true">
            </dataud-select>
        </div>
    </div>
</ng-container>