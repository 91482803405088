import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

import { Filter } from 'src/app/models/filter.model';

@Injectable()
export class FilterDispatchService {
    private _filterSubject: Subject<Filter>;
    private _filterReset: Subject<any>;

    constructor() {
        this._filterSubject = new Subject<Filter>();
        this._filterReset = new Subject<any>();
    }

    public dispatch(filter: Filter): void {
        this._filterSubject.next(filter);
    }

    public get filter$(): Observable<Filter> {
        return this._filterSubject.asObservable();
    }

    public reset(): void {
        this._filterReset.next(null);
    }

    public get filterReset$(): Observable<any> {
        return this._filterReset.asObservable();
    }
}
