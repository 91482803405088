import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ApiUrlService } from './api-url.service';
import { SampleLocationDownloadRequest, SampleLocationSearchRequest } from './requests/sample-location.requests';
import { SampleLocationResponse } from './responses/sample-location.response';
import { HttpHelper } from 'src/app/utils/http.helper';

@Injectable()
export class SampleLocationService {
    constructor(private _htttp: HttpClient, private _apiUrlService: ApiUrlService) {
    }

    public search(request: SampleLocationSearchRequest): Observable<SampleLocationResponse> {
        const url = this._apiUrlService.searchSampleLocations();
        return this._htttp.post<SampleLocationResponse>(url, request);
    }

    public download(request: SampleLocationDownloadRequest): Observable<boolean> {
        const url = this._apiUrlService.downloadSampleLocations();
        const loadingSubject = new BehaviorSubject<boolean>(true);
        HttpHelper.fetchBlob(this._htttp, url, request).subscribe({
            next: (res: any) => {
                loadingSubject.next(false);
                HttpHelper.downloadBlob(res, `Download-${new Date().toLocaleDateString()}.csv`);
            },
            error: () => {
                loadingSubject.next(false);
            }
        });

        return loadingSubject.asObservable();
    }
}
