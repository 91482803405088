<!-- Desktop UI -->
<div class="content-container flex-fill">
    <!-- Todo: add portal area later -->
    <!-- <div class="portal-area d-none d-xl-flex">
    </div> -->

    <dataud-desktop-side-bar class="d-none d-xl-flex left-panel" (reset)="resetFilters()">
        <ng-container #desktopFilter></ng-container>
    </dataud-desktop-side-bar>

    <div class="right-panel">
        <div class="map-container">
            <dataud-map></dataud-map>
        </div>

        <ng-container #desktopResult></ng-container>

        <dataud-loading-spinner *ngIf="isLoading"></dataud-loading-spinner>
    </div>

    <dataud-dmp-notification-area></dataud-dmp-notification-area>
</div>

<!-- Mobile UI -->
<dataud-mobile-view class="d-block d-xl-none position-relative" #mobileView (reset)="resetFilters()">
</dataud-mobile-view>

<div *ngIf="welcomeShown" class="welcome-backdrop">
    <div class="welcome-modal">
        <div class="content">
            <div class="header">{{'welcom_dialog.header' | translate}}</div>

            <div class="description d-block d-xl-none">{{'welcom_dialog.description_mobile' | translate}}</div>
            <div class="description d-none d-xl-block">{{'welcom_dialog.description_desktop' | translate}}</div>

            <div class="manual-area">
                <div class="manual-row row">
                    <div class="col-auto">
                        <div class="manual-img-container">
                            <img class="d-block d-xl-none" src="assets/images/left-side-manual-mobile.svg">
                            <img class="d-none d-xl-block" src="assets/images/left-side-manual-desktop.svg">
                        </div>

                    </div>

                    <div class="col d-block d-xl-none">{{'welcom_dialog.left_manual_mobile' | translate}}</div>
                    <div class="col d-none d-xl-block">{{'welcom_dialog.left_manual_desktop' | translate}}</div>
                </div>

                <div class="manual-row row">
                    <div class="col-auto">
                        <div class="manual-img-container right">
                            <img class="d-block d-xl-none" src="assets/images/right-side-manual-mobile.svg">
                            <img class="d-none d-xl-block" src="assets/images/right-side-manual-desktop.svg">
                        </div>
                    </div>

                    <div class="col d-block d-xl-none">{{'welcom_dialog.right_manual_mobile' | translate}}</div>
                    <div class="col d-none d-xl-block">{{'welcom_dialog.right_manual_desktop' | translate}}</div>
                </div>

                <div class="manual-row row">
                    <div class="col-auto">{{'welcom_dialog.bottom_manual' | translate}}</div>
                </div>
            </div>

            <div class="button-area">
                <div class="manual-row d-flex justify-content-between align-items-center">
                    <dataud-check-box [type]="'checkbox'" useHighlightStyle="true" [(ngModel)]="notShowAgain" [label]="'welcom_dialog.dont_show_again' | translate"
                        [textRole]="'annotation'"></dataud-check-box>

                    <button class="btn btn-info start-btn" (click)="start()">
                        <span>{{'welcom_dialog.start' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
