export default class S2Selection<T> {
  id: string;
  text: string;
  object: T;
  disabled?: boolean = false;

  constructor(id: string, text: string, object: T, disabled?: boolean) {
    this.id = String(id);
    this.text = text;
    this.object = object;
    this.disabled = disabled;
  }
}
