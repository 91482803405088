<div class="header-container">
    <div class="header-panel flex-fill flex-wrap flex-xl-nowrap">
        <div class="first-col">
            <button class="btn p-0 title d-flex align-items-center" (click)="toggleOptionalTab(false)" [class.selected]="!optionalTabShown" [class.cursor-auto]="!hasOptionalTab">
                <span *ngIf="!hasResults" class="tab-label d-none d-xl-block mr-2">{{'results_section.result' | translate}}:
                </span>

                <ng-container *ngIf="hasResults">
                    <span class="tab-label mr-2">{{'results_section.result' | translate}}: {{resultsHeaderKey | translate}}</span>
                    <span class="badge badge-pill badge-light">{{examinationsCount | number:'1.0-999'}}</span>
                </ng-container>
            </button>
        </div>

        <div *ngIf="hasOptionalTab" class="optional-col">
            <button class="btn title" [class.selected]="optionalTabShown" (click)="toggleOptionalTab(true)">
                <div class="title-col mr-2 tab-label">
                    <div class="text-truncate">
                        <ng-container *ngIf="optionalTabMode === 'Point'">{{'results_section.result' | translate}}:
                            {{'results_section.point' | translate}}</ng-container>
                        <ng-container *ngIf="optionalTabMode === 'Station'">{{'results_section.result' | translate}}: {{optionalTabStationDisplay}}
                        </ng-container>
                    </div>
                </div>

                <div class="d-flex align-items-center ml-2">
                    <span class="badge badge-pill badge-light">{{optionalTabExaminationsCount | number:'1.0-999'}}</span>
                    <button class="btn p-0 ml-2" (click)="closeOptionalTab($event)">
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </button>
        </div>

    </div>

    <div class="collaps-btn d-none d-xl-block ml-2 square-btn">
        <button class="btn p-0" (click)="toggleResultsCollapse()">
            <i class="far fa-arrow-to-right fa-lg fa-rotate-90" [class.fa-rotate-270]="resultsCollapsed"></i>
        </button>
    </div>
</div>

<div class="content-container" [class.expanded]="!resultsCollapsed">
    <div class="results-area">
        <ng-container *ngIf="resultMode === 'Result'">
            <div class="results-table-container results" [class.invisible]="downloadPanelOpen">
                <ng-container *ngIf="!optionalTabShown">
                    <div class="table-header">
                        <div class="header-cell"></div>
                        <div class="header-cell">{{ resultColumnKey | translate}}</div>
                        <div class="header-cell count-cell">{{'results_section.column_header.number_of_examinations' | translate}}</div>
                        <div class="header-cell count-cell">{{'results_section.column_header.number_of_results' | translate}}</div>
                        <div class="header-cell">{{'results_section.column_header.first_examination' | translate}}</div>
                        <div class="header-cell">{{'results_section.column_header.latest_examination' | translate}}</div>
                    </div>

                    <div class="table-body-container">
                        <div class="table-row empty" *ngIf="results?.length < 1">
                            <div class="table-cell text-center">{{'results_section.no_data' | translate}}</div>
                        </div>

                        <ng-container *ngIf="results?.length >= 1">
                            <cdk-virtual-scroll-viewport itemSize="10">
                                <div class="table-row" *cdkVirtualFor="let result of results" [class.selected]="result.selected" (click)="toggleSelectResultRow(result)">
                                    <div class="table-cell download-cell">
                                        <div *ngIf="result.isDownloading$ | async; else downloadResultBtn" class="icon-container">
                                            <i class="fas fa-spinner fa-spin text-info"></i>
                                        </div>
                                        <ng-template #downloadResultBtn>
                                            <button class="btn p-0 btn-download" (click)="downloadByType(result, $event)" [disabled]="!result.canDownload"
                                                [attr.title]="result.canDownload ? '' : ('results_section.download.cant_download_message' | translate)">
                                                <i class="fas fa-download fa-md"></i>
                                            </button>
                                        </ng-template>
                                    </div>
                                    <div class="table-cell text-truncate"> {{result.typeName}}</div>
                                    <div class="table-cell count-cell">{{result.examinationCount | number:'1.0-999'}}</div>
                                    <div class="table-cell count-cell">{{result.resultCount | number:'1.0-999'}}</div>
                                    <div class="table-cell">{{result.firstExaminationDate | date: 'dd-MM-yyyy'}}</div>
                                    <div class="table-cell">{{result.lastExaminationDate | date: 'dd-MM-yyyy'}}</div>
                                </div>
                            </cdk-virtual-scroll-viewport>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="optionalTabShown">
                    <div class="table-header">
                        <div class="header-cell"></div>
                        <div class="header-cell">{{ resultColumnKey | translate}}</div>
                        <div class="header-cell count-cell">{{'results_section.column_header.number_of_examinations' | translate}}</div>
                        <div class="header-cell count-cell">{{'results_section.column_header.number_of_results' | translate}}</div>
                        <div class="header-cell">{{'results_section.column_header.first_examination' | translate}}</div>
                        <div class="header-cell">{{'results_section.column_header.latest_examination' | translate}}</div>
                    </div>

                    <div class="table-body-container">
                        <cdk-virtual-scroll-viewport itemSize="10">
                            <div class="table-row" *cdkVirtualFor="let result of optionalTabResults">
                                <div class="table-cell download-cell">
                                    <div *ngIf="result.isDownloading$ | async; else downloadOptionalTabBtn" class="icon-container">
                                        <i class="fas fa-spinner fa-spin text-info"></i>
                                    </div>
                                    <ng-template #downloadOptionalTabBtn>
                                        <button class="btn p-0 btn-download" (click)="downloadByTypeAndStations(result, $event)" [disabled]="!result.canDownload"
                                            [attr.title]="result.canDownload ? '' : ('results_section.download.cant_download_message' | translate)">
                                            <i class="fas fa-download fa-md"></i>
                                        </button>
                                    </ng-template>
                                </div>
                                <div class="table-cell"> {{result.typeName}}</div>
                                <div class="table-cell count-cell">{{result.examinationCount | number:'1.0-999'}}</div>
                                <div class="table-cell count-cell">{{result.resultCount | number:'1.0-999'}}</div>
                                <div class="table-cell">{{result.firstExaminationDate | date: 'dd-MM-yyyy'}}</div>
                                <div class="table-cell">{{result.lastExaminationDate | date: 'dd-MM-yyyy'}}</div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="resultMode === 'SampleLocation'">
            <div class="results-table-container sample-locations">
                <div class="table-header">
                    <div class="header-cell">{{ resultColumnKey | translate}}</div>
                    <div class="header-cell">{{'results_section.column_header.municipality' | translate}}</div>
                    <div class="header-cell">{{'results_section.column_header.region' | translate}}</div>
                </div>

                <div class="table-body-container">
                    <div class="table-row empty" *ngIf="sampleLocations?.length < 1">
                        <div class="table-cell text-center">{{'results_section.no_data' | translate}}</div>
                    </div>

                    <ng-container *ngIf="sampleLocations?.length >= 1">
                        <cdk-virtual-scroll-viewport itemSize="10">
                            <div class="table-row" *cdkVirtualFor="let sampleLocation of sampleLocations" [class.selected]="sampleLocation.selected"
                                (click)="toggleSelectSampleLocationRow(sampleLocation)">
                                <div class="table-cell">
                                    <ng-container *ngIf="sampleLocation.number">{{sampleLocation.number}} - </ng-container>{{sampleLocation.name}}
                                </div>
                                <div class="table-cell">{{sampleLocation.municipality}}</div>
                                <div class="table-cell">{{sampleLocation.region}}</div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <div *ngIf="downloadAllShown" class="download-area">
        <button class="btn p-0 btn-download" (click)="downloadAll()">
            <i *ngIf="isDownloadingAll$ | async; else downloadIcon" class="fas fa-spinner fa-spin text-info mr-2"></i>
            <ng-template #downloadIcon>
                <i class="fas fa-download fa-md mr-2"></i>
            </ng-template>
            <span class="font-weight-bold text-primary">{{'results_section.download.download_all' |translate}}</span>
        </button>
    </div>
</div>

<dataud-loading-spinner class="d-block d-xl-none" *ngIf="isLoading"></dataud-loading-spinner>

<ng-template #markerPopup>
    <div *ngIf="popupData || loadingStation" class="marker-popup-container">
        <div class="scrollable" [class.scrollable-mode-2]="resultMode === 'SampleLocation'">
            <div class="scrollable-content marker-popup-w-auto" >
                <div *ngIf="resultMode !== 'SampleLocation'">
                    <div *ngIf="popupData.stations.length == 1; else multipleResults">
                        <div class="btn-close-popover" (click)="closePopup()"><i class="far fa-times"></i></div>
                        <div class="map-station-popover-item pb-0">
                            <div class="item-info text-bold text-truncate pl-3 pr-5 title"
                                [attr.title]="popupData.stations[0].number ? (popupData.stations[0].number + ' - ' + popupData.stations[0].name) : popupData.stations[0].name">
                                {{ popupData.stations[0].number ? (popupData.stations[0].number + ' - ' + popupData.stations[0].name) : popupData.stations[0].name }}
                            </div>
                            <div class="popover-content">
                                <div>
                                    <div class="info mb-3 pl-3 pr-3">
                                        <div *ngIf="popupData.stations[0].stationOwnerName" class="item-info fs-small text-truncate"> {{ popupData.stations[0].stationOwnerName }} </div>
                                        <div class="d-flex">
                                            <div *ngIf="popupData.stations[0].mediaName" class="item-info text-truncate mr-2"> <span class="badge bg-media"> {{ popupData.stations[0].mediaName }} </span></div>
                                            <div *ngIf="popupData.stations[0].locationTypeName" class="item-info text-truncate"> <span class="badge bg-media"> {{ popupData.stations[0].locationTypeName }} </span></div>
                                        </div>
                                    </div>
                                    <div class="footer">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ng-template #multipleResults>
                        <div *ngFor="let station of popupData.stations; index as i;" class="map-station-popover-item cursor-pointer multiple-item" (click)="openOptionalTabByStation(station)">
                            <div class="item-info text-bold text-truncate pl-3 pr-5 title">
                                {{ station.number + " " + station.name }}
                            </div>
                        </div>
                    </ng-template>
                </div>


                <!-- SampleLocation -->
                <div *ngIf="resultMode === 'SampleLocation'">
                    <div *ngIf="popupData.stations.length == 1; else multipleLocations">
                        <div class="btn-close-popover" (click)="closePopup()"><i class="far fa-times"></i></div>
                        <div class="map-station-popover-item pb-0">
                            <div class="item-info text-bold text-truncate pl-3 pr-5 title" [attr.title]="popupData.stations[0].number + ' ' + popupData.stations[0].name">
                                {{ popupData.stations[0].number + " " + popupData.stations[0].name }}
                            </div>
                            <div class="popover-content">
                                <div>
                                    <div class="info mb-3 pl-3 pr-3">
                                        <div class="item-info fs-small text-truncate"> {{ popupData.stations[0].stationOwnerName }} </div>
                                        <div class="d-flex">
                                            <div class="item-info text-truncate mr-2"> <span class="badge bg-media"> {{ popupData.stations[0].mediaName }} </span></div>
                                            <div *ngIf="popupData.stations[0].locationTypeName" class="item-info text-truncate"> <span class="badge bg-media"> {{ popupData.stations[0].locationTypeName }} </span></div>
                                        </div>
                                    </div>
                                    <div class="footer">
                                        <div *ngIf="popupData.stations[0].isLoadingHydroApiStation" class="loading-station">
                                            <div>
                                                <i class="fas fa-spinner fa-spin"></i>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!popupData.stations[0].isLoadingHydroApiStation">
                                            <ng-container *ngIf="popupData.stations[0].latestResult">
                                                <div class="white-space-nowrap mr-2 latest-result-text">
                                                    {{ popupData.stations[0].latestResult }}
                                                </div>
                                                <a class="p-1 white-space-nowrap" [href]="hydroChartsUrl + popupData.stations[0].number + location.search" (click)="$event.stopPropagation();">
                                                    <span class="mr-2">{{ 'map.station_popover.view_data' | translate }}</span>
                                                    <i class="far fa-chevron-right"></i>
                                                </a>
                                            </ng-container>
                                            <div *ngIf="!popupData.stations[0]?.latestResult" class="white-space-nowrap mr-2 latest-result-text">
                                                {{ 'results_section.no_data_short' | translate }}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ng-template #multipleLocations>
                        <div *ngFor="let station of popupData.stations; index as i;" class="map-station-popover-item cursor-pointer multiple-item" (click)="viewStation(station)">
                            <div class="item-info text-bold text-truncate pl-3 pr-5 title">
                                {{ station.number + " " + station.name }}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

        <div *ngIf="resultMode === 'Result' && popupData.stations.length > 1 " class="data-row justify-content-between view-all">
            <div>{{'results_section.map_popup.view_all_results' | translate}}</div>

            <button class="btn goto-btn" (click)="openOptionalTabByStations(popupData.stations)">
                <i class="far fa-arrow-right"></i>
            </button>
        </div>
    </div>
</ng-template>